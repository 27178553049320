/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetConfigFileErrorResultDto
 */
export interface GetConfigFileErrorResultDto {
    /**
     * 
     * @type {number}
     * @memberof GetConfigFileErrorResultDto
     */
    rowNumber: number;
    /**
     * 
     * @type {object}
     * @memberof GetConfigFileErrorResultDto
     */
    row: object;
    /**
     * 
     * @type {object}
     * @memberof GetConfigFileErrorResultDto
     */
    sheetName: GetConfigFileErrorResultDtoSheetNameEnum;
}

export function GetConfigFileErrorResultDtoFromJSON(json: any): GetConfigFileErrorResultDto {
    return GetConfigFileErrorResultDtoFromJSONTyped(json, false);
}

export function GetConfigFileErrorResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConfigFileErrorResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowNumber': json['rowNumber'],
        'row': json['row'],
        'sheetName': json['sheetName'],
    };
}

export function GetConfigFileErrorResultDtoToJSON(value?: GetConfigFileErrorResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowNumber': value.rowNumber,
        'row': value.row,
        'sheetName': value.sheetName,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetConfigFileErrorResultDtoSheetNameEnum {
    airports = 'airports',
    institutions = 'institutions',
    VIP = 'VIP',
    planes = 'planes',
    handlingCompanies = 'handlingCompanies',
    diplomaticApprovals = 'diplomaticApprovals',
    List1 = 'List1'
}


