/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetRequestLeanDto
 */
export interface GetRequestLeanDto {
    /**
     * Request ID
     * @type {number}
     * @memberof GetRequestLeanDto
     */
    id: number;
    /**
     * Request name
     * @type {string}
     * @memberof GetRequestLeanDto
     */
    name: string;
}

export function GetRequestLeanDtoFromJSON(json: any): GetRequestLeanDto {
    return GetRequestLeanDtoFromJSONTyped(json, false);
}

export function GetRequestLeanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestLeanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function GetRequestLeanDtoToJSON(value?: GetRequestLeanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}


