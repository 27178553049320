/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestPart,
    RequestPartFromJSON,
    RequestPartFromJSONTyped,
    RequestPartToJSON,
} from './';

/**
 * 
 * @export
 * @interface PostActionDto
 */
export interface PostActionDto {
    /**
     * Id of user for who is the actions created
     * @type {number}
     * @memberof PostActionDto
     */
    userForId: number;
    /**
     * Action content
     * @type {string}
     * @memberof PostActionDto
     */
    what: string;
    /**
     * Flight id assigned to an action
     * @type {number}
     * @memberof PostActionDto
     */
    flightId: number;
    /**
     * 
     * @type {RequestPart}
     * @memberof PostActionDto
     */
    partOfRequest?: RequestPart;
}

export function PostActionDtoFromJSON(json: any): PostActionDto {
    return PostActionDtoFromJSONTyped(json, false);
}

export function PostActionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostActionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userForId': json['userForId'],
        'what': json['what'],
        'flightId': json['flightId'],
        'partOfRequest': !exists(json, 'partOfRequest') ? undefined : RequestPartFromJSON(json['partOfRequest']),
    };
}

export function PostActionDtoToJSON(value?: PostActionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userForId': value.userForId,
        'what': value.what,
        'flightId': value.flightId,
        'partOfRequest': RequestPartToJSON(value.partOfRequest),
    };
}


