/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutFlightCargoDto
 */
export interface PutFlightCargoDto {
    /**
     * Has flight HAZMAT onboard?
     * @type {boolean}
     * @memberof PutFlightCargoDto
     */
    hasHazmat: boolean;
    /**
     * Has flight NOTOC onboard?
     * @type {boolean}
     * @memberof PutFlightCargoDto
     */
    hasNotoc: boolean;
    /**
     * Cargo weight
     * @type {number}
     * @memberof PutFlightCargoDto
     */
    weight?: number | null;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightCargoDto
     */
    declaration: PutRequestFilesDto;
    /**
     * Flight cargo comments
     * @type {string}
     * @memberof PutFlightCargoDto
     */
    comments?: string | null;
}

export function PutFlightCargoDtoFromJSON(json: any): PutFlightCargoDto {
    return PutFlightCargoDtoFromJSONTyped(json, false);
}

export function PutFlightCargoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightCargoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasHazmat': json['hasHazmat'],
        'hasNotoc': json['hasNotoc'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'declaration': PutRequestFilesDtoFromJSON(json['declaration']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function PutFlightCargoDtoToJSON(value?: PutFlightCargoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasHazmat': value.hasHazmat,
        'hasNotoc': value.hasNotoc,
        'weight': value.weight,
        'declaration': PutRequestFilesDtoToJSON(value.declaration),
        'comments': value.comments,
    };
}


