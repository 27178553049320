/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutFlightStatusDto
 */
export interface PutFlightStatusDto {
    /**
     * Flight ID
     * @type {number}
     * @memberof PutFlightStatusDto
     */
    id: number;
    /**
     * Is flight's basic info part done?
     * @type {boolean}
     * @memberof PutFlightStatusDto
     */
    isBasicInfoDone: boolean;
    /**
     * Is flight's pax part done?
     * @type {boolean}
     * @memberof PutFlightStatusDto
     */
    isPaxDone: boolean;
    /**
     * Is flight's crew part done?
     * @type {boolean}
     * @memberof PutFlightStatusDto
     */
    isCrewDone: boolean;
    /**
     * Is flight's handling part done?
     * @type {boolean}
     * @memberof PutFlightStatusDto
     */
    isHandlingDone: boolean;
    /**
     * Is flight's cargo part done?
     * @type {boolean}
     * @memberof PutFlightStatusDto
     */
    isCargoDone: boolean;
}

export function PutFlightStatusDtoFromJSON(json: any): PutFlightStatusDto {
    return PutFlightStatusDtoFromJSONTyped(json, false);
}

export function PutFlightStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isBasicInfoDone': json['isBasicInfoDone'],
        'isPaxDone': json['isPaxDone'],
        'isCrewDone': json['isCrewDone'],
        'isHandlingDone': json['isHandlingDone'],
        'isCargoDone': json['isCargoDone'],
    };
}

export function PutFlightStatusDtoToJSON(value?: PutFlightStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isBasicInfoDone': value.isBasicInfoDone,
        'isPaxDone': value.isPaxDone,
        'isCrewDone': value.isCrewDone,
        'isHandlingDone': value.isHandlingDone,
        'isCargoDone': value.isCargoDone,
    };
}


