import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import * as Sentry from '@sentry/react';
import {
  Configuration,
  ConfigurationApi,
  ConfigurationControllerDownloadDemoConfigFileTypeEnum,
  EnumsApi,
  GetUserDto,
  UsersApi,
} from '../../api';
import { getBasicSettings } from '../../helpers/functions/apiHelpers';
import { SnackBarProps } from '../../helpers/interfaces/snackbar';
import { State } from './reducers';
import { GetRequestEnumDataDto } from '../../api/models/GetRequestEnumDataDto';
import { createApplicationBlobUrl } from '../../helpers/functions/fileHelpers';

export enum ActionTypes {
  SET_REQUEST_DATA_ENUMS = '[general] SET_REQUEST_DATA_ENUMS',
  SET_SNACKBAR = '[general] SET_SNACKBAR',
  SET_ALL_USER = '[general] SET_ALL_USER',
}

export const setRequestDataEnums = action(
  ActionTypes.SET_REQUEST_DATA_ENUMS,
  payload<{ requestEnum: GetRequestEnumDataDto }>(),
);
export const createSnackbar = action(
  ActionTypes.SET_SNACKBAR,
  payload<SnackBarProps>(),
);
export const setAllUsers = action(
  ActionTypes.SET_ALL_USER,
  payload<{ users: GetUserDto[] }>(),
);

/**
 * fetch request enums data
 */
export const fetchRequestEnumData: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const enumsApi = new EnumsApi(new Configuration(getBasicSettings()));

    try {
      const result = await enumsApi.enumsControllerGetAllEnums();
      dispatch(setRequestDataEnums({ requestEnum: result }));
    } catch (err: any) {
      if (err && typeof err.json === 'function') {
        const message = `Fetch request enum data error.`;
        console.error(message, err);
        Sentry.captureMessage(message, {
          level: Sentry.Severity.Log,
          extra: { err: JSON.stringify(await err.json()) },
        });
      }
    }
  };

export const fetchAllUsers: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const usersApi = new UsersApi(new Configuration(getBasicSettings()));

    try {
      const users = await usersApi.usersControllerFetchAllUsers();
      dispatch(setAllUsers({ users }));
    } catch (err: any) {
      if (err && typeof err.json === 'function') {
        const message = `Fetch all users error.`;
        console.error(message, err);
        Sentry.captureMessage(message, {
          level: Sentry.Severity.Log,
          extra: { err: JSON.stringify(await err.json()) },
        });
      }
    }
  };

/**
 * fetch all users
 */
export const downloadConfigFile: ActionCreator<
  ThunkAction<Promise<string | undefined>, State, any, any>
> =
  (type: ConfigurationControllerDownloadDemoConfigFileTypeEnum) =>
  async (dispatch: Dispatch<Action>): Promise<string | undefined> => {
    const configApi = new ConfigurationApi(
      new Configuration(getBasicSettings()),
    );

    try {
      const result =
        await configApi.configurationControllerDownloadDemoConfigFile({ type });

      const url = createApplicationBlobUrl(result);

      return url;
    } catch (err: any) {
      const message = `${type} file download error.`;
      dispatch(
        createSnackbar({
          message,
          type: 'error',
        }),
      );
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { err: JSON.stringify(await err.json()) },
      });

      return undefined;
    }
  };

export const uploadConfigFile: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (file: File) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const configApi = new ConfigurationApi(
      new Configuration(getBasicSettings()),
    );

    try {
      await configApi.configurationControllerUploadConfigExcelFile({
        configExcelFile: file,
      });
      dispatch(
        createSnackbar({
          message: 'Config file was successfully updated',
          type: 'success',
        }),
      );
      // je potreba stahnout novz enumy
      // @ts-ignore
      dispatch(fetchRequestEnumData());
    } catch (err: any) {
      const message = `Config file upload error.`;
      console.error(message, err);
      dispatch(
        createSnackbar({
          message,
          type: 'error',
        }),
      );
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { file, err: JSON.stringify(await err.json()) },
      });
    }
  };

export const uploadFMSFile: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (file: File) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const configApi = new ConfigurationApi(
      new Configuration(getBasicSettings()),
    );

    try {
      await configApi.configurationControllerUploadFMSExcelFile({
        configExcelFile: file,
      });
      dispatch(
        createSnackbar({
          message: 'FMS file was successfully updated',
          type: 'success',
        }),
      );
      // je potreba stahnout novz enumy
      // @ts-ignore
      dispatch(fetchRequestEnumData());
    } catch (err: any) {
      const message = `FMS file upload error.`;
      console.error(message, err);
      dispatch(
        createSnackbar({
          message,
          type: 'error',
        }),
      );
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { file, err: JSON.stringify(await err.json()) },
      });
    }
  };

export const uploadCrewFile: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (file: File) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const configApi = new ConfigurationApi(
      new Configuration(getBasicSettings()),
    );

    try {
      await configApi.configurationControllerUploadCrewExcelFile({
        configExcelFile: file,
      });
      dispatch(
        createSnackbar({
          message: 'Crew file was successfully updated',
          type: 'success',
        }),
      );
      // @ts-ignore
      dispatch(fetchRequestEnumData());
    } catch (err: any) {
      const message = `Crew file upload error.`;
      console.error(message, err);
      dispatch(
        createSnackbar({
          message,
          type: 'error',
        }),
      );
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { file, err: JSON.stringify(await err.json()) },
      });
    }
  };
