/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutFlightBasicInfoDto
 */
export interface PutFlightBasicInfoDto {
    /**
     * Flight departure destination
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    departureIcao: string;
    /**
     * Flight departure time
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    departureTime: string;
    /**
     * Flight arrival destination
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    arrivalIcao: string;
    /**
     * Flight arrival time
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    arrivalTime: string;
    /**
     * Flight ID in basic info
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    flightId?: string | null;
    /**
     * Flight special status
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    specialStatus?: string | null;
    /**
     * Is flight same as previous Leg?
     * @type {boolean}
     * @memberof PutFlightBasicInfoDto
     */
    sameAsPreviousLeg: boolean;
    /**
     * Flight FPLN
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    fpln?: string | null;
    /**
     * Flight comments
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    comments?: string | null;
    /**
     * Type of diplomatic approval
     * @type {string}
     * @memberof PutFlightBasicInfoDto
     */
    dAType: PutFlightBasicInfoDtoDATypeEnum;
}

export function PutFlightBasicInfoDtoFromJSON(json: any): PutFlightBasicInfoDto {
    return PutFlightBasicInfoDtoFromJSONTyped(json, false);
}

export function PutFlightBasicInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightBasicInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'departureIcao': json['departureIcao'],
        'departureTime': json['departureTime'],
        'arrivalIcao': json['arrivalIcao'],
        'arrivalTime': json['arrivalTime'],
        'flightId': !exists(json, 'flightId') ? undefined : json['flightId'],
        'specialStatus': !exists(json, 'specialStatus') ? undefined : json['specialStatus'],
        'sameAsPreviousLeg': json['sameAsPreviousLeg'],
        'fpln': !exists(json, 'fpln') ? undefined : json['fpln'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'dAType': json['DAType'],
    };
}

export function PutFlightBasicInfoDtoToJSON(value?: PutFlightBasicInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departureIcao': value.departureIcao,
        'departureTime': value.departureTime,
        'arrivalIcao': value.arrivalIcao,
        'arrivalTime': value.arrivalTime,
        'flightId': value.flightId,
        'specialStatus': value.specialStatus,
        'sameAsPreviousLeg': value.sameAsPreviousLeg,
        'fpln': value.fpln,
        'comments': value.comments,
        'DAType': value.dAType,
    };
}

/**
* @export
* @enum {string}
*/
export enum PutFlightBasicInfoDtoDATypeEnum {
    G = 'G',
    H = 'H',
    V = 'V'
}


