/** @jsx jsx */

import { css, jsx, SerializedStyles } from '@emotion/core';
import { ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.css';
import { AppMenu } from './components/AppMenu';
import { Loading } from './components/shared/Loading';
import { anchorOrigin } from './helpers/constants/common';
import { RoutePaths } from './helpers/enums/RoutePaths';
import { authRoutes, history, unauthRoutes } from './router';
import { logOut, refreshUserFromCookie } from './store/auth/actions';
import { getIsUserRefreshed, getLoggedUser } from './store/auth/selectors';
import { fetchAllUsers, fetchRequestEnumData } from './store/general/actions';
import { getSnackbar } from './store/general/selectors';
import { setPDFExportingInterval } from './store/requests/actions';
import { getGeneratingPDF } from './store/requests/selectors';
import { AppMenuHeight } from './styles/global';
import { APP_THEME } from './styles/themes';

export const App: React.FC = () => {
  const loggedUser = useSelector(getLoggedUser);
  const isUserRefreshed = useSelector(getIsUserRefreshed);
  const snackbar = useSelector(getSnackbar);
  const { enqueueSnackbar } = useSnackbar();
  const generatingPDF = useSelector(getGeneratingPDF);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(refreshUserFromCookie());
    dispatch(fetchRequestEnumData());
    dispatch(fetchAllUsers());
  }, [dispatch]);

  React.useEffect(() => {
    if (snackbar) {
      enqueueSnackbar(snackbar.message, {
        variant: snackbar.type,
        autoHideDuration: snackbar.autoHideDuration,
        anchorOrigin,
        preventDuplicate: true,
        action: snackbar.action,
      });
    }
  }, [enqueueSnackbar, snackbar]);

  React.useEffect(() => {
    const interval = dispatch(setPDFExportingInterval()) as unknown as any;
    return () => {
      clearInterval(interval);
    };
  }, [generatingPDF, dispatch]);

  const renderRouter = React.useMemo(() => {
    if (!isUserRefreshed) {
      return <Loading />;
    }

    if (!loggedUser) {
      return (
        <Switch>
          {unauthRoutes.map(route => (
            <Route key={route.path} path={route.path} {...route.props} />
          ))}
          <Redirect to={`/${RoutePaths.LOGIN}`} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Redirect
          exact
          from={`/${RoutePaths.LOGIN}`}
          to={`${RoutePaths.REQUESTS}`}
        />

        <Redirect exact from="/" to={RoutePaths.REQUESTS} />
        {authRoutes.map(route => (
          <Route key={route.path} path={route.path} {...route.props} />
        ))}
      </Switch>
    );
  }, [isUserRefreshed, loggedUser]);

  return (
    <Sentry.ErrorBoundary fallback="An error has occurred.">
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={APP_THEME}>
          <Router history={history}>
            <div css={appContainerCss}>
              {loggedUser && (
                <AppMenu
                  user={loggedUser}
                  logOut={(): void => {
                    dispatch(logOut());
                  }}
                />
              )}

              <div css={wrapCss(loggedUser != null)}>{renderRouter}</div>
            </div>
          </Router>
        </ThemeProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

const appContainerCss: SerializedStyles = css`
  height: 100vh;
`;
const wrapCss = (hasTopMenu: boolean): SerializedStyles => css`
  ${hasTopMenu
    ? `height: calc(100% - ${Math.round(AppMenuHeight)}px) !important;`
    : `height: 100% !important;`}
  overflow: hidden;
  position: relative;
`;
