/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutApprovalDto,
    PutApprovalDtoFromJSON,
    PutApprovalDtoFromJSONTyped,
    PutApprovalDtoToJSON,
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutRequestDiplomaticApprovalDto
 */
export interface PutRequestDiplomaticApprovalDto {
    /**
     * 
     * @type {Array<PutApprovalDto>}
     * @memberof PutRequestDiplomaticApprovalDto
     */
    issuedApprovals: Array<PutApprovalDto>;
    /**
     * 
     * @type {Array<PutApprovalDto>}
     * @memberof PutRequestDiplomaticApprovalDto
     */
    approvalList: Array<PutApprovalDto>;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutRequestDiplomaticApprovalDto
     */
    oneTimePermit: PutRequestFilesDto;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutRequestDiplomaticApprovalDto
     */
    airportSlots: PutRequestFilesDto;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutRequestDiplomaticApprovalDto
     */
    pprs: PutRequestFilesDto;
    /**
     * 
     * @type {string}
     * @memberof PutRequestDiplomaticApprovalDto
     */
    comments?: string | null;
}

export function PutRequestDiplomaticApprovalDtoFromJSON(json: any): PutRequestDiplomaticApprovalDto {
    return PutRequestDiplomaticApprovalDtoFromJSONTyped(json, false);
}

export function PutRequestDiplomaticApprovalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutRequestDiplomaticApprovalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issuedApprovals': ((json['issuedApprovals'] as Array<any>).map(PutApprovalDtoFromJSON)),
        'approvalList': ((json['approvalList'] as Array<any>).map(PutApprovalDtoFromJSON)),
        'oneTimePermit': PutRequestFilesDtoFromJSON(json['oneTimePermit']),
        'airportSlots': PutRequestFilesDtoFromJSON(json['airportSlots']),
        'pprs': PutRequestFilesDtoFromJSON(json['pprs']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function PutRequestDiplomaticApprovalDtoToJSON(value?: PutRequestDiplomaticApprovalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issuedApprovals': ((value.issuedApprovals as Array<any>).map(PutApprovalDtoToJSON)),
        'approvalList': ((value.approvalList as Array<any>).map(PutApprovalDtoToJSON)),
        'oneTimePermit': PutRequestFilesDtoToJSON(value.oneTimePermit),
        'airportSlots': PutRequestFilesDtoToJSON(value.airportSlots),
        'pprs': PutRequestFilesDtoToJSON(value.pprs),
        'comments': value.comments,
    };
}


