/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestNotaDto
 */
export interface GetRequestNotaDto {
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetRequestNotaDto
     */
    files: GetRequestFilesDto;
    /**
     * 
     * @type {string}
     * @memberof GetRequestNotaDto
     */
    comments?: string | null;
}

export function GetRequestNotaDtoFromJSON(json: any): GetRequestNotaDto {
    return GetRequestNotaDtoFromJSONTyped(json, false);
}

export function GetRequestNotaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestNotaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'files': GetRequestFilesDtoFromJSON(json['files']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function GetRequestNotaDtoToJSON(value?: GetRequestNotaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'files': GetRequestFilesDtoToJSON(value.files),
        'comments': value.comments,
    };
}


