/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetRequestFileBufferDto
 */
export interface GetRequestFileBufferDto {
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileBufferDto
     */
    buffer: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileBufferDto
     */
    typeOfFile: string;
}

export function GetRequestFileBufferDtoFromJSON(json: any): GetRequestFileBufferDto {
    return GetRequestFileBufferDtoFromJSONTyped(json, false);
}

export function GetRequestFileBufferDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestFileBufferDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buffer': json['buffer'],
        'typeOfFile': json['typeOfFile'],
    };
}

export function GetRequestFileBufferDtoToJSON(value?: GetRequestFileBufferDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buffer': value.buffer,
        'typeOfFile': value.typeOfFile,
    };
}


