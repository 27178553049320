import { combineReducers } from 'redux';
import * as general from './general/reducers';
import * as requests from './requests/reducers';
import * as auth from './auth/reducers';

export interface CombinedState {
  general: general.State;
  requests: requests.State;
  auth: auth.State;
}

export const initialState: CombinedState = {
  general: general.initialState,
  requests: requests.initialState,
  auth: auth.initialState,
};

export const reducer = combineReducers<CombinedState>({
  general: general.generalReducer,
  requests: requests.requestsReducer,
  auth: auth.authReducer,
});
