import {
  GetRequestStatusDto,
  GetRequestDto,
  GetRequestFilesDto,
} from '../../api';
import { OverrideAndOmit } from '../interfaces/global';

export type EmptyGetRequest = OverrideAndOmit<
  GetRequestDto,
  {
    basicInfo: undefined;
    nota: undefined;
    diplomaticApproval: undefined;
    summary: undefined;
    id: undefined;
  },
  ''
>;

export const emptyRequest: EmptyGetRequest = {
  id: undefined,
  basicInfo: undefined,
  nota: undefined,
  diplomaticApproval: undefined,
  flights: [],
  summary: undefined,
};

export const emptyRequestStatus: GetRequestStatusDto = {
  isBasicInfoDone: false,
  isNotaDone: false,
  isDiplomaticApprovalDone: false,
  isSummaryDone: false,
  flights: {
    areAllFlightsDone: false,
    flights: [],
  },
};

export const emptyRequestFiles: GetRequestFilesDto = {
  activeFiles: [],
  archiveFiles: [],
};
