/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PDFGeneratingStatus,
    PDFGeneratingStatusFromJSON,
    PDFGeneratingStatusFromJSONTyped,
    PDFGeneratingStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPDFGenerationStatusDto
 */
export interface GetPDFGenerationStatusDto {
    /**
     * 
     * @type {PDFGeneratingStatus}
     * @memberof GetPDFGenerationStatusDto
     */
    status?: PDFGeneratingStatus;
}

export function GetPDFGenerationStatusDtoFromJSON(json: any): GetPDFGenerationStatusDto {
    return GetPDFGenerationStatusDtoFromJSONTyped(json, false);
}

export function GetPDFGenerationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPDFGenerationStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : PDFGeneratingStatusFromJSON(json['status']),
    };
}

export function GetPDFGenerationStatusDtoToJSON(value?: GetPDFGenerationStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': PDFGeneratingStatusToJSON(value.status),
    };
}


