/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAirportDto
 */
export interface GetAirportDto {
    /**
     * 
     * @type {string}
     * @memberof GetAirportDto
     */
    icao: string;
    /**
     * 
     * @type {string}
     * @memberof GetAirportDto
     */
    iata: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAirportDto
     */
    name: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAirportDto
     */
    isFms: boolean;
}

export function GetAirportDtoFromJSON(json: any): GetAirportDto {
    return GetAirportDtoFromJSONTyped(json, false);
}

export function GetAirportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAirportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icao': json['icao'],
        'iata': json['iata'],
        'name': json['name'],
        'isFms': json['isFms'],
    };
}

export function GetAirportDtoToJSON(value?: GetAirportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icao': value.icao,
        'iata': value.iata,
        'name': value.name,
        'isFms': value.isFms,
    };
}


