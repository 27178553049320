/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFileType,
    RequestFileTypeFromJSON,
    RequestFileTypeFromJSONTyped,
    RequestFileTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutRequestFileDto
 */
export interface PutRequestFileDto {
    /**
     * 
     * @type {number}
     * @memberof PutRequestFileDto
     */
    id: number;
    /**
     * 
     * @type {RequestFileType}
     * @memberof PutRequestFileDto
     */
    fileType: RequestFileType;
}

export function PutRequestFileDtoFromJSON(json: any): PutRequestFileDto {
    return PutRequestFileDtoFromJSONTyped(json, false);
}

export function PutRequestFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutRequestFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fileType': RequestFileTypeFromJSON(json['fileType']),
    };
}

export function PutRequestFileDtoToJSON(value?: PutRequestFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileType': RequestFileTypeToJSON(value.fileType),
    };
}


