/** @jsx jsx */

import {
  FC,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  useRef,
  useEffect,
} from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Button,
  Avatar,
  Hidden,
  Menu,
  MenuItem,
  Badge,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Colors, MPX } from '../styles/themes';
import { RoutePaths } from '../helpers/enums/RoutePaths';
import { AppMenuHeight, linkCss } from '../styles/global';
import {
  ConfigurationControllerDownloadDemoConfigFileTypeEnum,
  GetUserDto,
} from '../api';
import { downloadFile } from '../helpers/functions/fileHelpers';
import {
  downloadConfigFile,
  uploadConfigFile,
  uploadCrewFile,
  uploadFMSFile,
} from '../store/general/actions';
import { getUnreadActions } from '../store/requests/selectors';
import { getLoggedUser } from '../store/auth/selectors';
import { fetchActions } from '../store/requests/actions';
import { getRequestEnumData } from '../store/general/selectors';
import MultiLevel from './MultiLevelItem';

interface MenuItemInterface {
  name: string;
  route: RoutePaths;
}
const menuItems: MenuItemInterface[] = [
  {
    name: 'Requests',
    route: RoutePaths.REQUESTS,
  },
  {
    name: 'Actions',
    route: RoutePaths.ACTIONS,
  },
  {
    name: 'Flights',
    route: RoutePaths.FLIGHTS,
  },
  {
    name: 'Timesheet',
    route: RoutePaths.TIMESHEET,
  },
];

interface AppMenuProps {
  user: GetUserDto;
  logOut: () => void;
}

export const AppMenu: FC<AppMenuProps> = ({ user, logOut }: AppMenuProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const lastUpdated = useSelector(getRequestEnumData('lastUpdated'));
  const newestFMS = useSelector(getRequestEnumData('newestFMSFileName'));
  const inputFile = useRef(null);
  const inputFMSFile = useRef(null);
  const inputCrewFile = useRef(null);
  const numberUnreadActions = useSelector(getUnreadActions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_uploadedFile, setUploadFile] = useState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  useEffect(() => {
    if (loggedUser) {
      dispatch(fetchActions(loggedUser.id));
    }
    // every half hour update actions for badge
    const interval = setInterval(() => {
      if (loggedUser) {
        dispatch(fetchActions(loggedUser.id));
      }
    }, 1800000);
    return () => clearInterval(interval);
  }, [dispatch, loggedUser]);
  const handleMenu = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const firstPart = useMemo(() => `${pathname.split('/')[1]}`, [pathname]);

  const isActive = useCallback(
    (value: string): boolean => firstPart === value.toLocaleLowerCase(),
    [firstPart],
  );

  const renderMenuItems = (): JSX.Element[] =>
    menuItems.map(item => (
      <Link key={item.route} to={`/${item.route}`} css={linkCss}>
        <Button disableElevation css={menuItemCss(isActive(item.route))}>
          <Badge
            badgeContent={numberUnreadActions}
            invisible={item.name !== 'Actions' || numberUnreadActions === 0}
            color="secondary"
          >
            {item.name}
          </Badge>
        </Button>
        <div css={menuItemUnderlineCss(isActive(item.route))} />
      </Link>
    ));
  const handleFileClick = useCallback(() => {
    // `current` points to the mounted file input element
    if (inputFile !== null && inputFile.current !== null) {
      // @ts-ignore
      inputFile.current.click();
    }
  }, [inputFile]);

  const handleCrewClick = useCallback(() => {
    if (inputCrewFile !== null && inputCrewFile.current !== null) {
      // @ts-ignore
      inputCrewFile.current.click();
    }
  }, [inputCrewFile]);

  const handleUploadFile = useCallback(
    event => {
      const file = event.target.files[0];
      setUploadFile(file);
      dispatch(uploadConfigFile(file));
    },
    [dispatch],
  );

  const handleFileFMSClick = useCallback(() => {
    // `current` points to the mounted file input element
    if (inputFMSFile !== null && inputFMSFile.current !== null) {
      // @ts-ignore
      inputFMSFile.current.click();
    }
  }, [inputFMSFile]);
  const handleUploadFMSFile = useCallback(
    event => {
      const file = event.target.files[0];
      dispatch(uploadFMSFile(file));
    },
    [dispatch],
  );

  const handleUploadCrewFile = useCallback(
    event => {
      const file = event.target.files[0];
      dispatch(uploadCrewFile(file));
    },
    [dispatch],
  );
  const handleDownloadFile = useCallback(
    async (type: ConfigurationControllerDownloadDemoConfigFileTypeEnum) => {
      const response = (await dispatch(downloadConfigFile(type))) as any;

      if (!response) {
        return;
      }
      downloadFile(response, `${type}-file`);
    },
    [dispatch],
  );

  return (
    <AppBar position="sticky" css={appBarCss}>
      <Toolbar disableGutters={false} css={toolbarCss}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={1} sm={2} md={3}>
            <span css={titleCss}>ADM</span>
          </Grid>
          <Grid item xs={10} sm={8} md={5}>
            <Grid container justify="center" alignItems="center">
              {renderMenuItems()}
            </Grid>
          </Grid>
          <Grid item xs={1} sm={2} md={4}>
            <Grid container justify="flex-end" alignItems="center">
              <Grid item css={dateCss}>
                {newestFMS && JSON.stringify(newestFMS) !== '[]'
                  ? newestFMS
                  : 'No FMS file'}
              </Grid>
              <div css={dateCss}>
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item lg={12}>
                    <Hidden mdDown>
                      <Grid item>
                        {JSON.stringify(lastUpdated) !== '[]' &&
                          format(lastUpdated, 'dd.MM.yyyy')}
                      </Grid>
                      <Grid>
                        {JSON.stringify(lastUpdated) !== '[]' &&
                          format(lastUpdated, 'HH:mm:ss')}
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </div>
              <span css={userNameCss}>
                <Hidden mdDown>
                  {user.firstName} {user.lastName}
                </Hidden>
              </span>

              <IconButton onClick={handleMenu} css={avatarIconCss}>
                <Avatar color="primary">
                  {/* TODO user avatar */}
                  {user.firstName.substring(0, 1)}
                  {user.lastName.substring(0, 1)}
                </Avatar>
              </IconButton>

              <Hidden smDown>
                <IconButton
                  onClick={handleMenu}
                  className="logout-button"
                  css={[iconCss, logoutCss]}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </Hidden>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <input
                  type="file"
                  id="fileConfig"
                  ref={inputFile}
                  onInputCapture={handleUploadFile}
                  style={{ display: 'none' }}
                  value=""
                />
                <input
                  type="file"
                  id="fileFMS"
                  ref={inputFMSFile}
                  onInputCapture={handleUploadFMSFile}
                  style={{ display: 'none' }}
                  value=""
                />
                <input
                  type="file"
                  id="fileCrew"
                  ref={inputCrewFile}
                  onInputCapture={handleUploadCrewFile}
                  style={{ display: 'none' }}
                  value=""
                />
                <MultiLevel title="Download file">
                  <MenuItem
                    dense
                    onClick={() =>
                      handleDownloadFile(
                        ConfigurationControllerDownloadDemoConfigFileTypeEnum.CONFIG,
                      )
                    }
                  >
                    Config file
                  </MenuItem>
                  <MenuItem
                    dense
                    onClick={() =>
                      handleDownloadFile(
                        ConfigurationControllerDownloadDemoConfigFileTypeEnum.CREW,
                      )
                    }
                  >
                    Crew file
                  </MenuItem>
                </MultiLevel>
                <MultiLevel title="Upload file">
                  <MenuItem dense onClick={handleFileClick}>
                    Config file
                  </MenuItem>
                  <MenuItem dense onClick={handleCrewClick}>
                    Crew file
                  </MenuItem>
                  <MenuItem dense divider onClick={handleFileFMSClick}>
                    FMS file
                  </MenuItem>
                </MultiLevel>
                <MenuItem
                  component="a"
                  href="https://docs.google.com/document/d/1B6qXxa6wrBrzcmflFi5wy9L_ShyG2lVI9Y9FaXjK2Hk/"
                  target="_blank"
                >
                  FAQ
                </MenuItem>
                <MenuItem onClick={logOut}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const appBarCss: SerializedStyles = css`
  .MuiToolbar-regular {
    min-height: ${AppMenuHeight}px !important;
  }
`;
const toolbarCss: SerializedStyles = css`
  background-color: ${Colors.primary} !important;
  height: ${AppMenuHeight}px !important;
  padding: 0 ${MPX * 7}px 0 ${MPX * 10}px !important;
`;

const titleCss: SerializedStyles = css`
  font-size: ${MPX * 7}px;
  font-weight: 600;
  color: ${Colors.white};
  letter-spacing: 0px;
  opacity: 0.5;
`;

const menuItemCss = (isActive: boolean): SerializedStyles => css`
  margin: 0 ${MPX * 4}px !important;
  text-transform: capitalize !important;
  color: ${Colors.white} !important;
  border-radius: 0px !important;
  height: ${AppMenuHeight - MPX * 2}px !important;
  font-size: ${MPX * 4.5}px !important;
  font-weight: ${isActive ? 500 : 400} !important;
  padding-top: ${MPX * 2}px !important;
`;

const menuItemUnderlineCss = (isActive: boolean): SerializedStyles => css`
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: ${MPX * 2}px solid ${isActive ? Colors.white : Colors.primary};
`;

const userNameCss: SerializedStyles = css`
  opacity: 0.5;
  align-self: center;
  font-size: ${MPX * 4.5}px;
  font-weight: 500;
  text-transform: capitalize;
  color: ${Colors.white};
  padding-right: ${MPX * 5}px !important;
  overflow: hidden;
  max-width: 200px !important;
  display: -webkit-box;
  max-lines: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const dateCss: SerializedStyles = css`
  opacity: 0.5;
  align-self: center;
  font-size: ${MPX * 3.5}px;
  margin-left: ${MPX * 2.5}px !important;
  font-weight: 500;
  text-transform: capitalize;
  color: ${Colors.white};
  padding-right: ${MPX * 5}px !important;
  overflow: hidden;
  max-width: 200px !important;
  display: -webkit-box;
  text-overflow: ellipsis;
`;

const logoutCss: SerializedStyles = css`
  margin-right: 0 !important;
`;

const avatarIconCss: SerializedStyles = css`
  color: ${Colors.white} !important;
  width: ${MPX * 13}px !important;
  height: ${MPX * 13}px !important;
  .MuiAvatar-colorDefault {
    color: ${Colors.primary} !important;
    font-size: ${MPX * 5.25}px;
  }
  .MuiAvatar-root {
    width: ${MPX * 13}px !important;
    height: ${MPX * 13}px !important;
  }
`;
const iconCss: SerializedStyles = css`
  color: ${Colors.white} !important;
  width: ${MPX * 7.5}px !important;
  height: ${MPX * 7.5}px !important;
`;
