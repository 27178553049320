/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutFlightBasicInfoDto,
    PutFlightBasicInfoDtoFromJSON,
    PutFlightBasicInfoDtoFromJSONTyped,
    PutFlightBasicInfoDtoToJSON,
    PutFlightCargoDto,
    PutFlightCargoDtoFromJSON,
    PutFlightCargoDtoFromJSONTyped,
    PutFlightCargoDtoToJSON,
    PutFlightCrewDto,
    PutFlightCrewDtoFromJSON,
    PutFlightCrewDtoFromJSONTyped,
    PutFlightCrewDtoToJSON,
    PutFlightHandlingDto,
    PutFlightHandlingDtoFromJSON,
    PutFlightHandlingDtoFromJSONTyped,
    PutFlightHandlingDtoToJSON,
    PutFlightPaxDto,
    PutFlightPaxDtoFromJSON,
    PutFlightPaxDtoFromJSONTyped,
    PutFlightPaxDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutFlightDto
 */
export interface PutFlightDto {
    /**
     * 
     * @type {number}
     * @memberof PutFlightDto
     */
    id?: number;
    /**
     * 
     * @type {PutFlightBasicInfoDto}
     * @memberof PutFlightDto
     */
    basicInfo?: PutFlightBasicInfoDto;
    /**
     * 
     * @type {PutFlightCargoDto}
     * @memberof PutFlightDto
     */
    cargo?: PutFlightCargoDto;
    /**
     * 
     * @type {PutFlightPaxDto}
     * @memberof PutFlightDto
     */
    pax?: PutFlightPaxDto;
    /**
     * 
     * @type {PutFlightHandlingDto}
     * @memberof PutFlightDto
     */
    handling?: PutFlightHandlingDto;
    /**
     * 
     * @type {PutFlightCrewDto}
     * @memberof PutFlightDto
     */
    crew?: PutFlightCrewDto;
}

export function PutFlightDtoFromJSON(json: any): PutFlightDto {
    return PutFlightDtoFromJSONTyped(json, false);
}

export function PutFlightDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'basicInfo': !exists(json, 'basicInfo') ? undefined : PutFlightBasicInfoDtoFromJSON(json['basicInfo']),
        'cargo': !exists(json, 'cargo') ? undefined : PutFlightCargoDtoFromJSON(json['cargo']),
        'pax': !exists(json, 'pax') ? undefined : PutFlightPaxDtoFromJSON(json['pax']),
        'handling': !exists(json, 'handling') ? undefined : PutFlightHandlingDtoFromJSON(json['handling']),
        'crew': !exists(json, 'crew') ? undefined : PutFlightCrewDtoFromJSON(json['crew']),
    };
}

export function PutFlightDtoToJSON(value?: PutFlightDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'basicInfo': PutFlightBasicInfoDtoToJSON(value.basicInfo),
        'cargo': PutFlightCargoDtoToJSON(value.cargo),
        'pax': PutFlightPaxDtoToJSON(value.pax),
        'handling': PutFlightHandlingDtoToJSON(value.handling),
        'crew': PutFlightCrewDtoToJSON(value.crew),
    };
}


