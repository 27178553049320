/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutFlightsStatusDto,
    PutFlightsStatusDtoFromJSON,
    PutFlightsStatusDtoFromJSONTyped,
    PutFlightsStatusDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutRequestStatusDto
 */
export interface PutRequestStatusDto {
    /**
     * Is request's basic info part done?
     * @type {boolean}
     * @memberof PutRequestStatusDto
     */
    isBasicInfoDone: boolean;
    /**
     * Is request's nota part done?
     * @type {boolean}
     * @memberof PutRequestStatusDto
     */
    isNotaDone: boolean;
    /**
     * Is request's diplomaticApprovals part done?
     * @type {boolean}
     * @memberof PutRequestStatusDto
     */
    isDiplomaticApprovalDone: boolean;
    /**
     * Is request's summary part done?
     * @type {boolean}
     * @memberof PutRequestStatusDto
     */
    isSummaryDone: boolean;
    /**
     * 
     * @type {PutFlightsStatusDto}
     * @memberof PutRequestStatusDto
     */
    flights: PutFlightsStatusDto;
}

export function PutRequestStatusDtoFromJSON(json: any): PutRequestStatusDto {
    return PutRequestStatusDtoFromJSONTyped(json, false);
}

export function PutRequestStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutRequestStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isBasicInfoDone': json['isBasicInfoDone'],
        'isNotaDone': json['isNotaDone'],
        'isDiplomaticApprovalDone': json['isDiplomaticApprovalDone'],
        'isSummaryDone': json['isSummaryDone'],
        'flights': PutFlightsStatusDtoFromJSON(json['flights']),
    };
}

export function PutRequestStatusDtoToJSON(value?: PutRequestStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isBasicInfoDone': value.isBasicInfoDone,
        'isNotaDone': value.isNotaDone,
        'isDiplomaticApprovalDone': value.isDiplomaticApprovalDone,
        'isSummaryDone': value.isSummaryDone,
        'flights': PutFlightsStatusDtoToJSON(value.flights),
    };
}


