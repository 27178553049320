/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Role {
    ADMIN = 'ADMIN',
    CHIEF_PILOT = 'CHIEF_PILOT',
    HEADQUARTERS = 'HEADQUARTERS',
    HEAD_PLANNER = 'HEAD_PLANNER',
    CHIEF = 'CHIEF',
    NMCC = 'NMCC',
    NAVIGATOR = 'NAVIGATOR',
    CHIEF_CABIN_CREW = 'CHIEF_CABIN_CREW',
    PILOT_NAVIGATOR = 'PILOT_NAVIGATOR',
    PILOT = 'PILOT',
    PASSPORT_DEPARTMENT = 'PASSPORT_DEPARTMENT',
    EXTERNAL = 'EXTERNAL'
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    return json as Role;
}

export function RoleToJSON(value?: Role | null): any {
    return value as any;
}

