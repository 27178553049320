/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRequestFileDto,
    GetRequestFileDtoFromJSON,
    GetRequestFileDtoFromJSONTyped,
    GetRequestFileDtoToJSON,
    GetRequestFileUploadErrorResultDto,
    GetRequestFileUploadErrorResultDtoFromJSON,
    GetRequestFileUploadErrorResultDtoFromJSONTyped,
    GetRequestFileUploadErrorResultDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestFilesUploadResultsDto
 */
export interface GetRequestFilesUploadResultsDto {
    /**
     * 
     * @type {Array<GetRequestFileDto>}
     * @memberof GetRequestFilesUploadResultsDto
     */
    successes: Array<GetRequestFileDto>;
    /**
     * 
     * @type {Array<GetRequestFileUploadErrorResultDto>}
     * @memberof GetRequestFilesUploadResultsDto
     */
    errors: Array<GetRequestFileUploadErrorResultDto>;
}

export function GetRequestFilesUploadResultsDtoFromJSON(json: any): GetRequestFilesUploadResultsDto {
    return GetRequestFilesUploadResultsDtoFromJSONTyped(json, false);
}

export function GetRequestFilesUploadResultsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestFilesUploadResultsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'successes': ((json['successes'] as Array<any>).map(GetRequestFileDtoFromJSON)),
        'errors': ((json['errors'] as Array<any>).map(GetRequestFileUploadErrorResultDtoFromJSON)),
    };
}

export function GetRequestFilesUploadResultsDtoToJSON(value?: GetRequestFilesUploadResultsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'successes': ((value.successes as Array<any>).map(GetRequestFileDtoToJSON)),
        'errors': ((value.errors as Array<any>).map(GetRequestFileUploadErrorResultDtoToJSON)),
    };
}


