/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Permission {
    CREW = 'CREW',
    DIPLOMATIC_APPROVAL = 'DIPLOMATIC_APPROVAL',
    FLIGHTS = 'FLIGHTS',
    FLIGHT_REQUEST_APPROVAL = 'FLIGHT_REQUEST_APPROVAL'
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

