/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateUserDto,
    CreateUserDtoFromJSON,
    CreateUserDtoToJSON,
    GetActionDto,
    GetActionDtoFromJSON,
    GetActionDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoToJSON,
} from '../models';

export interface UsersControllerCreateUserRequest {
    createUserDto: CreateUserDto;
}

export interface UsersControllerGetActionsRequest {
    id: number;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Create new user
     */
    async usersControllerCreateUserRaw(requestParameters: UsersControllerCreateUserRequest): Promise<runtime.ApiResponse<GetUserDto>> {
        if (requestParameters.createUserDto === null || requestParameters.createUserDto === undefined) {
            throw new runtime.RequiredError('createUserDto','Required parameter requestParameters.createUserDto was null or undefined when calling usersControllerCreateUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDtoToJSON(requestParameters.createUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDtoFromJSON(jsonValue));
    }

    /**
     * Create new user
     */
    async usersControllerCreateUser(requestParameters: UsersControllerCreateUserRequest): Promise<GetUserDto> {
        const response = await this.usersControllerCreateUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch all users
     */
    async usersControllerFetchAllUsersRaw(): Promise<runtime.ApiResponse<Array<GetUserDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetUserDtoFromJSON));
    }

    /**
     * Fetch all users
     */
    async usersControllerFetchAllUsers(): Promise<Array<GetUserDto>> {
        const response = await this.usersControllerFetchAllUsersRaw();
        return await response.value();
    }

    /**
     * Get actions assigned to user
     */
    async usersControllerGetActionsRaw(requestParameters: UsersControllerGetActionsRequest): Promise<runtime.ApiResponse<Array<GetActionDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersControllerGetActions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/actions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetActionDtoFromJSON));
    }

    /**
     * Get actions assigned to user
     */
    async usersControllerGetActions(requestParameters: UsersControllerGetActionsRequest): Promise<Array<GetActionDto>> {
        const response = await this.usersControllerGetActionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get info about logged user
     */
    async usersControllerGetMeRaw(): Promise<runtime.ApiResponse<GetUserDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDtoFromJSON(jsonValue));
    }

    /**
     * Get info about logged user
     */
    async usersControllerGetMe(): Promise<GetUserDto> {
        const response = await this.usersControllerGetMeRaw();
        return await response.value();
    }

}
