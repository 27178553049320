import { createSelector } from 'reselect';
import { GetRequestEnumDataDto } from '../../api/models/GetRequestEnumDataDto';
import { GetPlaneDto, Role } from '../../api';
import { CombinedState } from '../combinedReducers';

export const getRequestEnumData = (
  enumType: keyof Omit<GetRequestEnumDataDto, 'airportsEnum'>,
) =>
  createSelector(
    (state: CombinedState) => state.general,
    general => {
      if (general.requestEnumData == null) {
        return [];
      }

      const askedEnums: any = general.requestEnumData[enumType];
      if (enumType === 'planeEnums') {
        return askedEnums.map(i => ({
          text: `${i.name} - ${i.number}`,
          value: i.nameNumber,
        }));
      }

      if (enumType === 'travelDocumentsEnum') {
        return askedEnums;
      }

      if (enumType === 'crewMembersEnum') {
        return askedEnums;
      }

      if (enumType === 'lastUpdated') {
        return askedEnums;
      }
      if (enumType === 'newestFMSFileName') {
        return askedEnums;
      }

      return askedEnums.map(i => ({ text: i.name, value: i.name }));
    },
  );

export const getFilteredPlanesEnum = (withoutPlane: GetPlaneDto | undefined) =>
  createSelector(
    (state: CombinedState) => state.general,
    general => {
      if (general.requestEnumData == null) {
        return [];
      }

      const allPlanes: any[] = general.requestEnumData.planeEnums.map(i => ({
        text: `${i.name} - ${i.number}`,
        value: i.nameNumber,
      }));
      return withoutPlane
        ? allPlanes.filter(plane => plane.value !== withoutPlane.nameNumber)
        : allPlanes;
    },
  );

export const getSnackbar = createSelector(
  (state: CombinedState) => state.general,
  general => general.snackbar,
);

export const getAssignedPlanners = createSelector(
  (state: CombinedState) => state.general,
  general =>
    general.allUsers
      .filter(user => user.role === Role.HEAD_PLANNER)
      .map(user => ({
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      })),
);

export const getUsersForSelect = createSelector(
  (state: CombinedState) => state.general,
  general =>
    general.allUsers.map(user => ({
      value: user.id,
      text: `${user.firstName} ${user.lastName}`,
    })),
);
