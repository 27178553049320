import { SnackbarOrigin } from 'notistack';
import { format as formatUTC, utcToZonedTime } from 'date-fns-tz';
import { MPX } from '../../styles/themes';
import { PutFlightBasicInfoDtoDATypeEnum } from '../../api';

/**
 * 2020-08-01 06:47 such a format is accepted by the datepicker
 */
export const datePickerFormat = `HH:mm d.MM.yyyy`;

export const timeFormat = `HH:mm`;
/**
 * 1.1.2020 left menu date format
 */
export const leftMenuFormat = `d.M.yyyy`;

/**
 * 2020-08-01 06:47 such a format is accepted by the datepicker
 */
export const tableFormat = `d.MM.yyyy HH:mm`;

export const DEFAULT_INPUT_WIDTH = MPX * 75;

export const INPUT_MOBILE_BREAKPOINT = 570;

export const FLIGHT_MOBILE_BREAKPOINT = 880;

// snackbar positioning
export const anchorOrigin: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

export const formatDateUTC = (date: string | Date, format: string) =>
  formatUTC(utcToZonedTime(date, 'Etc/GMT+0'), format, {
    awareOfUnicodeTokens: true,
    timeZone: 'Etc/GMT+0',
  });

export const FMS_WARNING_TEXT = 'Airport is not available in FMS database';

export const daTypeOptions = Object.keys(PutFlightBasicInfoDtoDATypeEnum).map(
  key => {
    let text = 'VIP';
    if (key === PutFlightBasicInfoDtoDATypeEnum.G) {
      text = 'General';
    }

    if (key === PutFlightBasicInfoDtoDATypeEnum.H) {
      text = 'Hazmat';
    }

    return {
      value: key as PutFlightBasicInfoDtoDATypeEnum,
      text,
    };
  },
);
