/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetConfigFileResultsDto,
    GetConfigFileResultsDtoFromJSON,
    GetConfigFileResultsDtoToJSON,
} from '../models';

export interface ConfigurationControllerDownloadDemoConfigFileRequest {
    type: ConfigurationControllerDownloadDemoConfigFileTypeEnum;
}

export interface ConfigurationControllerUploadConfigExcelFileRequest {
    configExcelFile: Blob;
}

export interface ConfigurationControllerUploadCrewExcelFileRequest {
    configExcelFile: Blob;
}

export interface ConfigurationControllerUploadFMSExcelFileRequest {
    configExcelFile: Blob;
}

/**
 * 
 */
export class ConfigurationApi extends runtime.BaseAPI {

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download demo config file
     */
    async configurationControllerDownloadDemoConfigFileRaw(requestParameters: ConfigurationControllerDownloadDemoConfigFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling configurationControllerDownloadDemoConfigFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configuration/file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download demo config file
     */
    async configurationControllerDownloadDemoConfigFile(requestParameters: ConfigurationControllerDownloadDemoConfigFileRequest): Promise<Blob> {
        const response = await this.configurationControllerDownloadDemoConfigFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload configuration file (in EXCEL file)
     */
    async configurationControllerUploadConfigExcelFileRaw(requestParameters: ConfigurationControllerUploadConfigExcelFileRequest): Promise<runtime.ApiResponse<GetConfigFileResultsDto>> {
        if (requestParameters.configExcelFile === null || requestParameters.configExcelFile === undefined) {
            throw new runtime.RequiredError('configExcelFile','Required parameter requestParameters.configExcelFile was null or undefined when calling configurationControllerUploadConfigExcelFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.configExcelFile !== undefined) {
            formParams.append('configExcelFile', requestParameters.configExcelFile as any);
        }

        const response = await this.request({
            path: `/configuration/config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConfigFileResultsDtoFromJSON(jsonValue));
    }

    /**
     * Upload configuration file (in EXCEL file)
     */
    async configurationControllerUploadConfigExcelFile(requestParameters: ConfigurationControllerUploadConfigExcelFileRequest): Promise<GetConfigFileResultsDto> {
        const response = await this.configurationControllerUploadConfigExcelFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload crew file (in EXCEL file)
     */
    async configurationControllerUploadCrewExcelFileRaw(requestParameters: ConfigurationControllerUploadCrewExcelFileRequest): Promise<runtime.ApiResponse<GetConfigFileResultsDto>> {
        if (requestParameters.configExcelFile === null || requestParameters.configExcelFile === undefined) {
            throw new runtime.RequiredError('configExcelFile','Required parameter requestParameters.configExcelFile was null or undefined when calling configurationControllerUploadCrewExcelFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.configExcelFile !== undefined) {
            formParams.append('configExcelFile', requestParameters.configExcelFile as any);
        }

        const response = await this.request({
            path: `/configuration/config/crew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConfigFileResultsDtoFromJSON(jsonValue));
    }

    /**
     * Upload crew file (in EXCEL file)
     */
    async configurationControllerUploadCrewExcelFile(requestParameters: ConfigurationControllerUploadCrewExcelFileRequest): Promise<GetConfigFileResultsDto> {
        const response = await this.configurationControllerUploadCrewExcelFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload FMS file (in EXCEL file)
     */
    async configurationControllerUploadFMSExcelFileRaw(requestParameters: ConfigurationControllerUploadFMSExcelFileRequest): Promise<runtime.ApiResponse<GetConfigFileResultsDto>> {
        if (requestParameters.configExcelFile === null || requestParameters.configExcelFile === undefined) {
            throw new runtime.RequiredError('configExcelFile','Required parameter requestParameters.configExcelFile was null or undefined when calling configurationControllerUploadFMSExcelFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.configExcelFile !== undefined) {
            formParams.append('configExcelFile', requestParameters.configExcelFile as any);
        }

        const response = await this.request({
            path: `/configuration/config/fms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConfigFileResultsDtoFromJSON(jsonValue));
    }

    /**
     * Upload FMS file (in EXCEL file)
     */
    async configurationControllerUploadFMSExcelFile(requestParameters: ConfigurationControllerUploadFMSExcelFileRequest): Promise<GetConfigFileResultsDto> {
        const response = await this.configurationControllerUploadFMSExcelFileRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ConfigurationControllerDownloadDemoConfigFileTypeEnum {
    FMS = 'FMS',
    CONFIG = 'CONFIG',
    CREW = 'CREW'
}
