/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import { Box, Button } from '@material-ui/core';
import { downloadFile } from '../../helpers/functions/fileHelpers';

export const renderStatusBox = (
  statusText: string,
  download: { url: string; name: string } | undefined,
) => {
  return (
    <div css={buttonCss}>
      <div>{statusText}</div>
      {download != null ? (
        <Box mt={2}>
          <Button
            color="inherit"
            onClick={() => downloadFile(download!.url, download!.name)}
            css={buttonCss}
          >
            {download!.name}
          </Button>
        </Box>
      ) : null}
    </div>
  );
};

const buttonCss = css`
  overflow-wrap: anywhere;
`;
