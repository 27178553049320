/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetPlaneDto,
    GetPlaneDtoFromJSON,
    GetPlaneDtoFromJSONTyped,
    GetPlaneDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestOverviewDto
 */
export interface GetRequestOverviewDto {
    /**
     * Flight request ID
     * @type {number}
     * @memberof GetRequestOverviewDto
     */
    id: number;
    /**
     * Flight request name
     * @type {string}
     * @memberof GetRequestOverviewDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestOverviewDto
     */
    requestedBy?: string | null;
    /**
     * From date
     * @type {Date}
     * @memberof GetRequestOverviewDto
     */
    from?: Date | null;
    /**
     * To date
     * @type {Date}
     * @memberof GetRequestOverviewDto
     */
    to?: Date | null;
    /**
     * Flight request destination
     * @type {string}
     * @memberof GetRequestOverviewDto
     */
    destination?: string | null;
    /**
     * 
     * @type {GetPlaneDto}
     * @memberof GetRequestOverviewDto
     */
    assignedPlane?: GetPlaneDto;
    /**
     * Is request ready?
     * @type {boolean}
     * @memberof GetRequestOverviewDto
     */
    isReadyToDispatch: boolean;
    /**
     * Is request active?
     * @type {boolean}
     * @memberof GetRequestOverviewDto
     */
    isActive: boolean;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetRequestOverviewDto
     */
    assignedPlaner?: GetUserDto;
}

export function GetRequestOverviewDtoFromJSON(json: any): GetRequestOverviewDto {
    return GetRequestOverviewDtoFromJSONTyped(json, false);
}

export function GetRequestOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'requestedBy': !exists(json, 'requestedBy') ? undefined : json['requestedBy'],
        'from': !exists(json, 'from') ? undefined : (json['from'] === null ? null : new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (json['to'] === null ? null : new Date(json['to'])),
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'assignedPlane': !exists(json, 'assignedPlane') ? undefined : GetPlaneDtoFromJSON(json['assignedPlane']),
        'isReadyToDispatch': json['isReadyToDispatch'],
        'isActive': json['isActive'],
        'assignedPlaner': !exists(json, 'assignedPlaner') ? undefined : GetUserDtoFromJSON(json['assignedPlaner']),
    };
}

export function GetRequestOverviewDtoToJSON(value?: GetRequestOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'requestedBy': value.requestedBy,
        'from': value.from === undefined ? undefined : (value.from === null ? null : value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to === null ? null : value.to.toISOString()),
        'destination': value.destination,
        'assignedPlane': GetPlaneDtoToJSON(value.assignedPlane),
        'isReadyToDispatch': value.isReadyToDispatch,
        'isActive': value.isActive,
        'assignedPlaner': GetUserDtoToJSON(value.assignedPlaner),
    };
}


