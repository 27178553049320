/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfApproval {
    Long_time_permit = 'Long time permit',
    One_time_permit = 'One time permit',
    Pprs = 'Pprs',
    Airport_slots = 'Airport slots'
}

export function TypeOfApprovalFromJSON(json: any): TypeOfApproval {
    return TypeOfApprovalFromJSONTyped(json, false);
}

export function TypeOfApprovalFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeOfApproval {
    return json as TypeOfApproval;
}

export function TypeOfApprovalToJSON(value?: TypeOfApproval | null): any {
    return value as any;
}

