/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResponsibleOrganization {
    NMCC_Star_Boleslav = 'NMCC Stará Boleslav',
    Ministerstvo_zahraninch_vc = 'Ministerstvo zahraničních věcí'
}

export function ResponsibleOrganizationFromJSON(json: any): ResponsibleOrganization {
    return ResponsibleOrganizationFromJSONTyped(json, false);
}

export function ResponsibleOrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponsibleOrganization {
    return json as ResponsibleOrganization;
}

export function ResponsibleOrganizationToJSON(value?: ResponsibleOrganization | null): any {
    return value as any;
}

