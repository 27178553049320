import React from 'react';
import { AppAutocompleteInput, AppTextInput } from '../../../shared/Forms';

export const DOMESTIC = 'Domestic';

export const purposesOfDomesticFlights: string[] = [
  'Výcvikové létání 241. dtl',
  'Výcvikové létání 242. dtl',
  'Výcvikové létání 243. dtl',
  'Cvičné lety SAR',
  'Účelový let',
  'Zálet',
  'Ukázka techniky',
  'Přeprava',
  'Technický přelet',
  'Pohotovost LKKB',
  'Pohotovost LKLN',
  'Foto',
];

const PurposeOfFlight = ({
  isDomestic,
  purpose,
}: {
  isDomestic: boolean;
  purpose: string | undefined;
}) => {
  const puposesOfDomesticFlightsEnum = purposesOfDomesticFlights.map(
    purpose => {
      return { text: purpose, value: purpose };
    },
  );

  return (
    <React.Fragment>
      {isDomestic ? (
        <AppAutocompleteInput
          name="purposeOfFlight"
          label="Purpose of flight"
          options={puposesOfDomesticFlightsEnum}
          defaultValue={purpose ? { value: purpose, text: purpose } : undefined}
        />
      ) : (
        <AppTextInput name="purposeOfFlight" label="Purpose of flight" />
      )}
    </React.Fragment>
  );
};

export default PurposeOfFlight;
