/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetFlightStatusDto,
    GetFlightStatusDtoFromJSON,
    GetFlightStatusDtoFromJSONTyped,
    GetFlightStatusDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFlightsStatusDto
 */
export interface GetFlightsStatusDto {
    /**
     * Are all flights in request done?
     * @type {boolean}
     * @memberof GetFlightsStatusDto
     */
    areAllFlightsDone: boolean;
    /**
     * 
     * @type {Array<GetFlightStatusDto>}
     * @memberof GetFlightsStatusDto
     */
    flights: Array<GetFlightStatusDto>;
}

export function GetFlightsStatusDtoFromJSON(json: any): GetFlightsStatusDto {
    return GetFlightsStatusDtoFromJSONTyped(json, false);
}

export function GetFlightsStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFlightsStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'areAllFlightsDone': json['areAllFlightsDone'],
        'flights': ((json['flights'] as Array<any>).map(GetFlightStatusDtoFromJSON)),
    };
}

export function GetFlightsStatusDtoToJSON(value?: GetFlightsStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'areAllFlightsDone': value.areAllFlightsDone,
        'flights': ((value.flights as Array<any>).map(GetFlightStatusDtoToJSON)),
    };
}


