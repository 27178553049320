/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAirportDto,
    GetAirportDtoFromJSON,
    GetAirportDtoToJSON,
    GetRequestEnumDataDto,
    GetRequestEnumDataDtoFromJSON,
    GetRequestEnumDataDtoToJSON,
} from '../models';

export interface EnumsControllerSearchAirportRequest {
    q: string;
    isDomestic: boolean;
}

/**
 * 
 */
export class EnumsApi extends runtime.BaseAPI {

    /**
     * Gets all enums
     */
    async enumsControllerGetAllEnumsRaw(): Promise<runtime.ApiResponse<GetRequestEnumDataDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/enums/request-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestEnumDataDtoFromJSON(jsonValue));
    }

    /**
     * Gets all enums
     */
    async enumsControllerGetAllEnums(): Promise<GetRequestEnumDataDto> {
        const response = await this.enumsControllerGetAllEnumsRaw();
        return await response.value();
    }

    /**
     * Airports search whisper
     */
    async enumsControllerSearchAirportRaw(requestParameters: EnumsControllerSearchAirportRequest): Promise<runtime.ApiResponse<Array<GetAirportDto>>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling enumsControllerSearchAirport.');
        }

        if (requestParameters.isDomestic === null || requestParameters.isDomestic === undefined) {
            throw new runtime.RequiredError('isDomestic','Required parameter requestParameters.isDomestic was null or undefined when calling enumsControllerSearchAirport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.isDomestic !== undefined) {
            queryParameters['isDomestic'] = requestParameters.isDomestic;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/enums/airports/whisper`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAirportDtoFromJSON));
    }

    /**
     * Airports search whisper
     */
    async enumsControllerSearchAirport(requestParameters: EnumsControllerSearchAirportRequest): Promise<Array<GetAirportDto>> {
        const response = await this.enumsControllerSearchAirportRaw(requestParameters);
        return await response.value();
    }

}
