import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { setIsUserRefreshed, setLoadState, setLoggedUser } from './actions';
import { GetUserDto } from '../../api';
import { LoadingState } from '../../helpers/enums/general';

export interface State {
  loggedUser: GetUserDto | undefined;
  isUserRefreshed: boolean;
  loadState: LoadingState;
}

export const initialState: State = {
  loggedUser: undefined,
  isUserRefreshed: false,
  loadState: LoadingState.Initial,
};

export const authReducer = reducer<State>(
  initialState,
  on(setLoggedUser, (state: State, { payload }) => {
    state.loggedUser = payload.user;
  }),
  on(setIsUserRefreshed, (state: State, { payload }) => {
    state.isUserRefreshed = payload.isRefreshed;
  }),
  on(setLoadState, (state: State, { payload }) => {
    state.loadState = payload.state;
  }),
);
