/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionType,
    ActionTypeFromJSON,
    ActionTypeFromJSONTyped,
    ActionTypeToJSON,
    GetRequestLeanDto,
    GetRequestLeanDtoFromJSON,
    GetRequestLeanDtoFromJSONTyped,
    GetRequestLeanDtoToJSON,
    RequestPart,
    RequestPartFromJSON,
    RequestPartFromJSONTyped,
    RequestPartToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetActionDto
 */
export interface GetActionDto {
    /**
     * Action ID
     * @type {number}
     * @memberof GetActionDto
     */
    id: number;
    /**
     * Id of user from who is the actions created
     * @type {number}
     * @memberof GetActionDto
     */
    userFrom?: number | null;
    /**
     * Ids of users for who is the action created
     * @type {Array<number>}
     * @memberof GetActionDto
     */
    usersFor: Array<number>;
    /**
     * 
     * @type {ActionType}
     * @memberof GetActionDto
     */
    actionType: ActionType;
    /**
     * Action content
     * @type {string}
     * @memberof GetActionDto
     */
    what?: string | null;
    /**
     * Flight id assigned to an action
     * @type {number}
     * @memberof GetActionDto
     */
    flightId?: number | null;
    /**
     * 
     * @type {RequestPart}
     * @memberof GetActionDto
     */
    partOfRequest?: RequestPart;
    /**
     * Date when action was created
     * @type {Date}
     * @memberof GetActionDto
     */
    createdAt: Date;
    /**
     * Read at
     * @type {Date}
     * @memberof GetActionDto
     */
    readAt?: Date | null;
    /**
     * Approved at
     * @type {Date}
     * @memberof GetActionDto
     */
    approvedAt?: Date | null;
    /**
     * 
     * @type {GetRequestLeanDto}
     * @memberof GetActionDto
     */
    request: GetRequestLeanDto;
}

export function GetActionDtoFromJSON(json: any): GetActionDto {
    return GetActionDtoFromJSONTyped(json, false);
}

export function GetActionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetActionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userFrom': !exists(json, 'userFrom') ? undefined : json['userFrom'],
        'usersFor': json['usersFor'],
        'actionType': ActionTypeFromJSON(json['actionType']),
        'what': !exists(json, 'what') ? undefined : json['what'],
        'flightId': !exists(json, 'flightId') ? undefined : json['flightId'],
        'partOfRequest': !exists(json, 'partOfRequest') ? undefined : RequestPartFromJSON(json['partOfRequest']),
        'createdAt': (new Date(json['createdAt'])),
        'readAt': !exists(json, 'readAt') ? undefined : (json['readAt'] === null ? null : new Date(json['readAt'])),
        'approvedAt': !exists(json, 'approvedAt') ? undefined : (json['approvedAt'] === null ? null : new Date(json['approvedAt'])),
        'request': GetRequestLeanDtoFromJSON(json['request']),
    };
}

export function GetActionDtoToJSON(value?: GetActionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userFrom': value.userFrom,
        'usersFor': value.usersFor,
        'actionType': ActionTypeToJSON(value.actionType),
        'what': value.what,
        'flightId': value.flightId,
        'partOfRequest': RequestPartToJSON(value.partOfRequest),
        'createdAt': (value.createdAt.toISOString()),
        'readAt': value.readAt === undefined ? undefined : (value.readAt === null ? null : value.readAt.toISOString()),
        'approvedAt': value.approvedAt === undefined ? undefined : (value.approvedAt === null ? null : value.approvedAt.toISOString()),
        'request': GetRequestLeanDtoToJSON(value.request),
    };
}


