import { reducer } from 'ts-action';
import { on } from 'ts-action-immer';
import {
  GetActionDto,
  GetFlightOverviewDto,
  GetRequestDto,
  GetRequestOverviewDto,
  GetRequestStatusDto,
} from '../../api';
import { emptyRequestStatus } from '../../helpers/constants/defaultData';
import { LoadingState } from '../../helpers/enums/general';
import { sortDate } from '../../helpers/functions/calendar';
import { ExportingPDF } from '../../helpers/interfaces/pdf';
import {
  setActions,
  setActionsState,
  setActiveFlight,
  setActiveRequest,
  setActiveRequestStatus,
  setExportingPDF,
  setFlights,
  setFlightsState,
  setRequests,
  setRequestState,
  setSubmittedFormBeforeRoute,
  updateAction,
} from './actions';

export interface State {
  requestState: LoadingState;
  activeRequest: GetRequestDto | undefined;
  activeFlightId: number | undefined;
  activeRequestStatus: GetRequestStatusDto | undefined;
  allRequests: GetRequestOverviewDto[];
  actionsState: LoadingState;
  actions: GetActionDto[];
  flightsState: LoadingState;
  flights: GetFlightOverviewDto[];
  submittedBeforeRoute: boolean;
  exportingPdf: ExportingPDF | undefined;
}

export const initialState: State = {
  requestState: LoadingState.Initial,
  activeRequest: undefined,
  activeFlightId: undefined,
  activeRequestStatus: emptyRequestStatus,
  allRequests: [], // only request overview. Full request data is not saved anywhere
  actionsState: LoadingState.Initial,
  actions: [],
  flightsState: LoadingState.Initial,
  flights: [],
  // Used in create request and flight for check if everything was saved to BE before route
  submittedBeforeRoute: true,
  exportingPdf: undefined,
};

export const requestsReducer = reducer<State>(
  initialState,
  on(setActiveRequest, (state, { payload }) => {
    state.activeRequest = payload.request
      ? {
          ...payload.request,
          // newer flights according to departure time to the end
          flights: payload.request.flights.sort((a, b) =>
            sortDate(
              new Date(a.basicInfo.departureTime),
              new Date(b.basicInfo.departureTime),
            ),
          ),
        }
      : undefined;
  }),
  on(setActiveFlight, (state, { payload }) => {
    state.activeFlightId = payload.flightId;
  }),
  on(setActiveRequestStatus, (state, { payload }) => {
    state.activeRequestStatus = payload.status;
  }),
  on(setRequestState, (state: State, { payload }) => {
    state.requestState = payload.state;
  }),
  on(setRequests, (state, { payload }) => {
    state.allRequests = payload.requests;
  }),
  on(setActionsState, (state: State, { payload }) => {
    state.actionsState = payload.state;
  }),
  on(setActions, (state, { payload }) => {
    state.actions = payload.actions;
  }),
  on(updateAction, (state, { payload }) => {
    const { action } = payload;
    state.actions = state.actions.map(item =>
      item.id === action.id ? action : item,
    );
  }),
  on(setFlightsState, (state: State, { payload }) => {
    state.flightsState = payload.state;
  }),
  on(setFlights, (state, { payload }) => {
    state.flights = payload.flights;
  }),
  on(setSubmittedFormBeforeRoute, (state, { payload }) => {
    state.submittedBeforeRoute = payload;
  }),
  on(setExportingPDF, (state, { payload }) => {
    state.exportingPdf = payload;
  }),
);
