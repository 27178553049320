/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiplomaticApprovalType,
    DiplomaticApprovalTypeFromJSON,
    DiplomaticApprovalTypeFromJSONTyped,
    DiplomaticApprovalTypeToJSON,
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
    TypeOfApproval,
    TypeOfApprovalFromJSON,
    TypeOfApprovalFromJSONTyped,
    TypeOfApprovalToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetApprovalDto
 */
export interface GetApprovalDto {
    /**
     * 
     * @type {number}
     * @memberof GetApprovalDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalDto
     */
    authno?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalDto
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalDto
     */
    to?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalDto
     */
    url?: string | null;
    /**
     * 
     * @type {TypeOfApproval}
     * @memberof GetApprovalDto
     */
    typeOfApproval?: TypeOfApproval;
    /**
     * 
     * @type {DiplomaticApprovalType}
     * @memberof GetApprovalDto
     */
    dAType: DiplomaticApprovalType;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetApprovalDto
     */
    files: GetRequestFilesDto;
}

export function GetApprovalDtoFromJSON(json: any): GetApprovalDto {
    return GetApprovalDtoFromJSONTyped(json, false);
}

export function GetApprovalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApprovalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'country': json['country'],
        'authno': !exists(json, 'authno') ? undefined : json['authno'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'typeOfApproval': !exists(json, 'typeOfApproval') ? undefined : TypeOfApprovalFromJSON(json['typeOfApproval']),
        'dAType': DiplomaticApprovalTypeFromJSON(json['DAType']),
        'files': GetRequestFilesDtoFromJSON(json['files']),
    };
}

export function GetApprovalDtoToJSON(value?: GetApprovalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'country': value.country,
        'authno': value.authno,
        'from': value.from,
        'to': value.to,
        'url': value.url,
        'typeOfApproval': TypeOfApprovalToJSON(value.typeOfApproval),
        'DAType': DiplomaticApprovalTypeToJSON(value.dAType),
        'files': GetRequestFilesDtoToJSON(value.files),
    };
}


