/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { Grid, CircularProgress } from '@material-ui/core';

interface LoadingInterface {
  // eslint-disable-next-line react/require-default-props
  customCss?: SerializedStyles;
  // eslint-disable-next-line react/require-default-props
  size?: number;
}
export const Loading: React.FC<LoadingInterface> = ({
  customCss = wrapCss,
  size = 40,
}: LoadingInterface): JSX.Element => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justify="center"
    css={customCss}
  >
    <CircularProgress size={size} />
  </Grid>
);

const wrapCss: SerializedStyles = css`
  height: 100vh !important;
`;
