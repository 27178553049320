/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutCrewPersonDto,
    PutCrewPersonDtoFromJSON,
    PutCrewPersonDtoFromJSONTyped,
    PutCrewPersonDtoToJSON,
    PutCrewSameAsPreviousLeg,
    PutCrewSameAsPreviousLegFromJSON,
    PutCrewSameAsPreviousLegFromJSONTyped,
    PutCrewSameAsPreviousLegToJSON,
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutFlightCrewDto
 */
export interface PutFlightCrewDto {
    /**
     * 
     * @type {Array<PutCrewPersonDto>}
     * @memberof PutFlightCrewDto
     */
    flightCrew: Array<PutCrewPersonDto>;
    /**
     * 
     * @type {Array<PutCrewPersonDto>}
     * @memberof PutFlightCrewDto
     */
    cabinCrew: Array<PutCrewPersonDto>;
    /**
     * 
     * @type {Array<PutCrewPersonDto>}
     * @memberof PutFlightCrewDto
     */
    groundTechnicians: Array<PutCrewPersonDto>;
    /**
     * 
     * @type {Array<PutCrewPersonDto>}
     * @memberof PutFlightCrewDto
     */
    medicalDoctor: Array<PutCrewPersonDto>;
    /**
     * 
     * @type {Array<PutCrewPersonDto>}
     * @memberof PutFlightCrewDto
     */
    militaryPolice: Array<PutCrewPersonDto>;
    /**
     * 
     * @type {Array<PutCrewSameAsPreviousLeg>}
     * @memberof PutFlightCrewDto
     */
    sameAsPreviousLegs: Array<PutCrewSameAsPreviousLeg>;
    /**
     * 
     * @type {string}
     * @memberof PutFlightCrewDto
     */
    comments?: string | null;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightCrewDto
     */
    crewList: PutRequestFilesDto;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightCrewDto
     */
    generalDeclaration: PutRequestFilesDto;
}

export function PutFlightCrewDtoFromJSON(json: any): PutFlightCrewDto {
    return PutFlightCrewDtoFromJSONTyped(json, false);
}

export function PutFlightCrewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightCrewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'flightCrew': ((json['flightCrew'] as Array<any>).map(PutCrewPersonDtoFromJSON)),
        'cabinCrew': ((json['cabinCrew'] as Array<any>).map(PutCrewPersonDtoFromJSON)),
        'groundTechnicians': ((json['groundTechnicians'] as Array<any>).map(PutCrewPersonDtoFromJSON)),
        'medicalDoctor': ((json['medicalDoctor'] as Array<any>).map(PutCrewPersonDtoFromJSON)),
        'militaryPolice': ((json['militaryPolice'] as Array<any>).map(PutCrewPersonDtoFromJSON)),
        'sameAsPreviousLegs': ((json['sameAsPreviousLegs'] as Array<any>).map(PutCrewSameAsPreviousLegFromJSON)),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'crewList': PutRequestFilesDtoFromJSON(json['crewList']),
        'generalDeclaration': PutRequestFilesDtoFromJSON(json['generalDeclaration']),
    };
}

export function PutFlightCrewDtoToJSON(value?: PutFlightCrewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'flightCrew': ((value.flightCrew as Array<any>).map(PutCrewPersonDtoToJSON)),
        'cabinCrew': ((value.cabinCrew as Array<any>).map(PutCrewPersonDtoToJSON)),
        'groundTechnicians': ((value.groundTechnicians as Array<any>).map(PutCrewPersonDtoToJSON)),
        'medicalDoctor': ((value.medicalDoctor as Array<any>).map(PutCrewPersonDtoToJSON)),
        'militaryPolice': ((value.militaryPolice as Array<any>).map(PutCrewPersonDtoToJSON)),
        'sameAsPreviousLegs': ((value.sameAsPreviousLegs as Array<any>).map(PutCrewSameAsPreviousLegToJSON)),
        'comments': value.comments,
        'crewList': PutRequestFilesDtoToJSON(value.crewList),
        'generalDeclaration': PutRequestFilesDtoToJSON(value.generalDeclaration),
    };
}


