import { addDays } from 'date-fns';
import { formatDateUTC } from '../constants/common';
import { CalendarDataRange } from '../interfaces/calendar';

export const initCalendarRange = (): CalendarDataRange => {
  const curr = new Date();
  // current Monday is the day of the month - the day of the week + 1
  const currMonday = curr.getDate() - curr.getDay() + 1;
  const startDate = new Date(curr.setDate(currMonday));
  const endDate = addDays(startDate, 6);

  return {
    startDate,
    endDate,
  };
};

export const getWeekFromSelectedDay = (startDate: Date): Date[] => {
  const dayIndexes = [0, 1, 2, 3, 4, 5, 6];

  return dayIndexes.map(i => addDays(startDate, i));
};

export const sortDate = (aDate: Date, bDate: Date): number => {
  if (aDate < bDate) return -1;
  if (aDate > bDate) return 1;
  return 0;
};

export const getTimeFormat = (ISOString: string): string =>
  formatDateUTC(new Date(ISOString), 'HH:mm');

/**
 * create the date from ISO string
 * @param ISOString
 */
export const createDate = (ISOString: string | null | undefined): Date | null =>
  ISOString ? new Date(ISOString) : null;

export const setLastMidnight = (date: Date | string) =>
  new Date(new Date(date).setHours(0, 0, 0, 0));

export const setNextMidnight = (date: Date | string) =>
  new Date(new Date(date).setHours(23, 59, 59, 99));

interface DateUTCProps {
  year?: number;
  month?: number;
  date?: number;
  hours?: number;
}

export const DateUTC = ({ year, month, date, hours }: DateUTCProps): Date => {
  const d = new Date();
  return new Date(
    Date.UTC(
      year || d.getUTCFullYear(),
      month || d.getUTCMonth(),
      date || d.getUTCDate(),
      hours || d.getUTCHours(),
      0,
      0,
    ),
  );
};
