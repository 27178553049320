/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAuditChangeDto,
    GetAuditChangeDtoFromJSON,
    GetAuditChangeDtoFromJSONTyped,
    GetAuditChangeDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAuditDto
 */
export interface GetAuditDto {
    /**
     * 
     * @type {number}
     * @memberof GetAuditDto
     */
    eventId: number;
    /**
     * 
     * @type {string}
     * @memberof GetAuditDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditDto
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetAuditDto
     */
    createdBySystem: boolean;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetAuditDto
     */
    createdByUser?: GetUserDto;
    /**
     * 
     * @type {GetAuditChangeDto}
     * @memberof GetAuditDto
     */
    change: GetAuditChangeDto;
}

export function GetAuditDtoFromJSON(json: any): GetAuditDto {
    return GetAuditDtoFromJSONTyped(json, false);
}

export function GetAuditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': json['eventId'],
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'createdBySystem': json['createdBySystem'],
        'createdByUser': !exists(json, 'createdByUser') ? undefined : GetUserDtoFromJSON(json['createdByUser']),
        'change': GetAuditChangeDtoFromJSON(json['change']),
    };
}

export function GetAuditDtoToJSON(value?: GetAuditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'createdBySystem': value.createdBySystem,
        'createdByUser': GetUserDtoToJSON(value.createdByUser),
        'change': GetAuditChangeDtoToJSON(value.change),
    };
}


