/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DAType,
    DATypeFromJSON,
    DATypeFromJSONTyped,
    DATypeToJSON,
    GetAirportDto,
    GetAirportDtoFromJSON,
    GetAirportDtoFromJSONTyped,
    GetAirportDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFlightBasicInfoDto
 */
export interface GetFlightBasicInfoDto {
    /**
     * 
     * @type {GetAirportDto}
     * @memberof GetFlightBasicInfoDto
     */
    departure: GetAirportDto;
    /**
     * Flight departure time
     * @type {string}
     * @memberof GetFlightBasicInfoDto
     */
    departureTime: string;
    /**
     * Flight departure time ADM
     * @type {Date}
     * @memberof GetFlightBasicInfoDto
     */
    departureTimeADM?: Date | null;
    /**
     * 
     * @type {GetAirportDto}
     * @memberof GetFlightBasicInfoDto
     */
    arrival: GetAirportDto;
    /**
     * Flight arrival time
     * @type {string}
     * @memberof GetFlightBasicInfoDto
     */
    arrivalTime: string;
    /**
     * Flight arrival time ADM
     * @type {Date}
     * @memberof GetFlightBasicInfoDto
     */
    arrivalTimeADM?: Date | null;
    /**
     * Flight ID in basic info
     * @type {string}
     * @memberof GetFlightBasicInfoDto
     */
    flightId?: string | null;
    /**
     * Flight special status
     * @type {string}
     * @memberof GetFlightBasicInfoDto
     */
    specialStatus?: string | null;
    /**
     * Is flight same as previous Leg?
     * @type {boolean}
     * @memberof GetFlightBasicInfoDto
     */
    sameAsPreviousLeg: boolean;
    /**
     * Flight FPLN
     * @type {string}
     * @memberof GetFlightBasicInfoDto
     */
    fpln?: string | null;
    /**
     * Flight comments
     * @type {string}
     * @memberof GetFlightBasicInfoDto
     */
    comments?: string | null;
    /**
     * Is diplomatic approval paired in our database?
     * @type {boolean}
     * @memberof GetFlightBasicInfoDto
     */
    diplomaticApprovalPaired: boolean;
    /**
     * 
     * @type {DAType}
     * @memberof GetFlightBasicInfoDto
     */
    dAType: DAType;
}

export function GetFlightBasicInfoDtoFromJSON(json: any): GetFlightBasicInfoDto {
    return GetFlightBasicInfoDtoFromJSONTyped(json, false);
}

export function GetFlightBasicInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFlightBasicInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'departure': GetAirportDtoFromJSON(json['departure']),
        'departureTime': json['departureTime'],
        'departureTimeADM': !exists(json, 'departureTimeADM') ? undefined : (json['departureTimeADM'] === null ? null : new Date(json['departureTimeADM'])),
        'arrival': GetAirportDtoFromJSON(json['arrival']),
        'arrivalTime': json['arrivalTime'],
        'arrivalTimeADM': !exists(json, 'arrivalTimeADM') ? undefined : (json['arrivalTimeADM'] === null ? null : new Date(json['arrivalTimeADM'])),
        'flightId': !exists(json, 'flightId') ? undefined : json['flightId'],
        'specialStatus': !exists(json, 'specialStatus') ? undefined : json['specialStatus'],
        'sameAsPreviousLeg': json['sameAsPreviousLeg'],
        'fpln': !exists(json, 'fpln') ? undefined : json['fpln'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'diplomaticApprovalPaired': json['diplomaticApprovalPaired'],
        'dAType': DATypeFromJSON(json['DAType']),
    };
}

export function GetFlightBasicInfoDtoToJSON(value?: GetFlightBasicInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departure': GetAirportDtoToJSON(value.departure),
        'departureTime': value.departureTime,
        'departureTimeADM': value.departureTimeADM === undefined ? undefined : (value.departureTimeADM === null ? null : value.departureTimeADM.toISOString()),
        'arrival': GetAirportDtoToJSON(value.arrival),
        'arrivalTime': value.arrivalTime,
        'arrivalTimeADM': value.arrivalTimeADM === undefined ? undefined : (value.arrivalTimeADM === null ? null : value.arrivalTimeADM.toISOString()),
        'flightId': value.flightId,
        'specialStatus': value.specialStatus,
        'sameAsPreviousLeg': value.sameAsPreviousLeg,
        'fpln': value.fpln,
        'comments': value.comments,
        'diplomaticApprovalPaired': value.diplomaticApprovalPaired,
        'DAType': DATypeToJSON(value.dAType),
    };
}


