import * as Sentry from '@sentry/react';
import { Dispatch } from 'react';
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import {
  AuditApi,
  Configuration,
  FilesApi,
  FlightsApi,
  FlightsControllerGetFilteredFlightsRequest,
  GetActionDto,
  GetAuditDto,
  GetFlightOverviewDto,
  GetRequestDto,
  GetRequestFilesUploadResultsDto,
  GetRequestOverviewDto,
  GetRequestStatusDto,
  PDFGeneratingStatus,
  PostActionDto,
  PostRequestDto,
  PutRequestDto,
  RequestsApi,
  UsersApi,
} from '../../api';
import { renderStatusBox } from '../../components/shared/PDF';
import { emptyRequestStatus } from '../../helpers/constants/defaultData';
import { LoadingState } from '../../helpers/enums/general';
import { RoutePaths } from '../../helpers/enums/RoutePaths';
import {
  getBasicSettings,
  getPDFDocument,
  getPDFGenerationStatus,
} from '../../helpers/functions/apiHelpers';
import { FilesToUpload } from '../../helpers/interfaces/files';
import { ExportingPDF } from '../../helpers/interfaces/pdf';
import { history } from '../../router';
import { CombinedState } from '../combinedReducers';
import { createSnackbar } from '../general/actions';

export enum ActionTypes {
  SET_ACTIVE_REQUEST = '[requests] SET_ACTIVE_REQUEST',
  SET_ACTIVE_FLIGHT = '[requests] SET_ACTIVE_FLIGHT',
  SET_ACTIVE_REQUEST_STATUS = '[requests] SET_ACTIVE_REQUEST_STATUS',
  SET_REQUEST_LOADING_STATE = '[requests] SET_REQUEST_LOADING_STATE',
  SET_REQUESTS = '[requests] SET_REQUESTS',
  SET_ACTIONS_LOADING_STATE = '[requests] SET_ACTIONS_LOADING_STATE',
  SET_ACTIONS = '[requests] SET_ACTIONS',
  UPDATE_ACTION = '[requests] UPDATE_ACTION',
  SET_FLIGHTS_LOADING_STATE = '[requests] SET_FLIGHTS_LOADING_STATE',
  SET_FLIGHTS = '[requests] SET_FLIGHTS',
  SET_SUBMITTED_BEFORE_ROUTE = '[requests] SET_SUBMITTED_BEFORE_ROUTE',
  SET_EXPORTING_PDF_ID = '[requests] SET_EXPORTING_PDF_ID',
}

export const setActiveRequest = action(
  ActionTypes.SET_ACTIVE_REQUEST,
  payload<{ request: GetRequestDto | undefined }>(),
);
export const setActiveFlight = action(
  ActionTypes.SET_ACTIVE_FLIGHT,
  payload<{ flightId: number | undefined }>(),
);
export const setActiveRequestStatus = action(
  ActionTypes.SET_ACTIVE_REQUEST_STATUS,
  payload<{ status: GetRequestStatusDto | undefined }>(),
);
export const setRequestState = action(
  ActionTypes.SET_REQUEST_LOADING_STATE,
  payload<{ state: LoadingState }>(),
);
export const setRequests = action(
  ActionTypes.SET_REQUESTS,
  payload<{ requests: GetRequestOverviewDto[] }>(),
);
export const setActionsState = action(
  ActionTypes.SET_ACTIONS_LOADING_STATE,
  payload<{ state: LoadingState }>(),
);
export const setActions = action(
  ActionTypes.SET_ACTIONS,
  payload<{ actions: GetActionDto[] }>(),
);
export const updateAction = action(
  ActionTypes.UPDATE_ACTION,
  payload<{ action: GetActionDto }>(),
);
export const setFlightsState = action(
  ActionTypes.SET_FLIGHTS_LOADING_STATE,
  payload<{ state: LoadingState }>(),
);
export const setFlights = action(
  ActionTypes.SET_FLIGHTS,
  payload<{ flights: GetFlightOverviewDto[] }>(),
);
export const setSubmittedFormBeforeRoute = action(
  ActionTypes.SET_SUBMITTED_BEFORE_ROUTE,
  payload<boolean>(),
);
export const setExportingPDF = action(
  ActionTypes.SET_EXPORTING_PDF_ID,
  payload<ExportingPDF | undefined>(),
);

export const fetchRequestsOverview: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setRequestState({ state: LoadingState.Loading }));

    const requestsApi = new RequestsApi(new Configuration(getBasicSettings()));

    try {
      const result = await requestsApi.requestControllerGetRequestOverviews();

      dispatch(setRequests({ requests: result }));
      dispatch(setRequestState({ state: LoadingState.Success }));
    } catch (err) {
      dispatch(setRequestState({ state: LoadingState.Error }));

      const message = `Fetch requests overview error.`;
      console.error(message, err);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { err: JSON.stringify(await err.json()) },
      });
    }
  };

export const initNewRequest: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(
      setActiveRequest({
        request: undefined,
      }),
    );
    dispatch(
      setActiveRequestStatus({
        status: emptyRequestStatus,
      }),
    );
  };

export const initNewFlight: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(
      setActiveFlight({
        flightId: undefined,
      }),
    );
  };

export const fetchRequestDetail: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  (requestId: number, doNotFetchStatus = false) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setRequestState({ state: LoadingState.Loading }));

    const requestsApi = new RequestsApi(new Configuration(getBasicSettings()));

    try {
      if (!doNotFetchStatus) {
        const requestStatusResult =
          await requestsApi.requestControllerGetRequestStatusById({
            id: requestId,
          });
        dispatch(
          setActiveRequestStatus({
            status: requestStatusResult,
          }),
        );
      }

      const request = await requestsApi.requestControllerGetRequestById({
        id: requestId,
      });

      dispatch(
        setActiveRequest({
          request,
        }),
      );

      dispatch(setRequestState({ state: LoadingState.Success }));
    } catch (error) {
      if (error && typeof error.json === 'function') {
        const data = await error.json();
        if (error.status === 404) {
          dispatch(
            createSnackbar({
              message: data.message,
              type: 'error',
            }),
          );
          history.replace(`/${RoutePaths.REQUESTS}`);
        }
      }
      dispatch(setRequestState({ state: LoadingState.Error }));

      const message = `Fetch request detail error.`;
      console.error(message, error);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: {
          requestId,
          doNotFetchStatus,
          err: JSON.stringify(await error.json()),
        },
      });
    }
  };
export const createRequest: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  (postRequestDto: PostRequestDto, filesToUpload: FilesToUpload[]) =>
  async (
    dispatch: Dispatch<Action>,
    getState: () => CombinedState,
  ): Promise<void> => {
    const requestsApi = new RequestsApi(new Configuration(getBasicSettings()));
    dispatch(setRequestState({ state: LoadingState.Loading }));
    // the form is submitting
    dispatch(setSubmittedFormBeforeRoute(false));

    try {
      const request = await requestsApi.requestControllerCreateRequest({
        postRequestDto,
      });

      dispatch(
        setActiveRequest({
          request,
        }),
      );

      const status = getState().requests.activeRequestStatus;
      if (status) {
        const updatedStatus = await updateRequestStatus(request.id, status);
        dispatch(
          setActiveRequestStatus({
            status: updatedStatus,
          }),
        );
      }

      dispatch(
        createSnackbar({
          message: 'Request has been successfully created.',
          type: 'success',
        }),
      );
      // everything good
      dispatch(setSubmittedFormBeforeRoute(true));
      history.replace(`/${RoutePaths.REQUESTS}/${request.id}`);

      await uploadRequestFiles(filesToUpload, request.id, dispatch, getState);

      dispatch(setRequestState({ state: LoadingState.Success }));
    } catch (error) {
      dispatch(setRequestState({ state: LoadingState.Error }));
      dispatch(
        createSnackbar({
          message: 'Request has not been created.',
          type: 'error',
        }),
      );

      const message = `Create request error.`;
      console.error(message, error);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: {
          postRequestDto,
          filesToUpload,
          err: JSON.stringify(await error.json()),
        },
      });
    }
  };

export const updateRequest: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  (
    putRequestDto: PutRequestDto,
    filesToUpload: FilesToUpload[],
    createRedirectUrl?: (
      pathname: string,
      activeRequest: GetRequestDto,
    ) => string,
  ) =>
  async (
    dispatch: Dispatch<Action>,
    getState: () => CombinedState,
  ): Promise<void> => {
    const { id } = getState().requests.activeRequest!;
    const requestsApi = new RequestsApi(new Configuration(getBasicSettings()));
    if (createRedirectUrl) {
      dispatch(setSubmittedFormBeforeRoute(false));
    }
    dispatch(setRequestState({ state: LoadingState.Loading }));

    try {
      const request = await requestsApi.requestControllerUpdateRequest({
        id,
        putRequestDto,
      });
      dispatch(
        setActiveRequest({
          request,
        }),
      );
      if (createRedirectUrl) {
        dispatch(setSubmittedFormBeforeRoute(true));
      }
      const status = getState().requests.activeRequestStatus;
      if (status) {
        const updatedStatus = await updateRequestStatus(id, status);
        dispatch(
          setActiveRequestStatus({
            status: updatedStatus,
          }),
        );
      }

      dispatch(
        createSnackbar({
          message: 'Request has been successfully updated.',
          type: 'success',
        }),
      );

      await uploadRequestFiles(filesToUpload, id, dispatch, getState);
      dispatch(setRequestState({ state: LoadingState.Success }));
    } catch (error) {
      dispatch(setRequestState({ state: LoadingState.Error }));
      dispatch(
        createSnackbar({
          message: 'Request has not been updated.',
          type: 'error',
        }),
      );

      const message = `Update request error.`;
      console.error(message, error);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: {
          id,
          putRequestDto,
          filesToUpload,
          err: JSON.stringify(await error.json()),
        },
      });
    }

    setTimeout(() => {
      const { pathname } = history.location;
      let redirectPath = pathname;

      if (createRedirectUrl != null) {
        dispatch(setSubmittedFormBeforeRoute(true));
        redirectPath = createRedirectUrl(
          redirectPath,
          getState().requests.activeRequest!,
        );
      }

      if (redirectPath !== pathname) {
        history.replace(redirectPath);
      }
    }, 500);
  };

export const updateRequestStatus = async (
  id: number,
  status: GetRequestStatusDto,
) => {
  const requestsApi = new RequestsApi(new Configuration(getBasicSettings()));
  return requestsApi.requestControllerUpdateRequestStatusById({
    id,
    putRequestStatusDto: {
      ...status,
      flights: {
        ...status.flights,
        flights: status.flights.flights.map(item => ({
          id: item.id,
          isBasicInfoDone: item.isBasicInfoDone,
          isCrewDone: item.isCrewDone,
          isPaxDone: item.isPaxDone,
          isCargoDone: item.isCargoDone,
          isHandlingDone: item.isHandlingDone,
        })),
      },
    },
  });
};

export const uploadRequestFiles = async (
  filesToUpload: FilesToUpload[],
  requestId: number,
  dispatch: Dispatch<Action>,
  getState: () => CombinedState,
) => {
  const filesApi = new FilesApi(new Configuration(getBasicSettings()));

  const performUpload = async (uploadData: FilesToUpload): Promise<void> =>
    // eslint-disable-next-line
    new Promise(async (resolve, reject) => {
      const { files, type, flightId } = uploadData;

      try {
        let result: GetRequestFilesUploadResultsDto;
        if (flightId != null) {
          result = await filesApi.filesControllerUploadFlightFiles({
            fileType: type,
            flightId,
            requestId,
            newFiles: files,
          });
        } else {
          result = await filesApi.filesControllerUploadRequestFiles({
            fileType: type,
            requestId,
            newFiles: files,
          });
        }

        result.errors.forEach(error => {
          dispatch(
            createSnackbar({
              message: `${error.error}`,
              type: 'error',
            }),
          );
        });
        resolve();
      } catch (error) {
        reject(error);
        dispatch(
          createSnackbar({
            message: `Files ${files
              .map(file => file.name)
              .join(', ')} was not saved. Uploading error.`,
            type: 'error',
          }),
        );

        const message = `Request file upload error.`;
        console.error(message, error);
        Sentry.captureMessage(message, {
          level: Sentry.Severity.Log,
          extra: {
            uploadData,
            requestId,
            err: JSON.stringify(await error.json()),
          },
        });
      }
    });
  const promises: Promise<void>[] = filesToUpload
    .filter(files => files.files.length)
    .map(files => performUpload(files));

  if (!promises.length) {
    return;
  }

  const results = await Promise.allSettled(promises);
  if (results.every(r => r.status === 'fulfilled')) {
    dispatch(
      createSnackbar({
        message: `Files were successfully uploaded.`,
        type: 'success',
      }),
    );
  }

  const req = getState().requests.activeRequest;
  if (req != null && req.id === requestId) {
    dispatch(fetchRequestDetail(requestId, true) as any);
  }
};

export const fetchActions: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  (id: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setActionsState({ state: LoadingState.Loading }));

    const actionsApi = new UsersApi(new Configuration(getBasicSettings()));

    try {
      const result = await actionsApi.usersControllerGetActions({
        id,
      });

      dispatch(setActions({ actions: result }));
      dispatch(setActionsState({ state: LoadingState.Success }));
    } catch (error) {
      dispatch(setActionsState({ state: LoadingState.Error }));
      if (error && typeof error.json === 'function') {
        const message = `Fetch actions error.`;
        console.error(message, error);
        Sentry.captureMessage(message, {
          level: Sentry.Severity.Log,
          extra: { userId: id, err: JSON.stringify(await error.json()) },
        });
      }
    }
  };
export const createAction: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  (idRequest: number, postActionDto: PostActionDto) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setActionsState({ state: LoadingState.Loading }));

    const actionsApi = new RequestsApi(new Configuration(getBasicSettings()));

    try {
      await actionsApi.requestControllerCreateAction({
        id: idRequest,
        postActionDto,
      });

      dispatch(
        createSnackbar({
          message: 'Alert was sent.',
          type: 'success',
        }),
      );
      dispatch(setActionsState({ state: LoadingState.Success }));
    } catch (error) {
      dispatch(setActionsState({ state: LoadingState.Error }));
      if (error && typeof error.json === 'function') {
        const message = `Create action error.`;
        console.error(message, error);
        Sentry.captureMessage(message, {
          level: Sentry.Severity.Log,
          extra: {
            idRequest,
            postActionDto,
            err: JSON.stringify(await error.json()),
          },
        });
      }
    }
  };
export const changeApprovalRequest: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  (idRequest: number, idUser?: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setActionsState({ state: LoadingState.Loading }));

    const actionsApi = new RequestsApi(new Configuration(getBasicSettings()));

    try {
      await actionsApi.requestControllerApproveRequestById({
        id: idRequest,
      });

      dispatch(
        createSnackbar({
          message: 'Approval of request was changed',
          type: 'success',
        }),
      );

      if (idUser) {
        // @ts-ignore
        dispatch(fetchActions(idUser));
      }

      dispatch(setActionsState({ state: LoadingState.Success }));
    } catch (error) {
      if (error) {
        const data = await error.json();
        if (error.status === 403) {
          dispatch(
            createSnackbar({
              message: data.message,
              type: 'error',
            }),
          );
        }
      }

      dispatch(setActionsState({ state: LoadingState.Error }));

      const message = `Change approval error.`;
      console.error(message, error);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { idRequest, idUser, err: JSON.stringify(await error.json()) },
      });
    }
  };

export const readAction: ActionCreator<
  ThunkAction<Promise<void>, CombinedState, any, any>
> =
  (idRequest: number, idAction: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setActionsState({ state: LoadingState.Loading }));

      const actionsApi = new RequestsApi(new Configuration(getBasicSettings()));
      const result = await actionsApi.requestControllerSetReadAt({
        id: idRequest,
        idAction,
      });

      dispatch(
        createSnackbar({
          message: 'Action was read',
          type: 'success',
        }),
      );
      dispatch(updateAction({ action: result }));
      dispatch(setActionsState({ state: LoadingState.Success }));
    } catch (error) {
      dispatch(setActionsState({ state: LoadingState.Error }));
      if (error && typeof error.json === 'function') {
        const message = `Set readAt for action error.`;
        console.error(message, error);
        Sentry.captureMessage(message, {
          level: Sentry.Severity.Log,
          extra: {
            idRequest,
            idAction,
            err: JSON.stringify(await error.json()),
          },
        });
      }
    }
  };

export const fetchFlights: ActionCreator<
  ThunkAction<
    Promise<void>,
    CombinedState,
    FlightsControllerGetFilteredFlightsRequest,
    any
  >
> =
  (dateFilters: FlightsControllerGetFilteredFlightsRequest) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setFlightsState({ state: LoadingState.Loading }));

    const flightsApi = new FlightsApi(new Configuration(getBasicSettings()));

    try {
      // todo add iso string like it should be
      const flights = await flightsApi.flightsControllerGetFilteredFlights(
        dateFilters,
      );

      dispatch(setFlights({ flights }));
      dispatch(setFlightsState({ state: LoadingState.Success }));
    } catch (error) {
      dispatch(setFlightsState({ state: LoadingState.Error }));
      if (error && typeof error.json === 'function') {
        const message = `Fetch flights error.`;
        console.error(message, error);
        Sentry.captureMessage(message, {
          level: Sentry.Severity.Log,
          extra: { dateFilters, err: JSON.stringify(await error.json()) },
        });
      }
    }
  };

export const fetchHistory = async (id: number, isFlight: boolean) => {
  const auditApi = new AuditApi(new Configuration(getBasicSettings()));
  let result: GetAuditDto[];

  try {
    if (isFlight) {
      result = await auditApi.auditControllerGetAuditForFlight({
        flightId: id,
      });
    } else {
      result = await auditApi.auditControllerGetAuditForRequest({
        requestId: id,
      });
    }

    return result;
  } catch (error) {
    if (error && typeof error.json === 'function') {
      const data = await error.json();

      const message = `Fetch history error.`;
      console.error(message, error);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { id, isFlight, err: JSON.stringify(data) },
      });

      return data.message;
    }
    return '';
  }
};

export const setPDFExportingInterval: ActionCreator<
  ThunkAction<
    number | undefined,
    CombinedState,
    FlightsControllerGetFilteredFlightsRequest,
    any
  >
> =
  () =>
  (
    dispatch: Dispatch<Action>,
    getState: () => CombinedState,
  ): number | undefined => {
    const {
      requests: { exportingPdf },
    } = getState();

    if (
      exportingPdf == null ||
      exportingPdf.pdfMetadata == null ||
      [PDFGeneratingStatus.error, PDFGeneratingStatus.success].includes(
        exportingPdf.status,
      )
    ) {
      return undefined;
    }

    const interval = window.setInterval(async () => {
      if (exportingPdf.pdfMetadata!.downloadInProgress) {
        clearInterval(interval);
        return;
      }
      const status = await getPDFGenerationStatus(
        exportingPdf.pdfMetadata!.pdfId,
        exportingPdf.pdfMetadata!.requestText,
      );

      if (status.status === PDFGeneratingStatus.success) {
        // convert is done - we can download file

        dispatch(
          setExportingPDF({
            pdfMetadata: {
              ...exportingPdf.pdfMetadata!,
              downloadInProgress: true,
            },
            ...status,
          }),
        );

        const document = await getPDFDocument(
          exportingPdf.pdfMetadata!.pdfId,
          exportingPdf.pdfMetadata!.fileNameText,
        );
        if (document != null) {
          // file was downloaded
          dispatch(
            setExportingPDF({
              pdfMetadata: {
                ...exportingPdf.pdfMetadata!,
                download: { ...document },
              },
              ...status,
            }),
          );

          dispatch(
            createSnackbar({
              message: renderStatusBox(status.statusText, document),
              type: 'success',
              autoHideDuration: null,
            }),
          );
        } else {
          // file wasn't downloaded for unknown reasons
          dispatch(
            setExportingPDF({
              ...exportingPdf,
              status: PDFGeneratingStatus.error,
            }),
          );

          dispatch(
            createSnackbar({
              message: status.statusText,
              type: 'error',
              autoHideDuration: null,
            }),
          );
        }
      } else {
        // we are still waiting
        dispatch(setExportingPDF({ ...exportingPdf, ...status }));
        dispatch(
          createSnackbar({
            message: status.statusText,
            type: 'info',
            autoHideDuration: null,
          }),
        );
      }

      if (
        [PDFGeneratingStatus.error, PDFGeneratingStatus.success].includes(
          status.status,
        )
      ) {
        clearInterval(interval);
      }
    }, 3000);

    return interval;
  };
