/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
    ResponsibleOrganization,
    ResponsibleOrganizationFromJSON,
    ResponsibleOrganizationFromJSONTyped,
    ResponsibleOrganizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutRequestBasicInfoDto
 */
export interface PutRequestBasicInfoDto {
    /**
     * Request name
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    name: string;
    /**
     * Request type
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    type?: string | null;
    /**
     * Request date from
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    from?: string | null;
    /**
     * Request date to
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    to?: string | null;
    /**
     * Request destination
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    destination?: string | null;
    /**
     * User who requested request
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    requestedBy?: string | null;
    /**
     * Request institution
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    institution?: string | null;
    /**
     * Request VIP passanger
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    vip?: string | null;
    /**
     * Has request any hazard materials?
     * @type {boolean}
     * @memberof PutRequestBasicInfoDto
     */
    isHazmat?: boolean | null;
    /**
     * Request contact
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    contact?: string | null;
    /**
     * Users ID who is assigned as planner
     * @type {number}
     * @memberof PutRequestBasicInfoDto
     */
    assignerPlanner?: number | null;
    /**
     * Request assigned plane ID (name and number)
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    assignedPlane?: string | null;
    /**
     * Is request temporary?
     * @type {boolean}
     * @memberof PutRequestBasicInfoDto
     */
    isTemporary?: boolean | null;
    /**
     * Request backup aircraft (name and number)
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    backupAircraft?: string | null;
    /**
     * Request comments
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    comments?: string | null;
    /**
     * Purpose of flight
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    purposeOfFlight?: string | null;
    /**
     * 
     * @type {ResponsibleOrganization}
     * @memberof PutRequestBasicInfoDto
     */
    responsibleOrganization?: ResponsibleOrganization;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutRequestBasicInfoDto
     */
    files: PutRequestFilesDto;
    /**
     * Chosen request template
     * @type {string}
     * @memberof PutRequestBasicInfoDto
     */
    template?: string | null;
}

export function PutRequestBasicInfoDtoFromJSON(json: any): PutRequestBasicInfoDto {
    return PutRequestBasicInfoDtoFromJSONTyped(json, false);
}

export function PutRequestBasicInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutRequestBasicInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'requestedBy': !exists(json, 'requestedBy') ? undefined : json['requestedBy'],
        'institution': !exists(json, 'institution') ? undefined : json['institution'],
        'vip': !exists(json, 'vip') ? undefined : json['vip'],
        'isHazmat': !exists(json, 'isHazmat') ? undefined : json['isHazmat'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'assignerPlanner': !exists(json, 'assignerPlanner') ? undefined : json['assignerPlanner'],
        'assignedPlane': !exists(json, 'assignedPlane') ? undefined : json['assignedPlane'],
        'isTemporary': !exists(json, 'isTemporary') ? undefined : json['isTemporary'],
        'backupAircraft': !exists(json, 'backupAircraft') ? undefined : json['backupAircraft'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'purposeOfFlight': !exists(json, 'purposeOfFlight') ? undefined : json['purposeOfFlight'],
        'responsibleOrganization': !exists(json, 'responsibleOrganization') ? undefined : ResponsibleOrganizationFromJSON(json['responsibleOrganization']),
        'files': PutRequestFilesDtoFromJSON(json['files']),
        'template': !exists(json, 'template') ? undefined : json['template'],
    };
}

export function PutRequestBasicInfoDtoToJSON(value?: PutRequestBasicInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'from': value.from,
        'to': value.to,
        'destination': value.destination,
        'requestedBy': value.requestedBy,
        'institution': value.institution,
        'vip': value.vip,
        'isHazmat': value.isHazmat,
        'contact': value.contact,
        'assignerPlanner': value.assignerPlanner,
        'assignedPlane': value.assignedPlane,
        'isTemporary': value.isTemporary,
        'backupAircraft': value.backupAircraft,
        'comments': value.comments,
        'purposeOfFlight': value.purposeOfFlight,
        'responsibleOrganization': ResponsibleOrganizationToJSON(value.responsibleOrganization),
        'files': PutRequestFilesDtoToJSON(value.files),
        'template': value.template,
    };
}


