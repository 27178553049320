import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { reducer } from './combinedReducers';

const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger as any);
}

const store = createStore(reducer, applyMiddleware(...middleware));

export default store;
