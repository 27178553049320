/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFlightPaxDto
 */
export interface GetFlightPaxDto {
    /**
     * 
     * @type {number}
     * @memberof GetFlightPaxDto
     */
    numberOfPassengers?: number | null;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetFlightPaxDto
     */
    passengerLists: GetRequestFilesDto;
    /**
     * 
     * @type {string}
     * @memberof GetFlightPaxDto
     */
    comments?: string | null;
}

export function GetFlightPaxDtoFromJSON(json: any): GetFlightPaxDto {
    return GetFlightPaxDtoFromJSONTyped(json, false);
}

export function GetFlightPaxDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFlightPaxDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfPassengers': !exists(json, 'numberOfPassengers') ? undefined : json['numberOfPassengers'],
        'passengerLists': GetRequestFilesDtoFromJSON(json['passengerLists']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function GetFlightPaxDtoToJSON(value?: GetFlightPaxDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfPassengers': value.numberOfPassengers,
        'passengerLists': GetRequestFilesDtoToJSON(value.passengerLists),
        'comments': value.comments,
    };
}


