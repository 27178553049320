import { RouteProps } from 'react-router-dom';
import { lazy } from 'react';
import * as routerHistory from 'history';
import { RequestsPaths, RoutePaths } from '../helpers/enums/RoutePaths';

export interface RouteInterface {
  path: string;
  props: RouteProps;
}

export const unauthRoutes: RouteInterface[] = [
  {
    path: `/${RoutePaths.LOGIN}`,
    props: {
      component: lazy(() => import('../pages/login/Login')),
      exact: true,
    },
  },
];

export const mainRoutes: RouteInterface[] = [
  {
    path: `/${RoutePaths.REQUESTS}`,
    props: {
      component: lazy(() => import('../pages/requests/Requests')),
      exact: true,
    },
  },
  {
    path: `/${RoutePaths.ACTIONS}`,
    props: {
      component: lazy(() => import('../pages/actions/Actions')),
      exact: true,
    },
  },
  {
    path: `/${RoutePaths.FLIGHTS}`,
    props: {
      component: lazy(() => import('../pages/flights/Flights')),
      exact: true,
    },
  },
  {
    path: `/${RoutePaths.TIMESHEET}`,
    props: {
      component: lazy(() => import('../pages/timesheet/Timesheet')),
      exact: true,
    },
  },
];

export const requestsRoutes: RouteInterface[] = [
  {
    path: `/${RoutePaths.REQUESTS}/${RequestsPaths.NEW_REQUEST}`,
    props: {
      component: lazy(() => import('../components/requestsComps/ViewRequest')),
      exact: false,
    },
  },
  {
    path: `/${RoutePaths.REQUESTS}/${RequestsPaths.REQUEST_ID}`,
    props: {
      component: lazy(() => import('../components/requestsComps/ViewRequest')),
      exact: false,
    },
  },
];

export const patchWithRequestId = `/${RoutePaths.REQUESTS}/${RequestsPaths.REQUEST_ID}`;
export const patchWithFlightId = `${patchWithRequestId}/${RequestsPaths.FLIGHTS}/${RequestsPaths.FLIGHT_ID}`;

export const requestsSubRoutes: RouteInterface[] = [
  {
    path: `${patchWithRequestId}/${RequestsPaths.BASIC_INFO}`,
    props: {
      component: lazy(
        () =>
          import(
            '../components/requestsComps/requestDetail/basicInfo/BasicInfoForm'
          ),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithRequestId}/${RequestsPaths.NOTA}`,
    props: {
      component: lazy(
        () => import('../components/requestsComps/requestDetail/nota/NotaForm'),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithRequestId}/${RequestsPaths.DIPLOMATIC_APPROVAL}`,
    props: {
      component: lazy(
        () =>
          import(
            '../components/requestsComps/requestDetail/diplomaticApproval/DiplomaticApproval'
          ),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithRequestId}/${RequestsPaths.NEW_FLIGHT}`,
    props: {
      component: lazy(
        () =>
          import(
            '../components/requestsComps/requestDetail/flights/basicInfo/FlightInfo'
          ),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithFlightId}/${RequestsPaths.PAX}`,
    props: {
      component: lazy(
        () =>
          import('../components/requestsComps/requestDetail/flights/pax/Pax'),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithFlightId}/${RequestsPaths.CREW}`,
    props: {
      component: lazy(
        () =>
          import('../components/requestsComps/requestDetail/flights/crew/Crew'),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithFlightId}/${RequestsPaths.CARGO}`,
    props: {
      component: lazy(
        () =>
          import(
            '../components/requestsComps/requestDetail/flights/cargo/Cargo'
          ),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithFlightId}/${RequestsPaths.HANDLING}`,
    props: {
      component: lazy(
        () =>
          import(
            '../components/requestsComps/requestDetail/flights/handling/Handling'
          ),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithRequestId}/${RequestsPaths.SUMMARY}`,
    props: {
      component: lazy(
        () =>
          import('../components/requestsComps/requestDetail/summary/Summary'),
      ),
      exact: false,
    },
  },
  {
    path: `${patchWithFlightId}`,
    props: {
      component: lazy(
        () =>
          import(
            '../components/requestsComps/requestDetail/flights/basicInfo/FlightInfo'
          ),
      ),
      exact: false,
    },
  },
];

export const authRoutes: RouteInterface[] = [...mainRoutes, ...requestsRoutes];

export const history = routerHistory.createBrowserHistory();
