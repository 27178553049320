/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestSection,
    RequestSectionFromJSON,
    RequestSectionFromJSONTyped,
    RequestSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAuditEntityUpdateMetadataDto
 */
export interface GetAuditEntityUpdateMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof GetAuditEntityUpdateMetadataDto
     */
    name: string;
    /**
     * 
     * @type {Array<RequestSection>}
     * @memberof GetAuditEntityUpdateMetadataDto
     */
    sectionPath: Array<RequestSection>;
}

export function GetAuditEntityUpdateMetadataDtoFromJSON(json: any): GetAuditEntityUpdateMetadataDto {
    return GetAuditEntityUpdateMetadataDtoFromJSONTyped(json, false);
}

export function GetAuditEntityUpdateMetadataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditEntityUpdateMetadataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'sectionPath': ((json['sectionPath'] as Array<any>).map(RequestSectionFromJSON)),
    };
}

export function GetAuditEntityUpdateMetadataDtoToJSON(value?: GetAuditEntityUpdateMetadataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sectionPath': ((value.sectionPath as Array<any>).map(RequestSectionToJSON)),
    };
}


