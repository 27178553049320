/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetRequestFileBufferDto,
    GetRequestFileBufferDtoFromJSON,
    GetRequestFileBufferDtoToJSON,
    GetRequestFilesUploadResultsDto,
    GetRequestFilesUploadResultsDtoFromJSON,
    GetRequestFilesUploadResultsDtoToJSON,
    RequestFileType,
    RequestFileTypeFromJSON,
    RequestFileTypeToJSON,
} from '../models';

export interface FilesControllerGetBufferedFileFromS3Request {
    id: number;
}

export interface FilesControllerUploadApprovalFilesRequest {
    approvalId: number;
    fileType: RequestFileType;
    requestId: number;
    newFiles?: Array<Blob>;
}

export interface FilesControllerUploadFlightFilesRequest {
    flightId: number;
    fileType: RequestFileType;
    requestId: number;
    newFiles?: Array<Blob>;
}

export interface FilesControllerUploadRequestFilesRequest {
    fileType: RequestFileType;
    requestId: number;
    newFiles?: Array<Blob>;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * Get buffered file and file type
     */
    async filesControllerGetBufferedFileFromS3Raw(requestParameters: FilesControllerGetBufferedFileFromS3Request): Promise<runtime.ApiResponse<GetRequestFileBufferDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesControllerGetBufferedFileFromS3.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestFileBufferDtoFromJSON(jsonValue));
    }

    /**
     * Get buffered file and file type
     */
    async filesControllerGetBufferedFileFromS3(requestParameters: FilesControllerGetBufferedFileFromS3Request): Promise<GetRequestFileBufferDto> {
        const response = await this.filesControllerGetBufferedFileFromS3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Upload new files for selected request and diplomatic approval
     */
    async filesControllerUploadApprovalFilesRaw(requestParameters: FilesControllerUploadApprovalFilesRequest): Promise<runtime.ApiResponse<GetRequestFilesUploadResultsDto>> {
        if (requestParameters.approvalId === null || requestParameters.approvalId === undefined) {
            throw new runtime.RequiredError('approvalId','Required parameter requestParameters.approvalId was null or undefined when calling filesControllerUploadApprovalFiles.');
        }

        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling filesControllerUploadApprovalFiles.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling filesControllerUploadApprovalFiles.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        // ALWAYS KEEP THESE 5 LINES A DO NOT OVERRIDE BY SWAGGER SHIT
        if (requestParameters.newFiles) {
            for(const file of requestParameters.newFiles){
                formParams.append('newFiles', file);
            }
        }
        //

        const response = await this.request({
            path: `/files/requests/{requestId}/approvals/{approvalId}/type/{fileType}`.replace(`{${"approvalId"}}`, encodeURIComponent(String(requestParameters.approvalId))).replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType))).replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestFilesUploadResultsDtoFromJSON(jsonValue));
    }

    /**
     * Upload new files for selected request and diplomatic approval
     */
    async filesControllerUploadApprovalFiles(requestParameters: FilesControllerUploadApprovalFilesRequest): Promise<GetRequestFilesUploadResultsDto> {
        const response = await this.filesControllerUploadApprovalFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload new files for selected request and flight
     */
    async filesControllerUploadFlightFilesRaw(requestParameters: FilesControllerUploadFlightFilesRequest): Promise<runtime.ApiResponse<GetRequestFilesUploadResultsDto>> {
        if (requestParameters.flightId === null || requestParameters.flightId === undefined) {
            throw new runtime.RequiredError('flightId','Required parameter requestParameters.flightId was null or undefined when calling filesControllerUploadFlightFiles.');
        }

        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling filesControllerUploadFlightFiles.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling filesControllerUploadFlightFiles.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        // ALWAYS KEEP THESE 5 LINES A DO NOT OVERRIDE BY SWAGGER SHIT
        if (requestParameters.newFiles) {
            for(const file of requestParameters.newFiles){
                formParams.append('newFiles', file);
            }
        }
        //

        const response = await this.request({
            path: `/files/requests/{requestId}/flights/{flightId}/type/{fileType}`.replace(`{${"flightId"}}`, encodeURIComponent(String(requestParameters.flightId))).replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType))).replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestFilesUploadResultsDtoFromJSON(jsonValue));
    }

    /**
     * Upload new files for selected request and flight
     */
    async filesControllerUploadFlightFiles(requestParameters: FilesControllerUploadFlightFilesRequest): Promise<GetRequestFilesUploadResultsDto> {
        const response = await this.filesControllerUploadFlightFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload new files for selected request
     */
    async filesControllerUploadRequestFilesRaw(requestParameters: FilesControllerUploadRequestFilesRequest): Promise<runtime.ApiResponse<GetRequestFilesUploadResultsDto>> {
        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling filesControllerUploadRequestFiles.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling filesControllerUploadRequestFiles.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        // ALWAYS KEEP THESE 5 LINES A DO NOT OVERRIDE BY SWAGGER SHIT
        if (requestParameters.newFiles) {
            for(const file of requestParameters.newFiles){
                formParams.append('newFiles', file);
            }
        }
        //

        const response = await this.request({
            path: `/files/requests/{requestId}/type/{fileType}`.replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType))).replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestFilesUploadResultsDtoFromJSON(jsonValue));
    }

    /**
     * Upload new files for selected request
     */
    async filesControllerUploadRequestFiles(requestParameters: FilesControllerUploadRequestFilesRequest): Promise<GetRequestFilesUploadResultsDto> {
        const response = await this.filesControllerUploadRequestFilesRaw(requestParameters);
        return await response.value();
    }

}
