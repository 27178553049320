/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TypeOfCrew,
    TypeOfCrewFromJSON,
    TypeOfCrewFromJSONTyped,
    TypeOfCrewToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutCrewSameAsPreviousLeg
 */
export interface PutCrewSameAsPreviousLeg {
    /**
     * Id of previous flight
     * @type {string}
     * @memberof PutCrewSameAsPreviousLeg
     */
    sameAsPreviousLeg: string;
    /**
     * 
     * @type {TypeOfCrew}
     * @memberof PutCrewSameAsPreviousLeg
     */
    typeOfCrew: TypeOfCrew;
}

export function PutCrewSameAsPreviousLegFromJSON(json: any): PutCrewSameAsPreviousLeg {
    return PutCrewSameAsPreviousLegFromJSONTyped(json, false);
}

export function PutCrewSameAsPreviousLegFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCrewSameAsPreviousLeg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sameAsPreviousLeg': json['sameAsPreviousLeg'],
        'typeOfCrew': TypeOfCrewFromJSON(json['typeOfCrew']),
    };
}

export function PutCrewSameAsPreviousLegToJSON(value?: PutCrewSameAsPreviousLeg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sameAsPreviousLeg': value.sameAsPreviousLeg,
        'typeOfCrew': TypeOfCrewToJSON(value.typeOfCrew),
    };
}


