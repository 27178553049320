/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DAType,
    DATypeFromJSON,
    DATypeFromJSONTyped,
    DATypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetDiplomaticApprovalDto
 */
export interface GetDiplomaticApprovalDto {
    /**
     * 
     * @type {number}
     * @memberof GetDiplomaticApprovalDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetDiplomaticApprovalDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GetDiplomaticApprovalDto
     */
    authno?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetDiplomaticApprovalDto
     */
    from: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetDiplomaticApprovalDto
     */
    to: Date;
    /**
     * 
     * @type {DAType}
     * @memberof GetDiplomaticApprovalDto
     */
    dAtype: DAType;
}

export function GetDiplomaticApprovalDtoFromJSON(json: any): GetDiplomaticApprovalDto {
    return GetDiplomaticApprovalDtoFromJSONTyped(json, false);
}

export function GetDiplomaticApprovalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDiplomaticApprovalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'country': json['country'],
        'authno': !exists(json, 'authno') ? undefined : json['authno'],
        'from': (new Date(json['from'])),
        'to': (new Date(json['to'])),
        'dAtype': DATypeFromJSON(json['DAtype']),
    };
}

export function GetDiplomaticApprovalDtoToJSON(value?: GetDiplomaticApprovalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'country': value.country,
        'authno': value.authno,
        'from': (value.from.toISOString()),
        'to': (value.to.toISOString()),
        'DAtype': DATypeToJSON(value.dAtype),
    };
}


