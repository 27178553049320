/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfCrew {
    Cabin_Crew = 'Cabin Crew',
    Flight_Crew = 'Flight Crew',
    Ground_Technicians = 'Ground Technicians',
    Medical_Doctor = 'Medical Doctor',
    Military_Police = 'Military Police'
}

export function TypeOfCrewFromJSON(json: any): TypeOfCrew {
    return TypeOfCrewFromJSONTyped(json, false);
}

export function TypeOfCrewFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeOfCrew {
    return json as TypeOfCrew;
}

export function TypeOfCrewToJSON(value?: TypeOfCrew | null): any {
    return value as any;
}

