import React from 'react';
import ReactDOM from 'react-dom';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

import './index.css';

const initSentry = () => {
  const sentryOptions = {
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.NODE_ENV,
  };

  if (sentryOptions.dsn) {
    try {
      Sentry.init({
        ...sentryOptions,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
      });
      console.log(`Sentry was initialized.`);
    } catch (error) {
      console.log(`Sentry error: ${error.message}.`);
    }
  } else {
    console.log('Sentry is skipped at this environment.');
  }
};

initSentry();

const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = key => () => {
  notistackRef.current?.closeSnackbar(key); // eslint-disable-line
};

ReactDOM.render(
  <Provider store={store}>
    {/* See https://emotion.sh/docs/theming for more info */}
    {/* <ThemeProvider theme={theme}> */}
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
      style={{ maxWidth: 400 }}
    >
      <App />
    </SnackbarProvider>
    {/* </ThemeProvider> */}
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
