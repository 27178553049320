/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRequestFileDto,
    GetRequestFileDtoFromJSON,
    GetRequestFileDtoFromJSONTyped,
    GetRequestFileDtoToJSON,
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestSummaryDto
 */
export interface GetRequestSummaryDto {
    /**
     * Is request ready?
     * @type {boolean}
     * @memberof GetRequestSummaryDto
     */
    isReadyToDispatch: boolean;
    /**
     * Is request active?
     * @type {boolean}
     * @memberof GetRequestSummaryDto
     */
    isActive: boolean;
    /**
     * Request comments
     * @type {string}
     * @memberof GetRequestSummaryDto
     */
    comments?: string | null;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetRequestSummaryDto
     */
    postFlightDoc: GetRequestFilesDto;
    /**
     * 
     * @type {GetRequestFileDto}
     * @memberof GetRequestSummaryDto
     */
    report?: GetRequestFileDto;
}

export function GetRequestSummaryDtoFromJSON(json: any): GetRequestSummaryDto {
    return GetRequestSummaryDtoFromJSONTyped(json, false);
}

export function GetRequestSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isReadyToDispatch': json['isReadyToDispatch'],
        'isActive': json['isActive'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'postFlightDoc': GetRequestFilesDtoFromJSON(json['postFlightDoc']),
        'report': !exists(json, 'report') ? undefined : GetRequestFileDtoFromJSON(json['report']),
    };
}

export function GetRequestSummaryDtoToJSON(value?: GetRequestSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isReadyToDispatch': value.isReadyToDispatch,
        'isActive': value.isActive,
        'comments': value.comments,
        'postFlightDoc': GetRequestFilesDtoToJSON(value.postFlightDoc),
        'report': GetRequestFileDtoToJSON(value.report),
    };
}


