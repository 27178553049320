/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TypeOfCrew,
    TypeOfCrewFromJSON,
    TypeOfCrewFromJSONTyped,
    TypeOfCrewToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCrewSameAsPreviousLeg
 */
export interface GetCrewSameAsPreviousLeg {
    /**
     * Id of flight
     * @type {string}
     * @memberof GetCrewSameAsPreviousLeg
     */
    sameAsPreviousLeg: string;
    /**
     * 
     * @type {TypeOfCrew}
     * @memberof GetCrewSameAsPreviousLeg
     */
    typeOfCrew: TypeOfCrew;
}

export function GetCrewSameAsPreviousLegFromJSON(json: any): GetCrewSameAsPreviousLeg {
    return GetCrewSameAsPreviousLegFromJSONTyped(json, false);
}

export function GetCrewSameAsPreviousLegFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCrewSameAsPreviousLeg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sameAsPreviousLeg': json['sameAsPreviousLeg'],
        'typeOfCrew': TypeOfCrewFromJSON(json['typeOfCrew']),
    };
}

export function GetCrewSameAsPreviousLegToJSON(value?: GetCrewSameAsPreviousLeg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sameAsPreviousLeg': value.sameAsPreviousLeg,
        'typeOfCrew': TypeOfCrewToJSON(value.typeOfCrew),
    };
}


