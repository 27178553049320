/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetCrewMemberDto,
    GetCrewMemberDtoFromJSON,
    GetCrewMemberDtoFromJSONTyped,
    GetCrewMemberDtoToJSON,
    GetDiplomaticApprovalDto,
    GetDiplomaticApprovalDtoFromJSON,
    GetDiplomaticApprovalDtoFromJSONTyped,
    GetDiplomaticApprovalDtoToJSON,
    GetHandlingCompanyDto,
    GetHandlingCompanyDtoFromJSON,
    GetHandlingCompanyDtoFromJSONTyped,
    GetHandlingCompanyDtoToJSON,
    GetInstitutionDto,
    GetInstitutionDtoFromJSON,
    GetInstitutionDtoFromJSONTyped,
    GetInstitutionDtoToJSON,
    GetPlaneDto,
    GetPlaneDtoFromJSON,
    GetPlaneDtoFromJSONTyped,
    GetPlaneDtoToJSON,
    GetRequestTypeDto,
    GetRequestTypeDtoFromJSON,
    GetRequestTypeDtoFromJSONTyped,
    GetRequestTypeDtoToJSON,
    GetSpecialStatusDto,
    GetSpecialStatusDtoFromJSON,
    GetSpecialStatusDtoFromJSONTyped,
    GetSpecialStatusDtoToJSON,
    GetTravelDocumentDto,
    GetTravelDocumentDtoFromJSON,
    GetTravelDocumentDtoFromJSONTyped,
    GetTravelDocumentDtoToJSON,
    GetVipDto,
    GetVipDtoFromJSON,
    GetVipDtoFromJSONTyped,
    GetVipDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestEnumDataDto
 */
export interface GetRequestEnumDataDto {
    /**
     * 
     * @type {Array<GetRequestTypeDto>}
     * @memberof GetRequestEnumDataDto
     */
    requestTypeEnums: Array<GetRequestTypeDto>;
    /**
     * 
     * @type {Array<GetSpecialStatusDto>}
     * @memberof GetRequestEnumDataDto
     */
    specialStatusEnums: Array<GetSpecialStatusDto>;
    /**
     * 
     * @type {Array<GetInstitutionDto>}
     * @memberof GetRequestEnumDataDto
     */
    institutionEnums: Array<GetInstitutionDto>;
    /**
     * 
     * @type {Array<GetVipDto>}
     * @memberof GetRequestEnumDataDto
     */
    vipEnums: Array<GetVipDto>;
    /**
     * 
     * @type {Array<GetPlaneDto>}
     * @memberof GetRequestEnumDataDto
     */
    planeEnums: Array<GetPlaneDto>;
    /**
     * 
     * @type {Array<GetHandlingCompanyDto>}
     * @memberof GetRequestEnumDataDto
     */
    handlingCompaniesEnums: Array<GetHandlingCompanyDto>;
    /**
     * 
     * @type {Array<GetDiplomaticApprovalDto>}
     * @memberof GetRequestEnumDataDto
     */
    diplomaticApprovalsEnum: Array<GetDiplomaticApprovalDto>;
    /**
     * 
     * @type {Array<GetTravelDocumentDto>}
     * @memberof GetRequestEnumDataDto
     */
    travelDocumentsEnum: Array<GetTravelDocumentDto>;
    /**
     * 
     * @type {Array<GetCrewMemberDto>}
     * @memberof GetRequestEnumDataDto
     */
    crewMembersEnum: Array<GetCrewMemberDto>;
    /**
     * 
     * @type {Date}
     * @memberof GetRequestEnumDataDto
     */
    lastUpdated: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetRequestEnumDataDto
     */
    newestFMSFileName: string;
}

export function GetRequestEnumDataDtoFromJSON(json: any): GetRequestEnumDataDto {
    return GetRequestEnumDataDtoFromJSONTyped(json, false);
}

export function GetRequestEnumDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestEnumDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestTypeEnums': ((json['requestTypeEnums'] as Array<any>).map(GetRequestTypeDtoFromJSON)),
        'specialStatusEnums': ((json['specialStatusEnums'] as Array<any>).map(GetSpecialStatusDtoFromJSON)),
        'institutionEnums': ((json['institutionEnums'] as Array<any>).map(GetInstitutionDtoFromJSON)),
        'vipEnums': ((json['vipEnums'] as Array<any>).map(GetVipDtoFromJSON)),
        'planeEnums': ((json['planeEnums'] as Array<any>).map(GetPlaneDtoFromJSON)),
        'handlingCompaniesEnums': ((json['handlingCompaniesEnums'] as Array<any>).map(GetHandlingCompanyDtoFromJSON)),
        'diplomaticApprovalsEnum': ((json['diplomaticApprovalsEnum'] as Array<any>).map(GetDiplomaticApprovalDtoFromJSON)),
        'travelDocumentsEnum': ((json['travelDocumentsEnum'] as Array<any>).map(GetTravelDocumentDtoFromJSON)),
        'crewMembersEnum': ((json['crewMembersEnum'] as Array<any>).map(GetCrewMemberDtoFromJSON)),
        'lastUpdated': (json['lastUpdated'] === null ? null : new Date(json['lastUpdated'])),
        'newestFMSFileName': json['newestFMSFileName'],
    };
}

export function GetRequestEnumDataDtoToJSON(value?: GetRequestEnumDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestTypeEnums': ((value.requestTypeEnums as Array<any>).map(GetRequestTypeDtoToJSON)),
        'specialStatusEnums': ((value.specialStatusEnums as Array<any>).map(GetSpecialStatusDtoToJSON)),
        'institutionEnums': ((value.institutionEnums as Array<any>).map(GetInstitutionDtoToJSON)),
        'vipEnums': ((value.vipEnums as Array<any>).map(GetVipDtoToJSON)),
        'planeEnums': ((value.planeEnums as Array<any>).map(GetPlaneDtoToJSON)),
        'handlingCompaniesEnums': ((value.handlingCompaniesEnums as Array<any>).map(GetHandlingCompanyDtoToJSON)),
        'diplomaticApprovalsEnum': ((value.diplomaticApprovalsEnum as Array<any>).map(GetDiplomaticApprovalDtoToJSON)),
        'travelDocumentsEnum': ((value.travelDocumentsEnum as Array<any>).map(GetTravelDocumentDtoToJSON)),
        'crewMembersEnum': ((value.crewMembersEnum as Array<any>).map(GetCrewMemberDtoToJSON)),
        'lastUpdated': (value.lastUpdated === null ? null : value.lastUpdated.toISOString()),
        'newestFMSFileName': value.newestFMSFileName,
    };
}


