/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetFlightOverviewDto,
    GetFlightOverviewDtoFromJSON,
    GetFlightOverviewDtoToJSON,
} from '../models';

export interface FlightsControllerGetFilteredFlightsRequest {
    dateTo?: string;
    dateFrom?: string;
}

/**
 * 
 */
export class FlightsApi extends runtime.BaseAPI {

    /**
     */
    async flightsControllerGetFilteredFlightsRaw(requestParameters: FlightsControllerGetFilteredFlightsRequest): Promise<runtime.ApiResponse<Array<GetFlightOverviewDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/flights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetFlightOverviewDtoFromJSON));
    }

    /**
     */
    async flightsControllerGetFilteredFlights(requestParameters: FlightsControllerGetFilteredFlightsRequest): Promise<Array<GetFlightOverviewDto>> {
        const response = await this.flightsControllerGetFilteredFlightsRaw(requestParameters);
        return await response.value();
    }

}
