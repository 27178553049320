/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentType,
    DocumentTypeFromJSON,
    DocumentTypeFromJSONTyped,
    DocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCrewPersonDto
 */
export interface GetCrewPersonDto {
    /**
     * Person's first name
     * @type {string}
     * @memberof GetCrewPersonDto
     */
    firstName: string;
    /**
     * Person's last name
     * @type {string}
     * @memberof GetCrewPersonDto
     */
    lastName: string;
    /**
     * Person's VISA number
     * @type {string}
     * @memberof GetCrewPersonDto
     */
    visa?: string | null;
    /**
     * Person's position
     * @type {string}
     * @memberof GetCrewPersonDto
     */
    position?: string | null;
    /**
     * Person's passport
     * @type {string}
     * @memberof GetCrewPersonDto
     */
    passport?: string | null;
    /**
     * Person's travel document expiration date
     * @type {string}
     * @memberof GetCrewPersonDto
     */
    docExp?: string | null;
    /**
     * 
     * @type {DocumentType}
     * @memberof GetCrewPersonDto
     */
    docType?: DocumentType;
}

export function GetCrewPersonDtoFromJSON(json: any): GetCrewPersonDto {
    return GetCrewPersonDtoFromJSONTyped(json, false);
}

export function GetCrewPersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCrewPersonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'visa': !exists(json, 'visa') ? undefined : json['visa'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'passport': !exists(json, 'passport') ? undefined : json['passport'],
        'docExp': !exists(json, 'docExp') ? undefined : json['docExp'],
        'docType': !exists(json, 'docType') ? undefined : DocumentTypeFromJSON(json['docType']),
    };
}

export function GetCrewPersonDtoToJSON(value?: GetCrewPersonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'visa': value.visa,
        'position': value.position,
        'passport': value.passport,
        'docExp': value.docExp,
        'docType': DocumentTypeToJSON(value.docType),
    };
}


