/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutFlightBasicInfoDto,
    PutFlightBasicInfoDtoFromJSON,
    PutFlightBasicInfoDtoFromJSONTyped,
    PutFlightBasicInfoDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PostFlightDto
 */
export interface PostFlightDto {
    /**
     * 
     * @type {PutFlightBasicInfoDto}
     * @memberof PostFlightDto
     */
    basicInfo: PutFlightBasicInfoDto;
}

export function PostFlightDtoFromJSON(json: any): PostFlightDto {
    return PostFlightDtoFromJSONTyped(json, false);
}

export function PostFlightDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostFlightDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basicInfo': PutFlightBasicInfoDtoFromJSON(json['basicInfo']),
    };
}

export function PostFlightDtoToJSON(value?: PostFlightDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basicInfo': PutFlightBasicInfoDtoToJSON(value.basicInfo),
    };
}


