/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuditEvent {
    Inserted = 'Inserted',
    Updated = 'Updated',
    Removed = 'Removed'
}

export function AuditEventFromJSON(json: any): AuditEvent {
    return AuditEventFromJSONTyped(json, false);
}

export function AuditEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEvent {
    return json as AuditEvent;
}

export function AuditEventToJSON(value?: AuditEvent | null): any {
    return value as any;
}

