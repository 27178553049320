/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditEvent,
    AuditEventFromJSON,
    AuditEventFromJSONTyped,
    AuditEventToJSON,
    GetAuditAttributeUpdateDto,
    GetAuditAttributeUpdateDtoFromJSON,
    GetAuditAttributeUpdateDtoFromJSONTyped,
    GetAuditAttributeUpdateDtoToJSON,
    GetAuditEntityUpdateMetadataDto,
    GetAuditEntityUpdateMetadataDtoFromJSON,
    GetAuditEntityUpdateMetadataDtoFromJSONTyped,
    GetAuditEntityUpdateMetadataDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAuditChangeDto
 */
export interface GetAuditChangeDto {
    /**
     * 
     * @type {GetAuditAttributeUpdateDto}
     * @memberof GetAuditChangeDto
     */
    attributeUpdate?: GetAuditAttributeUpdateDto;
    /**
     * 
     * @type {GetAuditEntityUpdateMetadataDto}
     * @memberof GetAuditChangeDto
     */
    entityUpdateMetadata?: GetAuditEntityUpdateMetadataDto;
    /**
     * 
     * @type {AuditEvent}
     * @memberof GetAuditChangeDto
     */
    auditedAction: AuditEvent;
}

export function GetAuditChangeDtoFromJSON(json: any): GetAuditChangeDto {
    return GetAuditChangeDtoFromJSONTyped(json, false);
}

export function GetAuditChangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditChangeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeUpdate': !exists(json, 'attributeUpdate') ? undefined : GetAuditAttributeUpdateDtoFromJSON(json['attributeUpdate']),
        'entityUpdateMetadata': !exists(json, 'entityUpdateMetadata') ? undefined : GetAuditEntityUpdateMetadataDtoFromJSON(json['entityUpdateMetadata']),
        'auditedAction': AuditEventFromJSON(json['auditedAction']),
    };
}

export function GetAuditChangeDtoToJSON(value?: GetAuditChangeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeUpdate': GetAuditAttributeUpdateDtoToJSON(value.attributeUpdate),
        'entityUpdateMetadata': GetAuditEntityUpdateMetadataDtoToJSON(value.entityUpdateMetadata),
        'auditedAction': AuditEventToJSON(value.auditedAction),
    };
}


