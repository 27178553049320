import { RequestSection } from '../../api';

export enum LoadingState {
  Initial = 'INITIAL',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}
export interface SectionPartInterface {
  enumName: RequestSection;
  text: string;
}
export const sectionPartText: SectionPartInterface[] = [
  {
    enumName: RequestSection.BASIC_INFO,
    text: 'Basic information',
  },
  {
    enumName: RequestSection.NOTA,
    text: 'Nota',
  },
  {
    enumName: RequestSection.DIPLOMATIC_APPROVAL,
    text: 'Diplomatic approval',
  },
  {
    enumName: RequestSection.ONE_TIME_PERMIT,
    text: 'One time permit',
  },
  {
    enumName: RequestSection.AIRPORT_SLOTS,
    text: 'Airport Slots',
  },
  {
    enumName: RequestSection.PPRS,
    text: 'Ppprs',
  },
  {
    enumName: RequestSection.CARGO,
    text: 'Cargo',
  },
  {
    enumName: RequestSection.DECLARATION,
    text: 'Declaration',
  },
  {
    enumName: RequestSection.CREW,
    text: 'Crew',
  },
  { enumName: RequestSection.GENERAL_DECLARATION, text: 'General declaration' },
  {
    enumName: RequestSection.CREW_LIST,
    text: 'Crew list',
  },
  {
    enumName: RequestSection.PAX,
    text: 'PAX',
  },
  {
    enumName: RequestSection.PASSENGER_LISTS,
    text: 'Passenger list',
  },
  {
    enumName: RequestSection.HANDLING,
    text: 'Handling',
  },
  {
    enumName: RequestSection.HANDLING_CONFIRMATION,
    text: 'Handling confirmation',
  },
  {
    enumName: RequestSection.HANDLING_REQUESTS,
    text: 'Handling requests',
  },
  {
    enumName: RequestSection.FUEL_RELEASE,
    text: 'Fuel release',
  },
  {
    enumName: RequestSection.HOTAC_OR_TRANSPORT,
    text: 'Hotac/Transport',
  },
  {
    enumName: RequestSection.SUMMARY,
    text: 'Summary',
  },
  {
    enumName: RequestSection.REPORT,
    text: 'Report',
  },
  {
    enumName: RequestSection.POST_FLIGHT_DOC,
    text: 'Post flight doc',
  },
];
