export enum RoutePaths {
  LOGIN = 'login',
  REQUESTS = 'requests',
  ACTIONS = 'actions',
  FLIGHTS = 'flights',
  TIMESHEET = 'timesheet',
}

export enum RequestsPaths {
  NEW_REQUEST = 'new',
  REQUEST_ID = ':requestId',
  BASIC_INFO = 'basic-information',
  FLIGHTS = 'flights',
  NOTA = 'nota',
  DIPLOMATIC_APPROVAL = 'diplomatic-approval',
  NEW_FLIGHT = 'new',
  FLIGHT_ID = ':flightId',
  PAX = 'pax',
  CARGO = 'cargo',
  CREW = 'crew',
  HANDLING = 'handling',
  SUMMARY = 'summary',
}
