/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetApprovalDto,
    GetApprovalDtoFromJSON,
    GetApprovalDtoFromJSONTyped,
    GetApprovalDtoToJSON,
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestDiplomaticApprovalDto
 */
export interface GetRequestDiplomaticApprovalDto {
    /**
     * 
     * @type {Array<GetApprovalDto>}
     * @memberof GetRequestDiplomaticApprovalDto
     */
    issuedApprovals: Array<GetApprovalDto>;
    /**
     * 
     * @type {Array<GetApprovalDto>}
     * @memberof GetRequestDiplomaticApprovalDto
     */
    approvalList: Array<GetApprovalDto>;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetRequestDiplomaticApprovalDto
     */
    oneTimePermit: GetRequestFilesDto;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetRequestDiplomaticApprovalDto
     */
    airportSlots: GetRequestFilesDto;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetRequestDiplomaticApprovalDto
     */
    pprs: GetRequestFilesDto;
    /**
     * 
     * @type {string}
     * @memberof GetRequestDiplomaticApprovalDto
     */
    comments?: string | null;
}

export function GetRequestDiplomaticApprovalDtoFromJSON(json: any): GetRequestDiplomaticApprovalDto {
    return GetRequestDiplomaticApprovalDtoFromJSONTyped(json, false);
}

export function GetRequestDiplomaticApprovalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestDiplomaticApprovalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issuedApprovals': ((json['issuedApprovals'] as Array<any>).map(GetApprovalDtoFromJSON)),
        'approvalList': ((json['approvalList'] as Array<any>).map(GetApprovalDtoFromJSON)),
        'oneTimePermit': GetRequestFilesDtoFromJSON(json['oneTimePermit']),
        'airportSlots': GetRequestFilesDtoFromJSON(json['airportSlots']),
        'pprs': GetRequestFilesDtoFromJSON(json['pprs']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function GetRequestDiplomaticApprovalDtoToJSON(value?: GetRequestDiplomaticApprovalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issuedApprovals': ((value.issuedApprovals as Array<any>).map(GetApprovalDtoToJSON)),
        'approvalList': ((value.approvalList as Array<any>).map(GetApprovalDtoToJSON)),
        'oneTimePermit': GetRequestFilesDtoToJSON(value.oneTimePermit),
        'airportSlots': GetRequestFilesDtoToJSON(value.airportSlots),
        'pprs': GetRequestFilesDtoToJSON(value.pprs),
        'comments': value.comments,
    };
}


