/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Permission,
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    password: string;
    /**
     * 
     * @type {Role}
     * @memberof CreateUserDto
     */
    role: Role;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof CreateUserDto
     */
    permissions: Array<Permission>;
}

export function CreateUserDtoFromJSON(json: any): CreateUserDto {
    return CreateUserDtoFromJSONTyped(json, false);
}

export function CreateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'password': json['password'],
        'role': RoleFromJSON(json['role']),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
    };
}

export function CreateUserDtoToJSON(value?: CreateUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'role': RoleToJSON(value.role),
        'permissions': ((value.permissions as Array<any>).map(PermissionToJSON)),
    };
}


