/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetActionDto,
    GetActionDtoFromJSON,
    GetActionDtoToJSON,
    GetPDFGenerationIdDto,
    GetPDFGenerationIdDtoFromJSON,
    GetPDFGenerationIdDtoToJSON,
    GetRequestDto,
    GetRequestDtoFromJSON,
    GetRequestDtoToJSON,
    GetRequestLeanDto,
    GetRequestLeanDtoFromJSON,
    GetRequestLeanDtoToJSON,
    GetRequestOverviewDto,
    GetRequestOverviewDtoFromJSON,
    GetRequestOverviewDtoToJSON,
    GetRequestStatusDto,
    GetRequestStatusDtoFromJSON,
    GetRequestStatusDtoToJSON,
    PostActionDto,
    PostActionDtoFromJSON,
    PostActionDtoToJSON,
    PostRequestDto,
    PostRequestDtoFromJSON,
    PostRequestDtoToJSON,
    PutActionDto,
    PutActionDtoFromJSON,
    PutActionDtoToJSON,
    PutRequestDto,
    PutRequestDtoFromJSON,
    PutRequestDtoToJSON,
    PutRequestStatusDto,
    PutRequestStatusDtoFromJSON,
    PutRequestStatusDtoToJSON,
} from '../models';

export interface RequestControllerApproveRequestByIdRequest {
    id: number;
}

export interface RequestControllerArchiveRequestToZIPRequest {
    id: number;
    flightIds?: Array<number>;
    isSummary?: boolean;
    isDiplomaticApproval?: boolean;
    isHandling?: boolean;
    isPax?: boolean;
    isCargo?: boolean;
    isCrew?: boolean;
    isBasicInfo?: boolean;
}

export interface RequestControllerConvertRequestDataToPDFRequest {
    id: number;
    flightIds?: Array<number>;
    isSummary?: boolean;
    isDiplomaticApproval?: boolean;
    isHandling?: boolean;
    isPax?: boolean;
    isCargo?: boolean;
    isCrew?: boolean;
    isBasicInfo?: boolean;
}

export interface RequestControllerCreateActionRequest {
    id: number;
    postActionDto: PostActionDto;
}

export interface RequestControllerCreateRequestRequest {
    postRequestDto: PostRequestDto;
}

export interface RequestControllerGetFilteredRequestsRequest {
    dateTo?: string;
    dateFrom?: string;
}

export interface RequestControllerGetRequestActionRequest {
    id: number;
    idAction: number;
}

export interface RequestControllerGetRequestActionsRequest {
    id: number;
}

export interface RequestControllerGetRequestByIdRequest {
    id: number;
}

export interface RequestControllerGetRequestStatusByIdRequest {
    id: number;
}

export interface RequestControllerSetReadAtRequest {
    id: number;
    idAction: number;
}

export interface RequestControllerUpdateActionRequest {
    id: number;
    idAction: number;
    putActionDto: PutActionDto;
}

export interface RequestControllerUpdateRequestRequest {
    id: number;
    putRequestDto: PutRequestDto;
}

export interface RequestControllerUpdateRequestStatusByIdRequest {
    id: number;
    putRequestStatusDto: PutRequestStatusDto;
}

/**
 * 
 */
export class RequestsApi extends runtime.BaseAPI {

    /**
     * Changed approval values on request and corresponding action
     */
    async requestControllerApproveRequestByIdRaw(requestParameters: RequestControllerApproveRequestByIdRequest): Promise<runtime.ApiResponse<GetRequestLeanDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerApproveRequestById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/changeApproval`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestLeanDtoFromJSON(jsonValue));
    }

    /**
     * Changed approval values on request and corresponding action
     */
    async requestControllerApproveRequestById(requestParameters: RequestControllerApproveRequestByIdRequest): Promise<GetRequestLeanDto> {
        const response = await this.requestControllerApproveRequestByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Files are not generated in PDF document. They are zipped to ZIP.
     * ZIP summary report of selected request with all files
     */
    async requestControllerArchiveRequestToZIPRaw(requestParameters: RequestControllerArchiveRequestToZIPRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerArchiveRequestToZIP.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.flightIds) {
            queryParameters['flightIds'] = requestParameters.flightIds;
        }

        if (requestParameters.isSummary !== undefined) {
            queryParameters['isSummary'] = requestParameters.isSummary;
        }

        if (requestParameters.isDiplomaticApproval !== undefined) {
            queryParameters['isDiplomaticApproval'] = requestParameters.isDiplomaticApproval;
        }

        if (requestParameters.isHandling !== undefined) {
            queryParameters['isHandling'] = requestParameters.isHandling;
        }

        if (requestParameters.isPax !== undefined) {
            queryParameters['isPax'] = requestParameters.isPax;
        }

        if (requestParameters.isCargo !== undefined) {
            queryParameters['isCargo'] = requestParameters.isCargo;
        }

        if (requestParameters.isCrew !== undefined) {
            queryParameters['isCrew'] = requestParameters.isCrew;
        }

        if (requestParameters.isBasicInfo !== undefined) {
            queryParameters['isBasicInfo'] = requestParameters.isBasicInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/zip`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Files are not generated in PDF document. They are zipped to ZIP.
     * ZIP summary report of selected request with all files
     */
    async requestControllerArchiveRequestToZIP(requestParameters: RequestControllerArchiveRequestToZIPRequest): Promise<Blob> {
        const response = await this.requestControllerArchiveRequestToZIPRaw(requestParameters);
        return await response.value();
    }

    /**
     * This returns generation ID. To check status of generation or download PDF file, use different endpoints.
     * Convert selected request and its data to PDF (in background)
     */
    async requestControllerConvertRequestDataToPDFRaw(requestParameters: RequestControllerConvertRequestDataToPDFRequest): Promise<runtime.ApiResponse<GetPDFGenerationIdDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerConvertRequestDataToPDF.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.flightIds) {
            queryParameters['flightIds'] = requestParameters.flightIds;
        }

        if (requestParameters.isSummary !== undefined) {
            queryParameters['isSummary'] = requestParameters.isSummary;
        }

        if (requestParameters.isDiplomaticApproval !== undefined) {
            queryParameters['isDiplomaticApproval'] = requestParameters.isDiplomaticApproval;
        }

        if (requestParameters.isHandling !== undefined) {
            queryParameters['isHandling'] = requestParameters.isHandling;
        }

        if (requestParameters.isPax !== undefined) {
            queryParameters['isPax'] = requestParameters.isPax;
        }

        if (requestParameters.isCargo !== undefined) {
            queryParameters['isCargo'] = requestParameters.isCargo;
        }

        if (requestParameters.isCrew !== undefined) {
            queryParameters['isCrew'] = requestParameters.isCrew;
        }

        if (requestParameters.isBasicInfo !== undefined) {
            queryParameters['isBasicInfo'] = requestParameters.isBasicInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/pdf`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPDFGenerationIdDtoFromJSON(jsonValue));
    }

    /**
     * This returns generation ID. To check status of generation or download PDF file, use different endpoints.
     * Convert selected request and its data to PDF (in background)
     */
    async requestControllerConvertRequestDataToPDF(requestParameters: RequestControllerConvertRequestDataToPDFRequest): Promise<GetPDFGenerationIdDto> {
        const response = await this.requestControllerConvertRequestDataToPDFRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create Action for Request
     */
    async requestControllerCreateActionRaw(requestParameters: RequestControllerCreateActionRequest): Promise<runtime.ApiResponse<GetActionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerCreateAction.');
        }

        if (requestParameters.postActionDto === null || requestParameters.postActionDto === undefined) {
            throw new runtime.RequiredError('postActionDto','Required parameter requestParameters.postActionDto was null or undefined when calling requestControllerCreateAction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/actions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostActionDtoToJSON(requestParameters.postActionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetActionDtoFromJSON(jsonValue));
    }

    /**
     * Create Action for Request
     */
    async requestControllerCreateAction(requestParameters: RequestControllerCreateActionRequest): Promise<GetActionDto> {
        const response = await this.requestControllerCreateActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create Flight request
     */
    async requestControllerCreateRequestRaw(requestParameters: RequestControllerCreateRequestRequest): Promise<runtime.ApiResponse<GetRequestDto>> {
        if (requestParameters.postRequestDto === null || requestParameters.postRequestDto === undefined) {
            throw new runtime.RequiredError('postRequestDto','Required parameter requestParameters.postRequestDto was null or undefined when calling requestControllerCreateRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostRequestDtoToJSON(requestParameters.postRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestDtoFromJSON(jsonValue));
    }

    /**
     * Create Flight request
     */
    async requestControllerCreateRequest(requestParameters: RequestControllerCreateRequestRequest): Promise<GetRequestDto> {
        const response = await this.requestControllerCreateRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async requestControllerGetFilteredRequestsRaw(requestParameters: RequestControllerGetFilteredRequestsRequest): Promise<runtime.ApiResponse<Array<GetRequestDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRequestDtoFromJSON));
    }

    /**
     */
    async requestControllerGetFilteredRequests(requestParameters: RequestControllerGetFilteredRequestsRequest): Promise<Array<GetRequestDto>> {
        const response = await this.requestControllerGetFilteredRequestsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get request action
     */
    async requestControllerGetRequestActionRaw(requestParameters: RequestControllerGetRequestActionRequest): Promise<runtime.ApiResponse<Array<GetActionDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerGetRequestAction.');
        }

        if (requestParameters.idAction === null || requestParameters.idAction === undefined) {
            throw new runtime.RequiredError('idAction','Required parameter requestParameters.idAction was null or undefined when calling requestControllerGetRequestAction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/actions/{idAction}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"idAction"}}`, encodeURIComponent(String(requestParameters.idAction))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetActionDtoFromJSON));
    }

    /**
     * Get request action
     */
    async requestControllerGetRequestAction(requestParameters: RequestControllerGetRequestActionRequest): Promise<Array<GetActionDto>> {
        const response = await this.requestControllerGetRequestActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all requests actions
     */
    async requestControllerGetRequestActionsRaw(requestParameters: RequestControllerGetRequestActionsRequest): Promise<runtime.ApiResponse<Array<GetActionDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerGetRequestActions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/actions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetActionDtoFromJSON));
    }

    /**
     * Get all requests actions
     */
    async requestControllerGetRequestActions(requestParameters: RequestControllerGetRequestActionsRequest): Promise<Array<GetActionDto>> {
        const response = await this.requestControllerGetRequestActionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get request data by given request ID
     */
    async requestControllerGetRequestByIdRaw(requestParameters: RequestControllerGetRequestByIdRequest): Promise<runtime.ApiResponse<GetRequestDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerGetRequestById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestDtoFromJSON(jsonValue));
    }

    /**
     * Get request data by given request ID
     */
    async requestControllerGetRequestById(requestParameters: RequestControllerGetRequestByIdRequest): Promise<GetRequestDto> {
        const response = await this.requestControllerGetRequestByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Flight request overviews for homepage
     */
    async requestControllerGetRequestOverviewsRaw(): Promise<runtime.ApiResponse<Array<GetRequestOverviewDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRequestOverviewDtoFromJSON));
    }

    /**
     * Get Flight request overviews for homepage
     */
    async requestControllerGetRequestOverviews(): Promise<Array<GetRequestOverviewDto>> {
        const response = await this.requestControllerGetRequestOverviewsRaw();
        return await response.value();
    }

    /**
     * Get request status by given request ID
     */
    async requestControllerGetRequestStatusByIdRaw(requestParameters: RequestControllerGetRequestStatusByIdRequest): Promise<runtime.ApiResponse<GetRequestStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerGetRequestStatusById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestStatusDtoFromJSON(jsonValue));
    }

    /**
     * Get request status by given request ID
     */
    async requestControllerGetRequestStatusById(requestParameters: RequestControllerGetRequestStatusByIdRequest): Promise<GetRequestStatusDto> {
        const response = await this.requestControllerGetRequestStatusByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set readAt at action
     */
    async requestControllerSetReadAtRaw(requestParameters: RequestControllerSetReadAtRequest): Promise<runtime.ApiResponse<GetActionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerSetReadAt.');
        }

        if (requestParameters.idAction === null || requestParameters.idAction === undefined) {
            throw new runtime.RequiredError('idAction','Required parameter requestParameters.idAction was null or undefined when calling requestControllerSetReadAt.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/actions/{idAction}/read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"idAction"}}`, encodeURIComponent(String(requestParameters.idAction))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetActionDtoFromJSON(jsonValue));
    }

    /**
     * Set readAt at action
     */
    async requestControllerSetReadAt(requestParameters: RequestControllerSetReadAtRequest): Promise<GetActionDto> {
        const response = await this.requestControllerSetReadAtRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update action at request
     */
    async requestControllerUpdateActionRaw(requestParameters: RequestControllerUpdateActionRequest): Promise<runtime.ApiResponse<GetActionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerUpdateAction.');
        }

        if (requestParameters.idAction === null || requestParameters.idAction === undefined) {
            throw new runtime.RequiredError('idAction','Required parameter requestParameters.idAction was null or undefined when calling requestControllerUpdateAction.');
        }

        if (requestParameters.putActionDto === null || requestParameters.putActionDto === undefined) {
            throw new runtime.RequiredError('putActionDto','Required parameter requestParameters.putActionDto was null or undefined when calling requestControllerUpdateAction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/actions/{idAction}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"idAction"}}`, encodeURIComponent(String(requestParameters.idAction))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutActionDtoToJSON(requestParameters.putActionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetActionDtoFromJSON(jsonValue));
    }

    /**
     * Update action at request
     */
    async requestControllerUpdateAction(requestParameters: RequestControllerUpdateActionRequest): Promise<GetActionDto> {
        const response = await this.requestControllerUpdateActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update request information
     */
    async requestControllerUpdateRequestRaw(requestParameters: RequestControllerUpdateRequestRequest): Promise<runtime.ApiResponse<GetRequestDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerUpdateRequest.');
        }

        if (requestParameters.putRequestDto === null || requestParameters.putRequestDto === undefined) {
            throw new runtime.RequiredError('putRequestDto','Required parameter requestParameters.putRequestDto was null or undefined when calling requestControllerUpdateRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutRequestDtoToJSON(requestParameters.putRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestDtoFromJSON(jsonValue));
    }

    /**
     * Update request information
     */
    async requestControllerUpdateRequest(requestParameters: RequestControllerUpdateRequestRequest): Promise<GetRequestDto> {
        const response = await this.requestControllerUpdateRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update request status information
     */
    async requestControllerUpdateRequestStatusByIdRaw(requestParameters: RequestControllerUpdateRequestStatusByIdRequest): Promise<runtime.ApiResponse<GetRequestStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestControllerUpdateRequestStatusById.');
        }

        if (requestParameters.putRequestStatusDto === null || requestParameters.putRequestStatusDto === undefined) {
            throw new runtime.RequiredError('putRequestStatusDto','Required parameter requestParameters.putRequestStatusDto was null or undefined when calling requestControllerUpdateRequestStatusById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/requests/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutRequestStatusDtoToJSON(requestParameters.putRequestStatusDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRequestStatusDtoFromJSON(jsonValue));
    }

    /**
     * Update request status information
     */
    async requestControllerUpdateRequestStatusById(requestParameters: RequestControllerUpdateRequestStatusByIdRequest): Promise<GetRequestStatusDto> {
        const response = await this.requestControllerUpdateRequestStatusByIdRaw(requestParameters);
        return await response.value();
    }

}
