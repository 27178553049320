/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAuditDto,
    GetAuditDtoFromJSON,
    GetAuditDtoToJSON,
} from '../models';

export interface AuditControllerGetAuditForFlightRequest {
    flightId: number;
}

export interface AuditControllerGetAuditForRequestRequest {
    requestId: number;
}

/**
 * 
 */
export class AuditApi extends runtime.BaseAPI {

    /**
     * Get change history for selected flight
     */
    async auditControllerGetAuditForFlightRaw(requestParameters: AuditControllerGetAuditForFlightRequest): Promise<runtime.ApiResponse<Array<GetAuditDto>>> {
        if (requestParameters.flightId === null || requestParameters.flightId === undefined) {
            throw new runtime.RequiredError('flightId','Required parameter requestParameters.flightId was null or undefined when calling auditControllerGetAuditForFlight.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/audit/flight/{flightId}`.replace(`{${"flightId"}}`, encodeURIComponent(String(requestParameters.flightId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAuditDtoFromJSON));
    }

    /**
     * Get change history for selected flight
     */
    async auditControllerGetAuditForFlight(requestParameters: AuditControllerGetAuditForFlightRequest): Promise<Array<GetAuditDto>> {
        const response = await this.auditControllerGetAuditForFlightRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get change history for selected request
     */
    async auditControllerGetAuditForRequestRaw(requestParameters: AuditControllerGetAuditForRequestRequest): Promise<runtime.ApiResponse<Array<GetAuditDto>>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling auditControllerGetAuditForRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/audit/request/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAuditDtoFromJSON));
    }

    /**
     * Get change history for selected request
     */
    async auditControllerGetAuditForRequest(requestParameters: AuditControllerGetAuditForRequestRequest): Promise<Array<GetAuditDto>> {
        const response = await this.auditControllerGetAuditForRequestRaw(requestParameters);
        return await response.value();
    }

}
