/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetTokenWithUserDto,
    GetTokenWithUserDtoFromJSON,
    GetTokenWithUserDtoToJSON,
    PostLoginDto,
    PostLoginDtoFromJSON,
    PostLoginDtoToJSON,
} from '../models';

export interface AuthControllerLoginRequest {
    postLoginDto: PostLoginDto;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Login user
     */
    async authControllerLoginRaw(requestParameters: AuthControllerLoginRequest): Promise<runtime.ApiResponse<GetTokenWithUserDto>> {
        if (requestParameters.postLoginDto === null || requestParameters.postLoginDto === undefined) {
            throw new runtime.RequiredError('postLoginDto','Required parameter requestParameters.postLoginDto was null or undefined when calling authControllerLogin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLoginDtoToJSON(requestParameters.postLoginDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTokenWithUserDtoFromJSON(jsonValue));
    }

    /**
     * Login user
     */
    async authControllerLogin(requestParameters: AuthControllerLoginRequest): Promise<GetTokenWithUserDto> {
        const response = await this.authControllerLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Logout user
     */
    async authControllerLogoutRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout user
     */
    async authControllerLogout(): Promise<void> {
        await this.authControllerLogoutRaw();
    }

}
