import { get, remove, set } from 'js-cookie';
import * as Sentry from '@sentry/react';
import {
  Configuration,
  PDFGeneratingStatus,
  PDFsApi,
  ReportApi,
  ReportControllerGenerateAndDownloadGeneralDeclarationTypeEnum,
  RequestControllerConvertRequestDataToPDFRequest,
  RequestsApi,
} from '../../api';
import { cookieExpiresInDays, cookieName } from '../constants/api';
import {
  createApplicationBlobUrl,
  parseFileNameFromResponseHeader,
} from './fileHelpers';

export const getBasicSettings = () => ({
  accessToken: get(cookieName),
  headers: {
    Authorization: `Bearer ${get(cookieName)}`,
  },
});

export const saveUserToCookie = (token: string): void => {
  set(cookieName, token, {
    expires: cookieExpiresInDays,
  });
};

export const receiveUserFromCookie = (): string | undefined => {
  const data = get(cookieName);
  if (data) {
    return data;
  }

  return undefined;
};

export const removeUserFromCookie = (): void => {
  remove(cookieName);
};

/**
 * Download report of summary
 */
export const downloadFlightReport = async (
  dateFrom: string,
  dateTo: string,
): Promise<{ url: string; name: string } | undefined> => {
  const reportApi = new ReportApi(new Configuration(getBasicSettings()));

  try {
    const result = await reportApi.reportControllerDownloadReportFileRaw({
      dateFrom,
      dateTo,
    });

    const blobData = await result.value();

    return {
      url: createApplicationBlobUrl(blobData),
      name: parseFileNameFromResponseHeader(result.raw.headers),
    };
  } catch (err) {
    const message = `Download flight report error.`;
    console.error(message, err);
    Sentry.captureMessage(message, {
      level: Sentry.Severity.Log,
      extra: { dateFrom, dateTo, err: JSON.stringify(await err.json()) },
    });

    return undefined;
  }
};

/**
 * Start request summary PDF converting
 */
export const startRequestSummaryPDFGeneration = async (
  params: RequestControllerConvertRequestDataToPDFRequest,
): Promise<string | undefined> => {
  const configApi = new RequestsApi(new Configuration(getBasicSettings()));

  try {
    const result = await configApi.requestControllerConvertRequestDataToPDF(
      params,
    );

    return result.id;
  } catch (err) {
    const message = `Start request summary PDF convert error.`;
    console.error(message, err);
    Sentry.captureMessage(message, {
      level: Sentry.Severity.Log,
      extra: { requestId: params.id },
    });
    return undefined;
  }
};

/**
 * Get status of PDF generation byt id
 */
export const getPDFGenerationStatus = async (
  pdfId: string,
  requestText: string,
): Promise<{
  status: PDFGeneratingStatus;
  statusText: string;
}> => {
  const configApi = new PDFsApi(new Configuration(getBasicSettings()));

  try {
    const response = await configApi.pDFsControllerGetGenerationStatus({
      pdfId,
    });

    const { status } = response;

    if (status == null || status === PDFGeneratingStatus.error) {
      return {
        status: PDFGeneratingStatus.error,
        statusText: `Your PDF summary for request ${requestText} could not be created. An error may have occurred while processing external files. Try checking the files or select other parts of the request.`,
      };
    }

    let text: string;

    if (
      status === PDFGeneratingStatus.loading ||
      status === PDFGeneratingStatus.initial
    ) {
      text = `Your PDF summary for request ${requestText} is currently being prepared. It may take some time. You will be notified when done. You can close this.`;
    }

    if (status === PDFGeneratingStatus.success) {
      text = `Your PDF summary for request ${requestText} has been successfully created. You can download it below.`;
    }

    return {
      status,
      statusText: text!,
    };
  } catch (err) {
    const message = `Get PDF generation status error.`;
    console.error(message, err);
    Sentry.captureMessage(message, {
      level: Sentry.Severity.Log,
      extra: { pdfId },
    });

    return {
      status: PDFGeneratingStatus.error,
      statusText: `Your PDF summary for request ${requestText} could not be created. An error may have occurred while processing external files. Try checking the files or select other parts of the request.`,
    };
  }
};

/**
 * Download PDF document by id
 */
export const getPDFDocument = async (
  pdfId: string,
  fileNameText?: string,
): Promise<{ url: string; name: string } | undefined> => {
  const configApi = new PDFsApi(new Configuration(getBasicSettings()));

  try {
    const result = await configApi.pDFsControllerDownloadPDFByIdRaw({ pdfId });
    const blobData = await result.value();
    console.log(pdfId);
    return {
      url: createApplicationBlobUrl(blobData),
      name: fileNameText || parseFileNameFromResponseHeader(result.raw.headers),
    };
  } catch (err) {
    const message = `Download PDF document error.`;
    console.error(message, err);
    Sentry.captureMessage(message, {
      level: Sentry.Severity.Log,
      extra: { pdfId },
    });

    return undefined;
  }
};

/**
 * Download nota of request
 */
export const downloadNotaReport = async (
  id: number,
  OnErrorCallback: (message: string) => void,
): Promise<{ url: string; name: string } | undefined> => {
  const reportApi = new ReportApi(new Configuration(getBasicSettings()));

  try {
    const result =
      await reportApi.reportControllerGenerateAndDownloadNOTAFileRaw({ id });

    const blobData = await result.value();

    return {
      url: createApplicationBlobUrl(blobData),
      name: parseFileNameFromResponseHeader(result.raw.headers),
    };
  } catch (err) {
    const data = await err.json();
    OnErrorCallback(data.message);

    const message = `Download nota report error.`;
    console.error(message, err);
    Sentry.captureMessage(message, {
      level: Sentry.Severity.Log,
      extra: { requestId: id, err: JSON.stringify(data) },
    });

    return undefined;
  }
};

/**
 * Download crew report
 */
export const downloadCrewReport = async (
  id: number,
  type: ReportControllerGenerateAndDownloadGeneralDeclarationTypeEnum,
  OnErrorCallback: (message: string) => void,
): Promise<{ url: string; name: string } | undefined> => {
  const reportApi = new ReportApi(new Configuration(getBasicSettings()));

  try {
    const result =
      await reportApi.reportControllerGenerateAndDownloadGeneralDeclarationRaw({
        id,
        type,
      });

    const blobData = await result.value();

    return {
      url: createApplicationBlobUrl(blobData),
      name: parseFileNameFromResponseHeader(result.raw.headers),
    };
  } catch (err) {
    const data = await err.json();
    OnErrorCallback(data.message);

    const message = `Download crew report error.`;
    console.error(message, err);
    Sentry.captureMessage(message, {
      level: Sentry.Severity.Log,
      extra: { requestId: id, type, err: JSON.stringify(data) },
    });

    return undefined;
  }
};
