/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentType {
    P = 'P',
    ID = 'ID'
}

export function DocumentTypeFromJSON(json: any): DocumentType {
    return DocumentTypeFromJSONTyped(json, false);
}

export function DocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentType {
    return json as DocumentType;
}

export function DocumentTypeToJSON(value?: DocumentType | null): any {
    return value as any;
}

