import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import * as Sentry from '@sentry/react';
import { State } from './reducers';
import {
  saveUserToCookie,
  receiveUserFromCookie,
  removeUserFromCookie,
  getBasicSettings,
} from '../../helpers/functions/apiHelpers';
import { history } from '../../router';
import { RoutePaths } from '../../helpers/enums/RoutePaths';
import {
  AuthApi,
  Configuration,
  GetUserDto,
  PostLoginDto,
  UsersApi,
} from '../../api';
import { LoadingState } from '../../helpers/enums/general';
import { fetchRequestEnumData } from '../general/actions';

export enum ActionTypes {
  SET_LOGGED_USER = '[auth] SET_LOGGED_USER',
  SET_LOGGED_USER_REFRESHED = '[auth] SET_LOGGED_USER_REFRESHED',
  SET_LOAD_STATE = '[auth] SET_LOAD_STATE',
}

export const setLoggedUser = action(
  ActionTypes.SET_LOGGED_USER,
  payload<{ user: GetUserDto | undefined }>(),
);
export const setIsUserRefreshed = action(
  ActionTypes.SET_LOGGED_USER_REFRESHED,
  payload<{ isRefreshed: boolean }>(),
);
export const setLoadState = action(
  ActionTypes.SET_LOAD_STATE,
  payload<{ state: LoadingState }>(),
);
/**
 * User logout
 */
export const logOut: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setLoadState({ state: LoadingState.Loading }));
    const authApi = new AuthApi(new Configuration(getBasicSettings()));

    try {
      await authApi.authControllerLogout();
      dispatch(setLoggedUser({ user: undefined }));
      removeUserFromCookie();
      dispatch(setLoadState({ state: LoadingState.Success }));
      history.push(`/${RoutePaths.LOGIN}`);
    } catch (err) {
      dispatch(setLoadState({ state: LoadingState.Error }));

      const message = `Logout error.`;
      console.error(message, err);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: { err: JSON.stringify(await err.json()) },
      });
    }
  };

/**
 * Refresh user that is saved in cookie, if token is valid perform onRefreshCallback,
 * logout user otherwise.
 *
 * @param onRefreshCallback
 */
export const refreshUserFromCookie: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    const cookieData = receiveUserFromCookie();

    if (cookieData) {
      try {
        const userApi = new UsersApi(new Configuration(getBasicSettings()));
        const userResult = await userApi.usersControllerGetMe();

        dispatch(
          setLoggedUser({
            user: userResult,
          }),
        );

        dispatch(
          setIsUserRefreshed({
            isRefreshed: true,
          }),
        );
      } catch (err) {
        dispatch(
          setIsUserRefreshed({
            isRefreshed: true,
          }),
        );
      }
    } else {
      // Invalid token
      // Remove user info from cookie
      removeUserFromCookie();

      dispatch(
        setIsUserRefreshed({
          isRefreshed: true,
        }),
      );
    }
  };

/**
 * Perform login action
 *
 * @param loginData
 * @param onLoginCallback
 */
export const performUserLogin: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (loginData: PostLoginDto, onLoginCallback: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setLoadState({ state: LoadingState.Loading }));
    const authApi = new AuthApi();

    try {
      const tokenResult = await authApi.authControllerLogin({
        postLoginDto: loginData,
      });

      saveUserToCookie(tokenResult.accessToken);

      dispatch(setLoggedUser({ user: tokenResult.user as GetUserDto }));

      onLoginCallback();
      // @ts-ignore
      dispatch(fetchRequestEnumData());

      dispatch(setLoadState({ state: LoadingState.Success }));
    } catch (err) {
      dispatch(setLoadState({ state: LoadingState.Error }));

      const message = `Login error.`;
      console.error(message, err);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Log,
        extra: {
          user: {
            email: loginData.email,
            err: JSON.stringify(await err.json()),
          },
        },
      });
    }
  };
