import { createSelector } from 'reselect';
import { DOMESTIC } from '../../components/requestsComps/requestDetail/basicInfo/PurposeOfFlight';
import { LoadingState } from '../../helpers/enums/general';
import { CombinedState } from '../combinedReducers';

export const getActiveRequest = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.activeRequest,
);

export const getActiveFlight = createSelector(
  (state: CombinedState) => state.requests,
  requests =>
    requests.activeRequest?.flights.find(
      flight => flight.id === requests.activeFlightId,
    ),
);

export const getActiveRequestStatus = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.activeRequestStatus,
);

export const getRequestState = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.requestState,
);

export const getAllRequests = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.allRequests,
);

export const isRequestLoading = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.requestState === LoadingState.Loading,
);

export const isRequestError = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.requestState === LoadingState.Error,
);

export const getActions = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.actions,
);
export const getUnreadActions = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.actions.filter(action => action.readAt == null).length,
);
export const isActionsLoading = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.actionsState === LoadingState.Loading,
);

export const getFlights = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.flights,
);

export const isFlightsLoading = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.flightsState === LoadingState.Loading,
);

export const getSubmittedForm = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.submittedBeforeRoute,
);

export const getGeneratingPDF = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.exportingPdf,
);

export const isRequestDomestic = createSelector(
  (state: CombinedState) => state.requests,
  requests => requests.activeRequest?.basicInfo.type === DOMESTIC,
);

// for same as previous leg functionality
export const getPreviousFlightId = createSelector(
  (state: CombinedState) => state.requests,
  requests => {
    const { activeFlightId } = requests;
    if (activeFlightId == null) {
      return undefined;
    }
    const { flights } = requests.activeRequest!;
    const index = flights.findIndex(f => f.id === activeFlightId);
    const prevFlight = flights[index - 1];
    return prevFlight?.basicInfo.flightId;
  },
);

export const getFlightsForSelectCrew = createSelector(
  (state: CombinedState) => state.requests,
  requests => {
    if (requests.activeFlightId && requests.activeRequest) {
      if (requests.activeRequest.flights) {
        return requests.activeRequest.flights
          .filter(flight => flight.id !== requests.activeFlightId)
          .map(itemFlight => ({
            value: itemFlight.id,
            text: `${itemFlight.basicInfo.departure.name} -> ${itemFlight.basicInfo.arrival.name}`,
          }));
      }
    }

    return [];
  },
);
