import { PutRequestFilesDto } from '../../api';
import {
  AttachedLocalFile,
  FileArchiveRefType,
} from '../../components/shared/Files/FileArchiveManager';
import { formatDateUTC } from '../constants/common';

/**
 * return filename without extension
 * @param filename
 */
export const getFNameWithoutExt = (filename: string): string =>
  filename.split('.').slice(0, -1).join('.');

/**
 * downloads the selected file
 * @param url
 * @param filename
 */
export const downloadFile = (url: string, filename: string): void => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createApplicationBlobUrl = (blobData: Blob) => {
  const url = window.URL.createObjectURL(blobData);
  return url;
};

export const parseFileNameFromResponseHeader = (headers: Headers) => {
  const cd = headers.get('content-disposition');
  if (cd != null && cd.includes('filename')) {
    let fileName = cd.split('filename=')[1];
    fileName = fileName.substring(1, fileName.length - 1); // remove quotes
    return fileName;
  }
  return 'file';
};

export const processFilesFromManager = (
  manager: React.RefObject<FileArchiveRefType>,
): PutRequestFilesDto & { newFiles: File[] } => {
  if (manager.current) {
    const { activeFiles, archiveFiles } = manager.current!.getCurrentFiles();

    return {
      archiveFiles: archiveFiles.map(file => ({
        fileType: file.fileType,
        id: file.id,
      })),
      newFiles: activeFiles
        .filter(file => (file as AttachedLocalFile).file != null)
        .map(f => (f as AttachedLocalFile).file),
    };
  }
  return {
    archiveFiles: [],
    newFiles: [],
  };
};

export const decideExistingNewFiles = (
  manager: React.RefObject<FileArchiveRefType>,
): boolean => {
  const { newFiles } = processFilesFromManager(manager);
  return newFiles.length > 0;
};
/**
 *
 * @param format
 * @param date ISOString
 */
const formatDate = (
  format: string,
  date: string | null | undefined,
): string | undefined =>
  date != null ? `${formatDateUTC(new Date(date), format)}` : undefined;

export const formatDateRange = (
  format: string,
  dateFrom: string | null | undefined,
  dateTo: string | null | undefined,
): string | undefined => {
  const from = formatDate(format, dateFrom);
  const to = formatDate(format, dateTo);
  return from != null && to != null ? `${from} - ${to}` : undefined;
};

export const isImage = (type: string | undefined | null) => {
  if (!type) {
    return false;
  }
  return ['jpeg', 'jpg', 'png', 'webp', 'gif', 'tif', 'bmp'].includes(type);
};
