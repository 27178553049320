/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTokenWithUserDto
 */
export interface GetTokenWithUserDto {
    /**
     * JWT token
     * @type {string}
     * @memberof GetTokenWithUserDto
     */
    accessToken: string;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetTokenWithUserDto
     */
    user: GetUserDto;
}

export function GetTokenWithUserDtoFromJSON(json: any): GetTokenWithUserDto {
    return GetTokenWithUserDtoFromJSONTyped(json, false);
}

export function GetTokenWithUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTokenWithUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['access_token'],
        'user': GetUserDtoFromJSON(json['user']),
    };
}

export function GetTokenWithUserDtoToJSON(value?: GetTokenWithUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
        'user': GetUserDtoToJSON(value.user),
    };
}


