/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetFlightDto,
    GetFlightDtoFromJSON,
    GetFlightDtoFromJSONTyped,
    GetFlightDtoToJSON,
    GetRequestBasicInfoDto,
    GetRequestBasicInfoDtoFromJSON,
    GetRequestBasicInfoDtoFromJSONTyped,
    GetRequestBasicInfoDtoToJSON,
    GetRequestDiplomaticApprovalDto,
    GetRequestDiplomaticApprovalDtoFromJSON,
    GetRequestDiplomaticApprovalDtoFromJSONTyped,
    GetRequestDiplomaticApprovalDtoToJSON,
    GetRequestNotaDto,
    GetRequestNotaDtoFromJSON,
    GetRequestNotaDtoFromJSONTyped,
    GetRequestNotaDtoToJSON,
    GetRequestSummaryDto,
    GetRequestSummaryDtoFromJSON,
    GetRequestSummaryDtoFromJSONTyped,
    GetRequestSummaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestDto
 */
export interface GetRequestDto {
    /**
     * Request ID
     * @type {number}
     * @memberof GetRequestDto
     */
    id: number;
    /**
     * 
     * @type {GetRequestBasicInfoDto}
     * @memberof GetRequestDto
     */
    basicInfo: GetRequestBasicInfoDto;
    /**
     * 
     * @type {GetRequestNotaDto}
     * @memberof GetRequestDto
     */
    nota: GetRequestNotaDto;
    /**
     * 
     * @type {GetRequestDiplomaticApprovalDto}
     * @memberof GetRequestDto
     */
    diplomaticApproval: GetRequestDiplomaticApprovalDto;
    /**
     * 
     * @type {GetRequestSummaryDto}
     * @memberof GetRequestDto
     */
    summary: GetRequestSummaryDto;
    /**
     * 
     * @type {Array<GetFlightDto>}
     * @memberof GetRequestDto
     */
    flights: Array<GetFlightDto>;
}

export function GetRequestDtoFromJSON(json: any): GetRequestDto {
    return GetRequestDtoFromJSONTyped(json, false);
}

export function GetRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'basicInfo': GetRequestBasicInfoDtoFromJSON(json['basicInfo']),
        'nota': GetRequestNotaDtoFromJSON(json['nota']),
        'diplomaticApproval': GetRequestDiplomaticApprovalDtoFromJSON(json['diplomaticApproval']),
        'summary': GetRequestSummaryDtoFromJSON(json['summary']),
        'flights': ((json['flights'] as Array<any>).map(GetFlightDtoFromJSON)),
    };
}

export function GetRequestDtoToJSON(value?: GetRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'basicInfo': GetRequestBasicInfoDtoToJSON(value.basicInfo),
        'nota': GetRequestNotaDtoToJSON(value.nota),
        'diplomaticApproval': GetRequestDiplomaticApprovalDtoToJSON(value.diplomaticApproval),
        'summary': GetRequestSummaryDtoToJSON(value.summary),
        'flights': ((value.flights as Array<any>).map(GetFlightDtoToJSON)),
    };
}


