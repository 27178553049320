/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetPlaneDto,
    GetPlaneDtoFromJSON,
    GetPlaneDtoFromJSONTyped,
    GetPlaneDtoToJSON,
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
    ResponsibleOrganization,
    ResponsibleOrganizationFromJSON,
    ResponsibleOrganizationFromJSONTyped,
    ResponsibleOrganizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestBasicInfoDto
 */
export interface GetRequestBasicInfoDto {
    /**
     * Request name
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    name: string;
    /**
     * Request type
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    type?: string | null;
    /**
     * Request date from
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    from?: string | null;
    /**
     * Request date to
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    to?: string | null;
    /**
     * Request destination
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    destination?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    requestedBy?: string | null;
    /**
     * Request institution
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    institution?: string | null;
    /**
     * Request VIP passanger
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    vip?: string | null;
    /**
     * Has request any hazard materials?
     * @type {boolean}
     * @memberof GetRequestBasicInfoDto
     */
    isHazmat?: boolean | null;
    /**
     * Request contact
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    contact?: string | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetRequestBasicInfoDto
     */
    assignerPlanner?: GetUserDto;
    /**
     * 
     * @type {GetPlaneDto}
     * @memberof GetRequestBasicInfoDto
     */
    assignedPlane?: GetPlaneDto;
    /**
     * Is request temporary?
     * @type {boolean}
     * @memberof GetRequestBasicInfoDto
     */
    isTemporary?: boolean | null;
    /**
     * Is request approved?
     * @type {boolean}
     * @memberof GetRequestBasicInfoDto
     */
    isRequestApproved: boolean;
    /**
     * 
     * @type {GetPlaneDto}
     * @memberof GetRequestBasicInfoDto
     */
    backupAircraft?: GetPlaneDto;
    /**
     * Request comments
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    comments?: string | null;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetRequestBasicInfoDto
     */
    files: GetRequestFilesDto;
    /**
     * 
     * @type {ResponsibleOrganization}
     * @memberof GetRequestBasicInfoDto
     */
    responsibleOrganization?: ResponsibleOrganization;
    /**
     * Purpose of flight
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    purposeOfFlight?: string | null;
    /**
     * Request template
     * @type {string}
     * @memberof GetRequestBasicInfoDto
     */
    template?: string | null;
}

export function GetRequestBasicInfoDtoFromJSON(json: any): GetRequestBasicInfoDto {
    return GetRequestBasicInfoDtoFromJSONTyped(json, false);
}

export function GetRequestBasicInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestBasicInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'requestedBy': !exists(json, 'requestedBy') ? undefined : json['requestedBy'],
        'institution': !exists(json, 'institution') ? undefined : json['institution'],
        'vip': !exists(json, 'vip') ? undefined : json['vip'],
        'isHazmat': !exists(json, 'isHazmat') ? undefined : json['isHazmat'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'assignerPlanner': !exists(json, 'assignerPlanner') ? undefined : GetUserDtoFromJSON(json['assignerPlanner']),
        'assignedPlane': !exists(json, 'assignedPlane') ? undefined : GetPlaneDtoFromJSON(json['assignedPlane']),
        'isTemporary': !exists(json, 'isTemporary') ? undefined : json['isTemporary'],
        'isRequestApproved': json['isRequestApproved'],
        'backupAircraft': !exists(json, 'backupAircraft') ? undefined : GetPlaneDtoFromJSON(json['backupAircraft']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'files': GetRequestFilesDtoFromJSON(json['files']),
        'responsibleOrganization': !exists(json, 'responsibleOrganization') ? undefined : ResponsibleOrganizationFromJSON(json['responsibleOrganization']),
        'purposeOfFlight': !exists(json, 'purposeOfFlight') ? undefined : json['purposeOfFlight'],
        'template': !exists(json, 'template') ? undefined : json['template'],
    };
}

export function GetRequestBasicInfoDtoToJSON(value?: GetRequestBasicInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'from': value.from,
        'to': value.to,
        'destination': value.destination,
        'requestedBy': value.requestedBy,
        'institution': value.institution,
        'vip': value.vip,
        'isHazmat': value.isHazmat,
        'contact': value.contact,
        'assignerPlanner': GetUserDtoToJSON(value.assignerPlanner),
        'assignedPlane': GetPlaneDtoToJSON(value.assignedPlane),
        'isTemporary': value.isTemporary,
        'isRequestApproved': value.isRequestApproved,
        'backupAircraft': GetPlaneDtoToJSON(value.backupAircraft),
        'comments': value.comments,
        'files': GetRequestFilesDtoToJSON(value.files),
        'responsibleOrganization': ResponsibleOrganizationToJSON(value.responsibleOrganization),
        'purposeOfFlight': value.purposeOfFlight,
        'template': value.template,
    };
}


