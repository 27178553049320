/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetFlightBasicInfoDto,
    GetFlightBasicInfoDtoFromJSON,
    GetFlightBasicInfoDtoFromJSONTyped,
    GetFlightBasicInfoDtoToJSON,
    GetFlightCargoDto,
    GetFlightCargoDtoFromJSON,
    GetFlightCargoDtoFromJSONTyped,
    GetFlightCargoDtoToJSON,
    GetFlightCrewDto,
    GetFlightCrewDtoFromJSON,
    GetFlightCrewDtoFromJSONTyped,
    GetFlightCrewDtoToJSON,
    GetFlightHandlingDto,
    GetFlightHandlingDtoFromJSON,
    GetFlightHandlingDtoFromJSONTyped,
    GetFlightHandlingDtoToJSON,
    GetFlightPaxDto,
    GetFlightPaxDtoFromJSON,
    GetFlightPaxDtoFromJSONTyped,
    GetFlightPaxDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFlightDto
 */
export interface GetFlightDto {
    /**
     * Flight ID
     * @type {number}
     * @memberof GetFlightDto
     */
    id: number;
    /**
     * 
     * @type {GetFlightBasicInfoDto}
     * @memberof GetFlightDto
     */
    basicInfo: GetFlightBasicInfoDto;
    /**
     * 
     * @type {GetFlightCargoDto}
     * @memberof GetFlightDto
     */
    cargo: GetFlightCargoDto;
    /**
     * 
     * @type {GetFlightPaxDto}
     * @memberof GetFlightDto
     */
    pax: GetFlightPaxDto;
    /**
     * 
     * @type {GetFlightHandlingDto}
     * @memberof GetFlightDto
     */
    handling: GetFlightHandlingDto;
    /**
     * 
     * @type {GetFlightCrewDto}
     * @memberof GetFlightDto
     */
    crew: GetFlightCrewDto;
}

export function GetFlightDtoFromJSON(json: any): GetFlightDto {
    return GetFlightDtoFromJSONTyped(json, false);
}

export function GetFlightDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFlightDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'basicInfo': GetFlightBasicInfoDtoFromJSON(json['basicInfo']),
        'cargo': GetFlightCargoDtoFromJSON(json['cargo']),
        'pax': GetFlightPaxDtoFromJSON(json['pax']),
        'handling': GetFlightHandlingDtoFromJSON(json['handling']),
        'crew': GetFlightCrewDtoFromJSON(json['crew']),
    };
}

export function GetFlightDtoToJSON(value?: GetFlightDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'basicInfo': GetFlightBasicInfoDtoToJSON(value.basicInfo),
        'cargo': GetFlightCargoDtoToJSON(value.cargo),
        'pax': GetFlightPaxDtoToJSON(value.pax),
        'handling': GetFlightHandlingDtoToJSON(value.handling),
        'crew': GetFlightCrewDtoToJSON(value.crew),
    };
}


