/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetPDFGenerationStatusDto,
    GetPDFGenerationStatusDtoFromJSON,
    GetPDFGenerationStatusDtoToJSON,
} from '../models';

export interface PDFsControllerDownloadPDFByIdRequest {
    pdfId: string;
}

export interface PDFsControllerGetGenerationStatusRequest {
    pdfId: string;
}

/**
 * 
 */
export class PDFsApi extends runtime.BaseAPI {

    /**
     * Use this endpoint to fetch PDF document. This document has to be generated already, otherwise expect error.
     * Download PDF document by PDF id
     */
    async pDFsControllerDownloadPDFByIdRaw(requestParameters: PDFsControllerDownloadPDFByIdRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.pdfId === null || requestParameters.pdfId === undefined) {
            throw new runtime.RequiredError('pdfId','Required parameter requestParameters.pdfId was null or undefined when calling pDFsControllerDownloadPDFById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/{pdfId}`.replace(`{${"pdfId"}}`, encodeURIComponent(String(requestParameters.pdfId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Use this endpoint to fetch PDF document. This document has to be generated already, otherwise expect error.
     * Download PDF document by PDF id
     */
    async pDFsControllerDownloadPDFById(requestParameters: PDFsControllerDownloadPDFByIdRequest): Promise<Blob> {
        const response = await this.pDFsControllerDownloadPDFByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Use this endpoint to get state of PDF generation, which was started previously and is running in background.
     * Get status of generation PDF file
     */
    async pDFsControllerGetGenerationStatusRaw(requestParameters: PDFsControllerGetGenerationStatusRequest): Promise<runtime.ApiResponse<GetPDFGenerationStatusDto>> {
        if (requestParameters.pdfId === null || requestParameters.pdfId === undefined) {
            throw new runtime.RequiredError('pdfId','Required parameter requestParameters.pdfId was null or undefined when calling pDFsControllerGetGenerationStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/{pdfId}/status`.replace(`{${"pdfId"}}`, encodeURIComponent(String(requestParameters.pdfId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPDFGenerationStatusDtoFromJSON(jsonValue));
    }

    /**
     * Use this endpoint to get state of PDF generation, which was started previously and is running in background.
     * Get status of generation PDF file
     */
    async pDFsControllerGetGenerationStatus(requestParameters: PDFsControllerGetGenerationStatusRequest): Promise<GetPDFGenerationStatusDto> {
        const response = await this.pDFsControllerGetGenerationStatusRaw(requestParameters);
        return await response.value();
    }

}
