/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RequestSection {
    BASIC_INFO = 'BASIC_INFO',
    NOTA = 'NOTA',
    DIPLOMATIC_APPROVAL = 'DIPLOMATIC_APPROVAL',
    ONE_TIME_PERMIT = 'ONE_TIME_PERMIT',
    AIRPORT_SLOTS = 'AIRPORT_SLOTS',
    PPRS = 'PPRS',
    CARGO = 'CARGO',
    DECLARATION = 'DECLARATION',
    CREW = 'CREW',
    GENERAL_DECLARATION = 'GENERAL_DECLARATION',
    CREW_LIST = 'CREW_LIST',
    PAX = 'PAX',
    PASSENGER_LISTS = 'PASSENGER_LISTS',
    HANDLING = 'HANDLING',
    HANDLING_CONFIRMATION = 'HANDLING_CONFIRMATION',
    HANDLING_REQUESTS = 'HANDLING_REQUESTS',
    FUEL_RELEASE = 'FUEL_RELEASE',
    HOTAC_OR_TRANSPORT = 'HOTAC_OR_TRANSPORT',
    SUMMARY = 'SUMMARY',
    REPORT = 'REPORT',
    POST_FLIGHT_DOC = 'POST_FLIGHT_DOC'
}

export function RequestSectionFromJSON(json: any): RequestSection {
    return RequestSectionFromJSONTyped(json, false);
}

export function RequestSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestSection {
    return json as RequestSection;
}

export function RequestSectionToJSON(value?: RequestSection | null): any {
    return value as any;
}

