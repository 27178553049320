/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestSection,
    RequestSectionFromJSON,
    RequestSectionFromJSONTyped,
    RequestSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAuditAttributeUpdateDto
 */
export interface GetAuditAttributeUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof GetAuditAttributeUpdateDto
     */
    attributeName: string;
    /**
     * 
     * @type {Array<RequestSection>}
     * @memberof GetAuditAttributeUpdateDto
     */
    sectionPath: Array<RequestSection>;
    /**
     * 
     * @type {string}
     * @memberof GetAuditAttributeUpdateDto
     */
    changeDescription: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditAttributeUpdateDto
     */
    fileName?: string | null;
}

export function GetAuditAttributeUpdateDtoFromJSON(json: any): GetAuditAttributeUpdateDto {
    return GetAuditAttributeUpdateDtoFromJSONTyped(json, false);
}

export function GetAuditAttributeUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditAttributeUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'sectionPath': ((json['sectionPath'] as Array<any>).map(RequestSectionFromJSON)),
        'changeDescription': json['changeDescription'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function GetAuditAttributeUpdateDtoToJSON(value?: GetAuditAttributeUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'sectionPath': ((value.sectionPath as Array<any>).map(RequestSectionToJSON)),
        'changeDescription': value.changeDescription,
        'fileName': value.fileName,
    };
}


