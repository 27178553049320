/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RequestFileType {
    BASIC_INFO = 'BASIC_INFO',
    NOTA = 'NOTA',
    DIPLOMATIC_APPROVAL = 'DIPLOMATIC_APPROVAL',
    DIPLOMATIC_APPROVALONE_TIME_PERMIT = 'DIPLOMATIC_APPROVAL$ONE_TIME_PERMIT',
    DIPLOMATIC_APPROVALAIRPORT_SLOTS = 'DIPLOMATIC_APPROVAL$AIRPORT_SLOTS',
    DIPLOMATIC_APPROVALPPRS = 'DIPLOMATIC_APPROVAL$PPRS',
    FLIGHTSCARGODECLARATION = 'FLIGHTS$CARGO$DECLARATION',
    FLIGHTSCREWGENERAL_DECLARATION = 'FLIGHTS$CREW$GENERAL_DECLARATION',
    FLIGHTSCREWCREW_LIST = 'FLIGHTS$CREW$CREW_LIST',
    FLIGHTSPAXPASSENGER_LISTS = 'FLIGHTS$PAX$PASSENGER_LISTS',
    FLIGHTSHANDLINGHANDLING_CONFIRMATION = 'FLIGHTS$HANDLING$HANDLING_CONFIRMATION',
    FLIGHTSHANDLINGHANDLING_REQUESTS = 'FLIGHTS$HANDLING$HANDLING_REQUESTS',
    FLIGHTSHANDLINGFUEL_RELEASE = 'FLIGHTS$HANDLING$FUEL_RELEASE',
    FLIGHTSHANDLINGHOTAC_OR_TRANSPORT = 'FLIGHTS$HANDLING$HOTAC_OR_TRANSPORT',
    SUMMARYREPORT = 'SUMMARY$REPORT',
    SUMMARYPOST_FLIGHT_DOC = 'SUMMARY$POST_FLIGHT_DOC'
}

export function RequestFileTypeFromJSON(json: any): RequestFileType {
    return RequestFileTypeFromJSONTyped(json, false);
}

export function RequestFileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFileType {
    return json as RequestFileType;
}

export function RequestFileTypeToJSON(value?: RequestFileType | null): any {
    return value as any;
}

