/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutFlightPaxDto
 */
export interface PutFlightPaxDto {
    /**
     * 
     * @type {number}
     * @memberof PutFlightPaxDto
     */
    numberOfPassengers?: number | null;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightPaxDto
     */
    passengerLists: PutRequestFilesDto;
    /**
     * 
     * @type {string}
     * @memberof PutFlightPaxDto
     */
    comments?: string | null;
}

export function PutFlightPaxDtoFromJSON(json: any): PutFlightPaxDto {
    return PutFlightPaxDtoFromJSONTyped(json, false);
}

export function PutFlightPaxDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightPaxDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfPassengers': !exists(json, 'numberOfPassengers') ? undefined : json['numberOfPassengers'],
        'passengerLists': PutRequestFilesDtoFromJSON(json['passengerLists']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function PutFlightPaxDtoToJSON(value?: PutFlightPaxDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfPassengers': value.numberOfPassengers,
        'passengerLists': PutRequestFilesDtoToJSON(value.passengerLists),
        'comments': value.comments,
    };
}


