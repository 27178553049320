/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PDFGeneratingStatus {
    initial = 'initial',
    loading = 'loading',
    success = 'success',
    error = 'error'
}

export function PDFGeneratingStatusFromJSON(json: any): PDFGeneratingStatus {
    return PDFGeneratingStatusFromJSONTyped(json, false);
}

export function PDFGeneratingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PDFGeneratingStatus {
    return json as PDFGeneratingStatus;
}

export function PDFGeneratingStatusToJSON(value?: PDFGeneratingStatus | null): any {
    return value as any;
}

