/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostLoginDto
 */
export interface PostLoginDto {
    /**
     * User's email/login
     * @type {string}
     * @memberof PostLoginDto
     */
    email: string;
    /**
     * User's password
     * @type {string}
     * @memberof PostLoginDto
     */
    password: string;
}

export function PostLoginDtoFromJSON(json: any): PostLoginDto {
    return PostLoginDtoFromJSONTyped(json, false);
}

export function PostLoginDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostLoginDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function PostLoginDtoToJSON(value?: PostLoginDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
    };
}


