import { on } from 'ts-action-immer';
import { reducer } from 'ts-action';
import { v4 as uuidv4 } from 'uuid';
import { GetRequestEnumDataDto } from '../../api/models/GetRequestEnumDataDto';
import { createSnackbar, setAllUsers, setRequestDataEnums } from './actions';
import { SnackBarProps } from '../../helpers/interfaces/snackbar';
import { GetUserDto } from '../../api';

export interface State {
  requestEnumData: GetRequestEnumDataDto | undefined;
  snackbar: SnackBarProps | undefined;
  allUsers: GetUserDto[];
}

export const initialState: State = {
  requestEnumData: undefined,
  snackbar: undefined,
  allUsers: [],
};

export const generalReducer = reducer<State>(
  initialState,
  on(setRequestDataEnums, (state: State, { payload }) => {
    state.requestEnumData = payload.requestEnum;
  }),
  on(createSnackbar, (state: State, { payload }) => {
    const snackWithId: SnackBarProps = { ...payload, id: uuidv4() };
    state.snackbar = snackWithId;
  }),
  on(setAllUsers, (state: State, { payload }) => {
    state.allUsers = payload.users;
  }),
);
