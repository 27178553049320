/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutRequestNotaDto
 */
export interface PutRequestNotaDto {
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutRequestNotaDto
     */
    files: PutRequestFilesDto;
    /**
     * 
     * @type {string}
     * @memberof PutRequestNotaDto
     */
    comments?: string | null;
}

export function PutRequestNotaDtoFromJSON(json: any): PutRequestNotaDto {
    return PutRequestNotaDtoFromJSONTyped(json, false);
}

export function PutRequestNotaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutRequestNotaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'files': PutRequestFilesDtoFromJSON(json['files']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function PutRequestNotaDtoToJSON(value?: PutRequestNotaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'files': PutRequestFilesDtoToJSON(value.files),
        'comments': value.comments,
    };
}


