import { createSelector } from 'reselect';
import { Permission, Role } from '../../api';
import { LoadingState } from '../../helpers/enums/general';
import { CombinedState } from '../combinedReducers';

export const getLoggedUser = createSelector(
  (state: CombinedState) => state.auth,
  auth => auth.loggedUser,
);

export const getIsUserRefreshed = createSelector(
  (state: CombinedState) => state.auth,
  auth => auth.isUserRefreshed,
);

export const isLoginLoading = createSelector(
  (state: CombinedState) => state.auth,
  auth => auth.loadState === LoadingState.Loading,
);

export const isLoginError = createSelector(
  (state: CombinedState) => state.auth,
  auth => auth.loadState === LoadingState.Error,
);

export const isReadOnlyRole = createSelector(
  (state: CombinedState) => state.auth,
  auth =>
    auth.loggedUser
      ? auth.loggedUser.role === Role.PILOT ||
        auth.loggedUser.role === Role.CHIEF_CABIN_CREW
      : false,
);

export const isAllAllowed = createSelector(
  (state: CombinedState) => state.auth,
  auth =>
    auth.loggedUser
      ? auth.loggedUser.role === Role.ADMIN ||
        auth.loggedUser.role === Role.HEADQUARTERS ||
        auth.loggedUser.role === Role.HEAD_PLANNER ||
        auth.loggedUser.role === Role.CHIEF
      : false,
);

export const isCrewAllowed = createSelector(
  (state: CombinedState) => state.auth,
  auth =>
    auth.loggedUser
      ? auth.loggedUser.permissions.includes(Permission.CREW) ||
        auth.loggedUser.permissions.includes(Permission.FLIGHTS)
      : false,
);

export const isDAAllowed = createSelector(
  (state: CombinedState) => state.auth,
  auth =>
    auth.loggedUser
      ? auth.loggedUser.permissions.includes(Permission.DIPLOMATIC_APPROVAL)
      : false,
);

export const isFlightsAllowed = createSelector(
  (state: CombinedState) => state.auth,
  auth =>
    auth.loggedUser
      ? auth.loggedUser.permissions.includes(Permission.FLIGHTS)
      : false,
);
