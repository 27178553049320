/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetCrewPersonDto,
    GetCrewPersonDtoFromJSON,
    GetCrewPersonDtoFromJSONTyped,
    GetCrewPersonDtoToJSON,
    GetCrewSameAsPreviousLeg,
    GetCrewSameAsPreviousLegFromJSON,
    GetCrewSameAsPreviousLegFromJSONTyped,
    GetCrewSameAsPreviousLegToJSON,
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFlightCrewDto
 */
export interface GetFlightCrewDto {
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetFlightCrewDto
     */
    generalDeclaration: GetRequestFilesDto;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetFlightCrewDto
     */
    crewList: GetRequestFilesDto;
    /**
     * 
     * @type {Array<GetCrewPersonDto>}
     * @memberof GetFlightCrewDto
     */
    flightCrew: Array<GetCrewPersonDto>;
    /**
     * 
     * @type {Array<GetCrewPersonDto>}
     * @memberof GetFlightCrewDto
     */
    cabinCrew: Array<GetCrewPersonDto>;
    /**
     * 
     * @type {Array<GetCrewPersonDto>}
     * @memberof GetFlightCrewDto
     */
    groundTechnicians: Array<GetCrewPersonDto>;
    /**
     * 
     * @type {Array<GetCrewPersonDto>}
     * @memberof GetFlightCrewDto
     */
    medicalDoctor: Array<GetCrewPersonDto>;
    /**
     * 
     * @type {Array<GetCrewPersonDto>}
     * @memberof GetFlightCrewDto
     */
    militaryPolice: Array<GetCrewPersonDto>;
    /**
     * 
     * @type {Array<GetCrewSameAsPreviousLeg>}
     * @memberof GetFlightCrewDto
     */
    sameAsPreviousLegs: Array<GetCrewSameAsPreviousLeg>;
    /**
     * 
     * @type {string}
     * @memberof GetFlightCrewDto
     */
    comments?: string | null;
}

export function GetFlightCrewDtoFromJSON(json: any): GetFlightCrewDto {
    return GetFlightCrewDtoFromJSONTyped(json, false);
}

export function GetFlightCrewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFlightCrewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generalDeclaration': GetRequestFilesDtoFromJSON(json['generalDeclaration']),
        'crewList': GetRequestFilesDtoFromJSON(json['crewList']),
        'flightCrew': ((json['flightCrew'] as Array<any>).map(GetCrewPersonDtoFromJSON)),
        'cabinCrew': ((json['cabinCrew'] as Array<any>).map(GetCrewPersonDtoFromJSON)),
        'groundTechnicians': ((json['groundTechnicians'] as Array<any>).map(GetCrewPersonDtoFromJSON)),
        'medicalDoctor': ((json['medicalDoctor'] as Array<any>).map(GetCrewPersonDtoFromJSON)),
        'militaryPolice': ((json['militaryPolice'] as Array<any>).map(GetCrewPersonDtoFromJSON)),
        'sameAsPreviousLegs': ((json['sameAsPreviousLegs'] as Array<any>).map(GetCrewSameAsPreviousLegFromJSON)),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function GetFlightCrewDtoToJSON(value?: GetFlightCrewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generalDeclaration': GetRequestFilesDtoToJSON(value.generalDeclaration),
        'crewList': GetRequestFilesDtoToJSON(value.crewList),
        'flightCrew': ((value.flightCrew as Array<any>).map(GetCrewPersonDtoToJSON)),
        'cabinCrew': ((value.cabinCrew as Array<any>).map(GetCrewPersonDtoToJSON)),
        'groundTechnicians': ((value.groundTechnicians as Array<any>).map(GetCrewPersonDtoToJSON)),
        'medicalDoctor': ((value.medicalDoctor as Array<any>).map(GetCrewPersonDtoToJSON)),
        'militaryPolice': ((value.militaryPolice as Array<any>).map(GetCrewPersonDtoToJSON)),
        'sameAsPreviousLegs': ((value.sameAsPreviousLegs as Array<any>).map(GetCrewSameAsPreviousLegToJSON)),
        'comments': value.comments,
    };
}


