/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRequestFilesDto,
    GetRequestFilesDtoFromJSON,
    GetRequestFilesDtoFromJSONTyped,
    GetRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFlightCargoDto
 */
export interface GetFlightCargoDto {
    /**
     * Has flight HAZMAT onboard?
     * @type {boolean}
     * @memberof GetFlightCargoDto
     */
    hasHazmat: boolean;
    /**
     * Has flight NOTOC onboard?
     * @type {boolean}
     * @memberof GetFlightCargoDto
     */
    hasNotoc: boolean;
    /**
     * Cargo weight
     * @type {number}
     * @memberof GetFlightCargoDto
     */
    weight?: number | null;
    /**
     * 
     * @type {GetRequestFilesDto}
     * @memberof GetFlightCargoDto
     */
    declaration: GetRequestFilesDto;
    /**
     * Flight cargo comments
     * @type {string}
     * @memberof GetFlightCargoDto
     */
    comments?: string | null;
}

export function GetFlightCargoDtoFromJSON(json: any): GetFlightCargoDto {
    return GetFlightCargoDtoFromJSONTyped(json, false);
}

export function GetFlightCargoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFlightCargoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasHazmat': json['hasHazmat'],
        'hasNotoc': json['hasNotoc'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'declaration': GetRequestFilesDtoFromJSON(json['declaration']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function GetFlightCargoDtoToJSON(value?: GetFlightCargoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasHazmat': value.hasHazmat,
        'hasNotoc': value.hasNotoc,
        'weight': value.weight,
        'declaration': GetRequestFilesDtoToJSON(value.declaration),
        'comments': value.comments,
    };
}


