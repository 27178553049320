/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutRequestSummaryDto
 */
export interface PutRequestSummaryDto {
    /**
     * Is request ready?
     * @type {boolean}
     * @memberof PutRequestSummaryDto
     */
    isReadyToDispatch: boolean;
    /**
     * Is request active?
     * @type {boolean}
     * @memberof PutRequestSummaryDto
     */
    isActive: boolean;
    /**
     * Request comments
     * @type {string}
     * @memberof PutRequestSummaryDto
     */
    comments?: string | null;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutRequestSummaryDto
     */
    postFlightDoc: PutRequestFilesDto;
}

export function PutRequestSummaryDtoFromJSON(json: any): PutRequestSummaryDto {
    return PutRequestSummaryDtoFromJSONTyped(json, false);
}

export function PutRequestSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutRequestSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isReadyToDispatch': json['isReadyToDispatch'],
        'isActive': json['isActive'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'postFlightDoc': PutRequestFilesDtoFromJSON(json['postFlightDoc']),
    };
}

export function PutRequestSummaryDtoToJSON(value?: PutRequestSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isReadyToDispatch': value.isReadyToDispatch,
        'isActive': value.isActive,
        'comments': value.comments,
        'postFlightDoc': PutRequestFilesDtoToJSON(value.postFlightDoc),
    };
}


