import { css, SerializedStyles } from '@emotion/core';
import { Colors, MPX } from './themes';

export const linkCss: SerializedStyles = css`
  text-decoration: none !important;
`;

export const AppMenuHeight = MPX * 18.5;

export const scrollWrapCss: SerializedStyles = css`
  height: 100% !important;
  overflow: hidden !important;
`;
export const scrollContentCss: SerializedStyles = css`
  height: 100% !important;
  overflow: auto !important;
`;

export const niceScrollbarCss: SerializedStyles = css`
  &::-webkit-scrollbar {
    width: ${MPX * 2}px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: ${MPX * 2.5}px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${MPX * 2.5}px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const inputsGlobalCss: SerializedStyles = css`
  min-height: ${MPX * 12}px;
  display: flex;
  align-items: center;
  font-size: ${MPX * 4.5}px;
`;

export const tableWrapCss: SerializedStyles = css`
  padding: ${MPX * 3}px;
  border: 1px solid ${Colors.lightGray};
  border-radius: 6px;
`;
