/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRequestFileDto,
    GetRequestFileDtoFromJSON,
    GetRequestFileDtoFromJSONTyped,
    GetRequestFileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestFilesDto
 */
export interface GetRequestFilesDto {
    /**
     * 
     * @type {Array<GetRequestFileDto>}
     * @memberof GetRequestFilesDto
     */
    archiveFiles: Array<GetRequestFileDto>;
    /**
     * 
     * @type {Array<GetRequestFileDto>}
     * @memberof GetRequestFilesDto
     */
    activeFiles: Array<GetRequestFileDto>;
}

export function GetRequestFilesDtoFromJSON(json: any): GetRequestFilesDto {
    return GetRequestFilesDtoFromJSONTyped(json, false);
}

export function GetRequestFilesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestFilesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archiveFiles': ((json['archiveFiles'] as Array<any>).map(GetRequestFileDtoFromJSON)),
        'activeFiles': ((json['activeFiles'] as Array<any>).map(GetRequestFileDtoFromJSON)),
    };
}

export function GetRequestFilesDtoToJSON(value?: GetRequestFilesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archiveFiles': ((value.archiveFiles as Array<any>).map(GetRequestFileDtoToJSON)),
        'activeFiles': ((value.activeFiles as Array<any>).map(GetRequestFileDtoToJSON)),
    };
}


