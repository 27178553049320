/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutRequestFilesDto,
    PutRequestFilesDtoFromJSON,
    PutRequestFilesDtoFromJSONTyped,
    PutRequestFilesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutFlightHandlingDto
 */
export interface PutFlightHandlingDto {
    /**
     * 
     * @type {string}
     * @memberof PutFlightHandlingDto
     */
    handlingCompany?: string | null;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightHandlingDto
     */
    handlingConfirmation: PutRequestFilesDto;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightHandlingDto
     */
    handlingRequests: PutRequestFilesDto;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightHandlingDto
     */
    fuelRelease: PutRequestFilesDto;
    /**
     * 
     * @type {PutRequestFilesDto}
     * @memberof PutFlightHandlingDto
     */
    hotacOrTransport: PutRequestFilesDto;
    /**
     * 
     * @type {string}
     * @memberof PutFlightHandlingDto
     */
    comments?: string | null;
}

export function PutFlightHandlingDtoFromJSON(json: any): PutFlightHandlingDto {
    return PutFlightHandlingDtoFromJSONTyped(json, false);
}

export function PutFlightHandlingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightHandlingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'handlingCompany': !exists(json, 'handlingCompany') ? undefined : json['handlingCompany'],
        'handlingConfirmation': PutRequestFilesDtoFromJSON(json['handlingConfirmation']),
        'handlingRequests': PutRequestFilesDtoFromJSON(json['handlingRequests']),
        'fuelRelease': PutRequestFilesDtoFromJSON(json['fuelRelease']),
        'hotacOrTransport': PutRequestFilesDtoFromJSON(json['hotacOrTransport']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function PutFlightHandlingDtoToJSON(value?: PutFlightHandlingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'handlingCompany': value.handlingCompany,
        'handlingConfirmation': PutRequestFilesDtoToJSON(value.handlingConfirmation),
        'handlingRequests': PutRequestFilesDtoToJSON(value.handlingRequests),
        'fuelRelease': PutRequestFilesDtoToJSON(value.fuelRelease),
        'hotacOrTransport': PutRequestFilesDtoToJSON(value.hotacOrTransport),
        'comments': value.comments,
    };
}


