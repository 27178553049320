/** @jsx jsx */
/* eslint-disable */
import { css, jsx, SerializedStyles } from '@emotion/core';
import MomentUtils from '@date-io/moment';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  SwitchProps,
  TextField,
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { TextFieldProps } from '@material-ui/core/TextField';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { v4 as uuidv4 } from 'uuid';
import {
  DEFAULT_INPUT_WIDTH,
  FLIGHT_MOBILE_BREAKPOINT,
  INPUT_MOBILE_BREAKPOINT,
} from '../../helpers/constants/common';
import { isAllAllowed, isReadOnlyRole } from '../../store/auth/selectors';
import { inputsGlobalCss } from '../../styles/global';
import { Colors, MPX } from '../../styles/themes';
import { Autocomplete } from '@material-ui/lab';

export type AppTextInputProps = Omit<
  {
    rules?: RegisterOptions;
    name: string;
    label: string;
    upperHelperText?: JSX.Element;
    width?: string | number;
  } & TextFieldProps,
  'value'
>;

export const AppTextInput: FC<AppTextInputProps> = ({
  rules,
  label,
  name,
  fullWidth,
  variant,
  margin,
  width = DEFAULT_INPUT_WIDTH,
  upperHelperText,
  ...restProps
}) => {
  const { errors, control } = useFormContext();
  const error = errors[name];

  const customProps: TextFieldProps = useMemo(
    () => ({
      label,
      margin: margin || 'dense',
      variant: variant || 'outlined',
      fullWidth: fullWidth != null ? fullWidth : true,
      name,
      error: error != null,
      InputLabelProps: {
        required: rules && rules.required != null,
      },
    }),
    [rules, margin, variant, fullWidth, name, error, label],
  );

  return (
    <div css={appTextInputWrapper(width, fullWidth)}>
      {upperHelperText != null && (
        <span css={appTextInputUpperHint}>{upperHelperText}</span>
      )}
      <Controller
        render={({ onChange, value }) => {
          const valueToRender = Number.isInteger(value) ? value! : value || '';
          return (
            <TextField
              {...restProps}
              {...customProps}
              value={valueToRender}
              onChange={onChange}
              css={appTextInput}
            />
          );
        }}
        control={control}
        name={name}
        rules={rules}
        defaultValue=""
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </div>
  );
};

export const appTextInput: SerializedStyles = css`
  .MuiInputBase-root {
    background: white;
    border-radius: 4px;
  }
  .MuiOutlinedInput-inputMarginDense {
    ${inputsGlobalCss}
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: ${MPX * 3.5}px !important;
    box-sizing: border-box !important;
  }
`;

export const appTextInputWrapper = (
  width: string | number,
  fullWidth: boolean | undefined,
): SerializedStyles => css`
  position: relative;
  width: ${fullWidth ? '100%' : `${width}px`} !important;
  @media screen and (max-width: ${INPUT_MOBILE_BREAKPOINT}px) {
    width: 100% !important;
  }
`;

const appTextInputUpperHint: SerializedStyles = css`
  position: absolute;
  right: 0;
  top: -12px;
`;

type AppFormSubmitProps = {
  text: string;
  disableAccordingToPermission?: boolean;
  disabledOnError?: boolean;
  loading?: boolean;
  width?: number;
  height?: number;
  customCss?: SerializedStyles;
  name?: string;
  value?: string;
} & ButtonProps;

export const AppFormSubmit: FC<AppFormSubmitProps> = ({
  text,
  disableAccordingToPermission,
  disabledOnError,
  variant,
  color,
  fullWidth,
  size,
  loading,
  width = MPX * 55,
  height = MPX * 13.75,
  customCss,
  name,
  value,
  disabled,
  ...restProps
}) => {
  // role co muze jen cist
  const isReadOnlyForm = useSelector(isReadOnlyRole);
  // role co muze delat vse
  const isAllAllowedForm = useSelector(isAllAllowed);
  const isDisabled = useMemo(() => {
    // pokud je role co ma vsechno tak nedisabluju
    if (isAllAllowedForm) {
      return false;
      // pokud je role co ma jen readly tak nech vsude globalne disabled
    }
    if (isReadOnlyForm) {
      return isReadOnlyForm;
    }
    // jinak vem to co se poslalo do toho tlacika jako disable
    return disableAccordingToPermission !== undefined
      ? disableAccordingToPermission
      : true;
  }, [isReadOnlyForm, isAllAllowedForm, disableAccordingToPermission]);
  const allProps: ButtonProps = {
    variant: variant || 'outlined',
    color: color || 'primary',
    size: size || 'large',
    fullWidth: fullWidth != null ? fullWidth : true,
    // pokud je role co ma vsechno tak nedisabluju
    disabled: isDisabled,
  };

  return (
    <div css={submitBtnWrapper}>
      <Button
        css={customCss || submitBtnCss(width, height, fullWidth)}
        type="submit"
        {...restProps}
        {...allProps}
        disableElevation
      >
        {loading ? (
          <CircularProgress size={24} css={submitBtnProgress} />
        ) : (
          text
        )}
      </Button>
    </div>
  );
};

const submitBtnWrapper: SerializedStyles = css`
  position: relative;
  padding: ${MPX * 5}px 0;
  text-align: center;
  width: 100% !important;
`;
const submitBtnCss = (
  width: number | undefined,
  height: number | undefined,
  fullWidth: boolean | undefined,
): SerializedStyles => css`
  height: ${height}px !important;
  width: ${fullWidth ? '100%' : `${width}px`} !important;
  color: ${Colors.white} !important;
  background-color: ${Colors.primary} !important;
`;
const submitBtnProgress: SerializedStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: ${Colors.white} !important;
`;

export const AppFormSubmitWrap: FC = props => {
  const { children } = props;
  return (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <div>{children}</div>
    </Grid>
  );
};

export const AppFormVertical: FC = props => {
  const { children } = props;
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      {children}
    </Grid>
  );
};

export const AppFormRow: FC = props => {
  const { children } = props;
  return (
    <Grid container css={formRowCss}>
      {children}
    </Grid>
  );
};

const formRowCss: SerializedStyles = css`
  padding: ${MPX * 2.5}px 0;

  @media screen and (max-width: ${FLIGHT_MOBILE_BREAKPOINT}px) {
    gap: ${MPX * 5}px !important;
  }

  @media screen and (min-width: ${FLIGHT_MOBILE_BREAKPOINT + 1}px) {
    gap: ${MPX * 40}px !important;
  }
`;

export interface AppSelectInputProps {
  name: string;
  options: Array<{ value: string | number; text: string }>;
  defaultValue?: { value: string | number; text: string };
  label: string;
  rules?: RegisterOptions;
  variant?: 'standard' | 'filled' | 'outlined';
  width?: string | number;
  fullWidth?: boolean;
  customOnChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
}

export const AppSelectInput: React.FC<AppSelectInputProps> = ({
  name,
  options,
  label,
  rules,
  variant = 'outlined',
  width = DEFAULT_INPUT_WIDTH,
  fullWidth,
  defaultValue,
  customOnChange,
}) => {
  const { errors, control } = useFormContext();
  const error = errors[name];
  const selectUuid = uuidv4();
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect((): void => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return (
    <FormControl
      variant={variant}
      css={selectWrapperCss(width, fullWidth)}
      error={!!error}
      margin="dense"
    >
      <InputLabel
        ref={inputLabel}
        id={selectUuid}
        margin="dense"
        required={rules && rules.required != null}
      >
        {label}
      </InputLabel>
      <Controller
        render={({ onChange, value }) => (
          <Select
            labelId={selectUuid}
            css={selectCss}
            variant={variant}
            autoWidth
            multiple={false}
            defaultValue={defaultValue}
            value={value}
            onChange={e => {
              if (customOnChange) {
                customOnChange(e);
              }
              onChange(e);
            }}
            labelWidth={labelWidth}
            margin="dense"
          >
            {!options.length ? (
              <MenuItem disabled css={menuItemCss}>
                No data
              </MenuItem>
            ) : (
              <MenuItem value="" key="" css={[menuItemCss, defaultMenuItemCss]}>
                None
              </MenuItem>
            )}
            {defaultValue &&
              !options
                .map(option => option.value)
                .includes(defaultValue.value) && (
                <MenuItem disabled value={defaultValue.value} css={menuItemCss}>
                  {defaultValue.text}
                </MenuItem>
              )}
            {options.map(item => (
              <MenuItem key={item.value} value={item.value} css={menuItemCss}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        )}
        control={control}
        name={name}
        rules={rules}
        defaultValue=""
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

const selectWrapperCss = (
  width: string | number,
  fullWidth: boolean | undefined,
): SerializedStyles => css`
  @media screen and (min-width: 561px) {
    width: ${fullWidth ? '100%' : `${width}px`} !important;
  }
  @media screen and (max-width: ${INPUT_MOBILE_BREAKPOINT}px) {
    width: 100% !important;
  }
`;
export const selectCss: SerializedStyles = css`
  .MuiSelect-select.MuiSelect-select {
    ${inputsGlobalCss}
  }
  width: 100%;
  background-color: ${Colors.white} !important;
`;
export const menuItemCss: SerializedStyles = css`
  min-width: ${DEFAULT_INPUT_WIDTH}px;
`;
export const defaultMenuItemCss: SerializedStyles = css`
  color: #c9c9c9 !important;
`;

export interface AppDateTimeInputProps {
  name: string;
  label: string;
  rules?: RegisterOptions;
  variant?: 'dialog' | 'inline' | 'static';
  inputVariant?: 'standard' | 'filled' | 'outlined';
  width?: string | number;
  fullWidth?: boolean;
}

class MomentUTCUtils extends MomentUtils {
  format = (value, formatString) => {
    return this.moment.utc(value).format(formatString);
  };
  //
  // @ts-ignore
  parse = (value: string, format: string) => {
    if (value === '') {
      return null;
    }

    return this.moment.utc(value, format, true);
  };
  // @ts-ignore
  date = (value?: any) => {
    if (value === null) {
      return null;
    }

    const moment = this.moment.utc(value);

    return moment;
  };
}

export const AppDateTimeInput: React.FC<AppDateTimeInputProps> = ({
  name,
  label,
  rules,
  variant = 'inline',
  inputVariant = 'outlined',
  width = DEFAULT_INPUT_WIDTH,
  fullWidth,
}) => {
  const { errors, control } = useFormContext();
  const error = errors[name];

  return (
    <div css={dateTimeWrapperCss}>
      <MuiPickersUtilsProvider utils={MomentUTCUtils}>
        <Controller
          render={({ onChange, value }) => (
            <KeyboardDateTimePicker
              css={dateTimeCss(width, fullWidth)}
              variant={variant}
              inputVariant={inputVariant}
              InputAdornmentProps={{ position: 'start' }}
              ampm={false}
              onChange={onChange}
              value={value}
              format={'HH:mm DD.MM.yyyy'}
              label={label}
              InputLabelProps={{
                required: rules != null ? 'required' in rules : false,
              }}
              error={!!error}
              invalidDateMessage=""
            />
          )}
          control={control}
          name={name}
          rules={rules}
          defaultValue={null}
        />
      </MuiPickersUtilsProvider>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </div>
  );
};

const dateTimeWrapperCss: SerializedStyles = css`
  margin-top: 8px;
  @media screen and (max-width: ${INPUT_MOBILE_BREAKPOINT}px) {
    width: 100% !important;
  }
`;
const dateTimeCss = (
  width: string | number,
  fullWidth: boolean | undefined,
): SerializedStyles => css`
  @media screen and (min-width: 561px) {
    width: ${fullWidth ? '100%' : `${width}px`} !important;
  }
  @media screen and (max-width: ${INPUT_MOBILE_BREAKPOINT}px) {
    width: 100% !important;
  }
  .MuiIconButton-root {
    padding: 0px !important;
  }
  .MuiOutlinedInput-input {
    padding: ${MPX * 2.625}px ${MPX * 3.5}px ${MPX * 2.625}px 0;
    ${inputsGlobalCss}
  }
`;

export type AppSwitchInputProps = Omit<
  {
    name: string;
    label: string;
    width?: string | number;
    color?: 'primary' | 'secondary' | 'default';
  } & SwitchProps,
  'value'
>;

export const AppSwitchInput: React.FC<AppSwitchInputProps> = ({
  name,
  label,
  width = DEFAULT_INPUT_WIDTH,
  color = 'primary',
  ...restProps
}) => {
  const { control } = useFormContext();

  return (
    <Grid container alignItems="center" css={switchWrapCss(width)}>
      <Controller
        render={({ value, onChange }) => (
          <Switch
            {...restProps}
            checked={value}
            onChange={e => onChange(e.target.checked)}
            color={color}
          />
        )}
        control={control}
        name={name}
        defaultValue={false}
      />

      <Grid item css={switchTextCss}>
        {label}
      </Grid>
    </Grid>
  );
};

const switchWrapCss = (width: string | number): SerializedStyles => css`
  width: ${width}px !important;
`;

const switchTextCss: SerializedStyles = css`
  padding-left: ${MPX * 4}px;
  font-size: ${MPX * 4.5}px;
  font-weight: 500;
`;

export const AppAutocompleteInput: React.FC<AppSelectInputProps> = ({
  name,
  options,
  label,
  rules,
  variant = 'outlined',
  width = DEFAULT_INPUT_WIDTH,
  fullWidth,
  defaultValue,
}) => {
  const { errors, control } = useFormContext();
  const error = errors[name];
  const selectUuid = uuidv4();

  return (
    <FormControl
      variant={variant}
      css={selectWrapperCss(width, fullWidth)}
      error={!!error}
      margin="dense"
    >
      <Controller
        render={({ onChange, value }) => (
          <Autocomplete
            id={selectUuid}
            defaultValue={defaultValue}
            options={options.map(option => option.text)}
            fullWidth
            freeSolo
            onChange={(e, value) => onChange(value)}
            onInputChange={(e, value) => onChange(value)}
            value={value}
            renderInput={params => (
              <TextField {...params} label={label} variant={variant} />
            )}
          />
        )}
        control={control}
        name={name}
        rules={rules}
        defaultValue=""
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};
