/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DiplomaticApprovalType {
    G = 'G',
    H = 'H',
    V = 'V'
}

export function DiplomaticApprovalTypeFromJSON(json: any): DiplomaticApprovalType {
    return DiplomaticApprovalTypeFromJSONTyped(json, false);
}

export function DiplomaticApprovalTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiplomaticApprovalType {
    return json as DiplomaticApprovalType;
}

export function DiplomaticApprovalTypeToJSON(value?: DiplomaticApprovalType | null): any {
    return value as any;
}

