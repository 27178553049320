/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RequestPart {
    BASIC_INFO = 'BASIC_INFO',
    PAX = 'PAX',
    CREW = 'CREW',
    CARGO = 'CARGO',
    HANDLING = 'HANDLING',
    SUMMARY = 'SUMMARY'
}

export function RequestPartFromJSON(json: any): RequestPart {
    return RequestPartFromJSONTyped(json, false);
}

export function RequestPartFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestPart {
    return json as RequestPart;
}

export function RequestPartToJSON(value?: RequestPart | null): any {
    return value as any;
}

