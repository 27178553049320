/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ReportControllerDownloadReportFileRequest {
    dateTo: string;
    dateFrom: string;
}

export interface ReportControllerGenerateAndDownloadGeneralDeclarationRequest {
    id: number;
    type: ReportControllerGenerateAndDownloadGeneralDeclarationTypeEnum;
}

export interface ReportControllerGenerateAndDownloadNOTAFileRequest {
    id: number;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download daily report if dateFrom and dateTo are same or download weekly report if the gap between dateFrom (included) and dateTo (included) is 7
     */
    async reportControllerDownloadReportFileRaw(requestParameters: ReportControllerDownloadReportFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling reportControllerDownloadReportFile.');
        }

        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling reportControllerDownloadReportFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/flights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download daily report if dateFrom and dateTo are same or download weekly report if the gap between dateFrom (included) and dateTo (included) is 7
     */
    async reportControllerDownloadReportFile(requestParameters: ReportControllerDownloadReportFileRequest): Promise<Blob> {
        const response = await this.reportControllerDownloadReportFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download formatted crew report of specific flight
     */
    async reportControllerGenerateAndDownloadGeneralDeclarationRaw(requestParameters: ReportControllerGenerateAndDownloadGeneralDeclarationRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reportControllerGenerateAndDownloadGeneralDeclaration.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling reportControllerGenerateAndDownloadGeneralDeclaration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/{id}/crew`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download formatted crew report of specific flight
     */
    async reportControllerGenerateAndDownloadGeneralDeclaration(requestParameters: ReportControllerGenerateAndDownloadGeneralDeclarationRequest): Promise<Blob> {
        const response = await this.reportControllerGenerateAndDownloadGeneralDeclarationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download NOTA report for request with given ID
     */
    async reportControllerGenerateAndDownloadNOTAFileRaw(requestParameters: ReportControllerGenerateAndDownloadNOTAFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reportControllerGenerateAndDownloadNOTAFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/nota/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Open url in new tab or Execute in swagger and then click \'Download file\' link bellow.
     * Download NOTA report for request with given ID
     */
    async reportControllerGenerateAndDownloadNOTAFile(requestParameters: ReportControllerGenerateAndDownloadNOTAFileRequest): Promise<Blob> {
        const response = await this.reportControllerGenerateAndDownloadNOTAFileRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ReportControllerGenerateAndDownloadGeneralDeclarationTypeEnum {
    GENERAL_DECLARATION = 'GENERAL_DECLARATION',
    CREW_LIST = 'CREW_LIST'
}
