/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutFlightStatusDto,
    PutFlightStatusDtoFromJSON,
    PutFlightStatusDtoFromJSONTyped,
    PutFlightStatusDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutFlightsStatusDto
 */
export interface PutFlightsStatusDto {
    /**
     * Are all flights in request done?
     * @type {boolean}
     * @memberof PutFlightsStatusDto
     */
    areAllFlightsDone: boolean;
    /**
     * 
     * @type {Array<PutFlightStatusDto>}
     * @memberof PutFlightsStatusDto
     */
    flights: Array<PutFlightStatusDto>;
}

export function PutFlightsStatusDtoFromJSON(json: any): PutFlightsStatusDto {
    return PutFlightsStatusDtoFromJSONTyped(json, false);
}

export function PutFlightsStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFlightsStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'areAllFlightsDone': json['areAllFlightsDone'],
        'flights': ((json['flights'] as Array<any>).map(PutFlightStatusDtoFromJSON)),
    };
}

export function PutFlightsStatusDtoToJSON(value?: PutFlightsStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'areAllFlightsDone': value.areAllFlightsDone,
        'flights': ((value.flights as Array<any>).map(PutFlightStatusDtoToJSON)),
    };
}


