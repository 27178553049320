/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFileType,
    RequestFileTypeFromJSON,
    RequestFileTypeFromJSONTyped,
    RequestFileTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestFileDto
 */
export interface GetRequestFileDto {
    /**
     * 
     * @type {number}
     * @memberof GetRequestFileDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileDto
     */
    awsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileDto
     */
    updatedAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetRequestFileDto
     */
    isArchived: boolean;
    /**
     * 
     * @type {RequestFileType}
     * @memberof GetRequestFileDto
     */
    fileType: RequestFileType;
    /**
     * 
     * @type {number}
     * @memberof GetRequestFileDto
     */
    flightId?: number | null;
}

export function GetRequestFileDtoFromJSON(json: any): GetRequestFileDto {
    return GetRequestFileDtoFromJSONTyped(json, false);
}

export function GetRequestFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fileName': json['fileName'],
        'awsUrl': !exists(json, 'awsUrl') ? undefined : json['awsUrl'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'isArchived': json['isArchived'],
        'fileType': RequestFileTypeFromJSON(json['fileType']),
        'flightId': !exists(json, 'flightId') ? undefined : json['flightId'],
    };
}

export function GetRequestFileDtoToJSON(value?: GetRequestFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileName': value.fileName,
        'awsUrl': value.awsUrl,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'isArchived': value.isArchived,
        'fileType': RequestFileTypeToJSON(value.fileType),
        'flightId': value.flightId,
    };
}


