/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTravelDocumentDto
 */
export interface GetTravelDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof GetTravelDocumentDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof GetTravelDocumentDto
     */
    docExp: string;
    /**
     * 
     * @type {string}
     * @memberof GetTravelDocumentDto
     */
    docType: GetTravelDocumentDtoDocTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetTravelDocumentDto
     */
    crewMemberId: number;
}

export function GetTravelDocumentDtoFromJSON(json: any): GetTravelDocumentDto {
    return GetTravelDocumentDtoFromJSONTyped(json, false);
}

export function GetTravelDocumentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTravelDocumentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'docExp': json['docExp'],
        'docType': json['docType'],
        'crewMemberId': json['crewMemberId'],
    };
}

export function GetTravelDocumentDtoToJSON(value?: GetTravelDocumentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'docExp': value.docExp,
        'docType': value.docType,
        'crewMemberId': value.crewMemberId,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetTravelDocumentDtoDocTypeEnum {
    P = 'P',
    ID = 'ID'
}


