/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFileType,
    RequestFileTypeFromJSON,
    RequestFileTypeFromJSONTyped,
    RequestFileTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestFileUploadErrorResultDto
 */
export interface GetRequestFileUploadErrorResultDto {
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileUploadErrorResultDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof GetRequestFileUploadErrorResultDto
     */
    error: string;
    /**
     * 
     * @type {RequestFileType}
     * @memberof GetRequestFileUploadErrorResultDto
     */
    fileType: RequestFileType;
}

export function GetRequestFileUploadErrorResultDtoFromJSON(json: any): GetRequestFileUploadErrorResultDto {
    return GetRequestFileUploadErrorResultDtoFromJSONTyped(json, false);
}

export function GetRequestFileUploadErrorResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestFileUploadErrorResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': json['fileName'],
        'error': json['error'],
        'fileType': RequestFileTypeFromJSON(json['fileType']),
    };
}

export function GetRequestFileUploadErrorResultDtoToJSON(value?: GetRequestFileUploadErrorResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'error': value.error,
        'fileType': RequestFileTypeToJSON(value.fileType),
    };
}


