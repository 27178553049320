/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostFlightDto,
    PostFlightDtoFromJSON,
    PostFlightDtoFromJSONTyped,
    PostFlightDtoToJSON,
    PutFlightDto,
    PutFlightDtoFromJSON,
    PutFlightDtoFromJSONTyped,
    PutFlightDtoToJSON,
    PutRequestBasicInfoDto,
    PutRequestBasicInfoDtoFromJSON,
    PutRequestBasicInfoDtoFromJSONTyped,
    PutRequestBasicInfoDtoToJSON,
    PutRequestDiplomaticApprovalDto,
    PutRequestDiplomaticApprovalDtoFromJSON,
    PutRequestDiplomaticApprovalDtoFromJSONTyped,
    PutRequestDiplomaticApprovalDtoToJSON,
    PutRequestNotaDto,
    PutRequestNotaDtoFromJSON,
    PutRequestNotaDtoFromJSONTyped,
    PutRequestNotaDtoToJSON,
    PutRequestSummaryDto,
    PutRequestSummaryDtoFromJSON,
    PutRequestSummaryDtoFromJSONTyped,
    PutRequestSummaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutRequestDto
 */
export interface PutRequestDto {
    /**
     * 
     * @type {PutRequestBasicInfoDto}
     * @memberof PutRequestDto
     */
    basicInfo?: PutRequestBasicInfoDto;
    /**
     * 
     * @type {PutRequestNotaDto}
     * @memberof PutRequestDto
     */
    nota?: PutRequestNotaDto;
    /**
     * 
     * @type {PutRequestDiplomaticApprovalDto}
     * @memberof PutRequestDto
     */
    diplomaticApproval?: PutRequestDiplomaticApprovalDto;
    /**
     * 
     * @type {PutRequestSummaryDto}
     * @memberof PutRequestDto
     */
    summary?: PutRequestSummaryDto;
    /**
     * 
     * @type {PostFlightDto}
     * @memberof PutRequestDto
     */
    createdFlight?: PostFlightDto;
    /**
     * 
     * @type {PutFlightDto}
     * @memberof PutRequestDto
     */
    updatedFlight?: PutFlightDto;
}

export function PutRequestDtoFromJSON(json: any): PutRequestDto {
    return PutRequestDtoFromJSONTyped(json, false);
}

export function PutRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basicInfo': !exists(json, 'basicInfo') ? undefined : PutRequestBasicInfoDtoFromJSON(json['basicInfo']),
        'nota': !exists(json, 'nota') ? undefined : PutRequestNotaDtoFromJSON(json['nota']),
        'diplomaticApproval': !exists(json, 'diplomaticApproval') ? undefined : PutRequestDiplomaticApprovalDtoFromJSON(json['diplomaticApproval']),
        'summary': !exists(json, 'summary') ? undefined : PutRequestSummaryDtoFromJSON(json['summary']),
        'createdFlight': !exists(json, 'createdFlight') ? undefined : PostFlightDtoFromJSON(json['createdFlight']),
        'updatedFlight': !exists(json, 'updatedFlight') ? undefined : PutFlightDtoFromJSON(json['updatedFlight']),
    };
}

export function PutRequestDtoToJSON(value?: PutRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basicInfo': PutRequestBasicInfoDtoToJSON(value.basicInfo),
        'nota': PutRequestNotaDtoToJSON(value.nota),
        'diplomaticApproval': PutRequestDiplomaticApprovalDtoToJSON(value.diplomaticApproval),
        'summary': PutRequestSummaryDtoToJSON(value.summary),
        'createdFlight': PostFlightDtoToJSON(value.createdFlight),
        'updatedFlight': PutFlightDtoToJSON(value.updatedFlight),
    };
}


