/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPlaneDto
 */
export interface GetPlaneDto {
    /**
     * 
     * @type {string}
     * @memberof GetPlaneDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPlaneDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof GetPlaneDto
     */
    nameNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPlaneDto
     */
    fmsdatabasecheck: boolean;
}

export function GetPlaneDtoFromJSON(json: any): GetPlaneDto {
    return GetPlaneDtoFromJSONTyped(json, false);
}

export function GetPlaneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPlaneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'number': json['number'],
        'nameNumber': json['nameNumber'],
        'fmsdatabasecheck': json['fmsdatabasecheck'],
    };
}

export function GetPlaneDtoToJSON(value?: GetPlaneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'number': value.number,
        'nameNumber': value.nameNumber,
        'fmsdatabasecheck': value.fmsdatabasecheck,
    };
}


