/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetFlightsStatusDto,
    GetFlightsStatusDtoFromJSON,
    GetFlightsStatusDtoFromJSONTyped,
    GetFlightsStatusDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRequestStatusDto
 */
export interface GetRequestStatusDto {
    /**
     * Is request's basic info part done?
     * @type {boolean}
     * @memberof GetRequestStatusDto
     */
    isBasicInfoDone: boolean;
    /**
     * Is request's nota part done?
     * @type {boolean}
     * @memberof GetRequestStatusDto
     */
    isNotaDone: boolean;
    /**
     * Is request's diplomaticApprovals part done?
     * @type {boolean}
     * @memberof GetRequestStatusDto
     */
    isDiplomaticApprovalDone: boolean;
    /**
     * Is request's summary part done?
     * @type {boolean}
     * @memberof GetRequestStatusDto
     */
    isSummaryDone: boolean;
    /**
     * 
     * @type {GetFlightsStatusDto}
     * @memberof GetRequestStatusDto
     */
    flights: GetFlightsStatusDto;
}

export function GetRequestStatusDtoFromJSON(json: any): GetRequestStatusDto {
    return GetRequestStatusDtoFromJSONTyped(json, false);
}

export function GetRequestStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRequestStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isBasicInfoDone': json['isBasicInfoDone'],
        'isNotaDone': json['isNotaDone'],
        'isDiplomaticApprovalDone': json['isDiplomaticApprovalDone'],
        'isSummaryDone': json['isSummaryDone'],
        'flights': GetFlightsStatusDtoFromJSON(json['flights']),
    };
}

export function GetRequestStatusDtoToJSON(value?: GetRequestStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isBasicInfoDone': value.isBasicInfoDone,
        'isNotaDone': value.isNotaDone,
        'isDiplomaticApprovalDone': value.isDiplomaticApprovalDone,
        'isSummaryDone': value.isSummaryDone,
        'flights': GetFlightsStatusDtoToJSON(value.flights),
    };
}


