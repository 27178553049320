/* tslint:disable */
/* eslint-disable */
/**
 * NAV REST API
 * Private API by @NOTUM
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DAType,
    DATypeFromJSON,
    DATypeFromJSONTyped,
    DATypeToJSON,
    GetCrewPersonDto,
    GetCrewPersonDtoFromJSON,
    GetCrewPersonDtoFromJSONTyped,
    GetCrewPersonDtoToJSON,
    GetPlaneDto,
    GetPlaneDtoFromJSON,
    GetPlaneDtoFromJSONTyped,
    GetPlaneDtoToJSON,
    GetRequestLeanDto,
    GetRequestLeanDtoFromJSON,
    GetRequestLeanDtoFromJSONTyped,
    GetRequestLeanDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFlightOverviewDto
 */
export interface GetFlightOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof GetFlightOverviewDto
     */
    id: number;
    /**
     * 
     * @type {GetRequestLeanDto}
     * @memberof GetFlightOverviewDto
     */
    request: GetRequestLeanDto;
    /**
     * 
     * @type {string}
     * @memberof GetFlightOverviewDto
     */
    departureTime: string;
    /**
     * Flight departure time ADM
     * @type {Date}
     * @memberof GetFlightOverviewDto
     */
    departureTimeADM?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetFlightOverviewDto
     */
    arrivalTime: string;
    /**
     * Flight arrival time ADM
     * @type {Date}
     * @memberof GetFlightOverviewDto
     */
    arrivalTimeADM?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetFlightOverviewDto
     */
    flightId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFlightOverviewDto
     */
    fpln?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFlightOverviewDto
     */
    pax?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFlightOverviewDto
     */
    comments?: string;
    /**
     * 
     * @type {Array<GetCrewPersonDto>}
     * @memberof GetFlightOverviewDto
     */
    crew: Array<GetCrewPersonDto>;
    /**
     * 
     * @type {GetPlaneDto}
     * @memberof GetFlightOverviewDto
     */
    assignedPlane?: GetPlaneDto;
    /**
     * 
     * @type {DAType}
     * @memberof GetFlightOverviewDto
     */
    dAType: DAType;
}

export function GetFlightOverviewDtoFromJSON(json: any): GetFlightOverviewDto {
    return GetFlightOverviewDtoFromJSONTyped(json, false);
}

export function GetFlightOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFlightOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'request': GetRequestLeanDtoFromJSON(json['request']),
        'departureTime': json['departureTime'],
        'departureTimeADM': !exists(json, 'departureTimeADM') ? undefined : (json['departureTimeADM'] === null ? null : new Date(json['departureTimeADM'])),
        'arrivalTime': json['arrivalTime'],
        'arrivalTimeADM': !exists(json, 'arrivalTimeADM') ? undefined : (json['arrivalTimeADM'] === null ? null : new Date(json['arrivalTimeADM'])),
        'flightId': !exists(json, 'flightId') ? undefined : json['flightId'],
        'fpln': !exists(json, 'fpln') ? undefined : json['fpln'],
        'pax': !exists(json, 'pax') ? undefined : json['pax'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'crew': ((json['crew'] as Array<any>).map(GetCrewPersonDtoFromJSON)),
        'assignedPlane': !exists(json, 'assignedPlane') ? undefined : GetPlaneDtoFromJSON(json['assignedPlane']),
        'dAType': DATypeFromJSON(json['DAType']),
    };
}

export function GetFlightOverviewDtoToJSON(value?: GetFlightOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'request': GetRequestLeanDtoToJSON(value.request),
        'departureTime': value.departureTime,
        'departureTimeADM': value.departureTimeADM === undefined ? undefined : (value.departureTimeADM === null ? null : value.departureTimeADM.toISOString()),
        'arrivalTime': value.arrivalTime,
        'arrivalTimeADM': value.arrivalTimeADM === undefined ? undefined : (value.arrivalTimeADM === null ? null : value.arrivalTimeADM.toISOString()),
        'flightId': value.flightId,
        'fpln': value.fpln,
        'pax': value.pax,
        'comments': value.comments,
        'crew': ((value.crew as Array<any>).map(GetCrewPersonDtoToJSON)),
        'assignedPlane': GetPlaneDtoToJSON(value.assignedPlane),
        'DAType': DATypeToJSON(value.dAType),
    };
}


