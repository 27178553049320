import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { ColorsInterface } from '../helpers/interfaces/colors';

export const MPX = 3.2;

export const Colors: ColorsInterface = {
  primary: '#455416',
  secondary: '#7B7B7B',
  white: '#fff',
  white80: '#FFFFFF80',
  whiteE6: '#FFFFFFE6',
  darkWhite: '#F8F9F8',
  darkWhite2: '#F2F2F2',
  black: '#000000',
  blackE6: '#000000E6',
  lightGray: '#EBECEB',
  gray: '#757575',
  gray2: '#949DA6',
  gray3: '#595959',
  darkGray: '#707070',
  disabled: '#ABABAB',
  dark: '#3A3A3A',
  red: '#D90000',
  error: '#f44336',
  warning: '#ffeb3b',
};

const palette: PaletteOptions = {
  primary: { main: Colors.primary, contrastText: Colors.black },
  secondary: { main: Colors.secondary, contrastText: Colors.black },
  text: {
    primary: Colors.black,
  },
};

export const APP_THEME = createMuiTheme({
  palette,
});
